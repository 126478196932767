import React from 'react'
import { StyledLinkButton } from './LinkButton.styled'

interface LinkButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'default' | 'danger'
}

export function LinkButton({
    type = 'button',
    variant = 'default',
    children,
    ...props
}: LinkButtonProps) {
    return (
        <StyledLinkButton variant={variant} type={type} {...props}>
            {children}
        </StyledLinkButton>
    )
}
