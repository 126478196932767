import styled from '@emotion/styled'
import { GameTheme } from '../../theme'

import { calcRem } from '@the-game/components/utils'
import { focusStyle } from '@the-game/components/utils'

function iconButtonStyles(theme: GameTheme) {
    return {
        minWidth: calcRem(24),
        minHeight: calcRem(24),
        height: calcRem(24),
        padding: 0,
        display: 'flex',
        border: 'none',
        background: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        cursor: 'pointer',
        ...focusStyle(theme),
    }
}

export const IconButton = styled('button')<{ width?: 'sm' | 'auto' }>(
    ({ width = 'auto', theme }) => ({
        ...iconButtonStyles(theme),
        width: width === 'sm' ? calcRem(24) : 'auto',
    }),
)
