import { calcRem } from "@the-game/components/utils";
import { focusStyle } from "@the-game/components/utils";
import styled from "@emotion/styled";
const fabDefaults = (theme, buttonSize) => ({
  backgroundColor: theme.colors.white,
  borderRadius: "50%",
  transition: "0.1s linear all",
  cursor: "pointer",
  margin: 0,
  padding: calcRem(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: buttonSize,
  minHeight: buttonSize,
  width: buttonSize,
  height: buttonSize,
  ":hover": {
    outline: "none",
    fill: theme.colors.palette.bayBlue600,
    backgroundColor: theme.colors.palette.neutral30
  },
  ...focusStyle(theme)
});
export const StyledRemoveButtonWrapper = styled("button")(({ theme }) => ({
  zIndex: 1,
  top: calcRem(-5),
  right: calcRem(10),
  position: "absolute",
  border: `none`,
  ...fabDefaults(theme, 40),
  ...theme.elevations.Elevation2
}));
export const StyledCross = styled("div")(({ theme }) => ({
  border: `2px solid ${theme.colors.loss}`,
  ...fabDefaults(theme, 22)
}));
export const StyledAvatarSelector = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: calcRem(200),
  flex: 2
});
export const StyledAvatarButtonContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  flex: 3
});
export const StyledIconSlot = styled("button")(({ theme }) => ({
  width: 100,
  height: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  borderRadius: "50%",
  backgroundColor: theme.colors.palette.neutral30,
  border: `2px solid ${theme.colors.palette.neutral50}`,
  boxSizing: "content-box",
  cursor: "pointer",
  padding: 0,
  margin: 0,
  transition: "all 0.1s ease-in-out",
  overflow: "hidden",
  "&:hover": {
    border: `2px solid ${theme.colors.palette.neutral70}`
  },
  "&:focus": {
    border: `2px solid ${theme.colors.palette.neutral70}`
  },
  ...focusStyle(theme)
}));
export const StyledImagePreview = styled("div")(
  ({ image }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: 100,
    height: 100,
    backgroundImage: image ? `url(${image})` : void 0,
    backgroundSize: "cover"
  })
);
export const StyledAvatarSelectorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "space-around",
  marginBottom: calcRem(theme.spacing.md),
  marginTop: calcRem(theme.spacing.md)
}));
