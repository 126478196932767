import * as z from 'zod'
import { GameShape } from './game'
import { SeasonShape } from './season'
import { SportShape } from './sport'

export const RecalculateUserLifeCycleEventShape = z.object({
    type: z.literal('recalculate-user-lifecycle-event'),
    sub: z.string(),
    sport: SportShape,
    season: SeasonShape,
    game: GameShape,
})
export type RecalculateUserLifeCycleEvent = z.TypeOf<
    typeof RecalculateUserLifeCycleEventShape
>
