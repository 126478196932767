import React from 'react'
import {
    StyledTable,
    StyledTableProp,
    StyledTableWrapper,
} from './Table.styled'

export interface TableProps
    extends StyledTableProp,
        React.DetailedHTMLProps<
            React.TableHTMLAttributes<HTMLTableElement>,
            HTMLTableElement
        > {
    appendTableWrapper: boolean
}

export function Table({ children, ...props }: TableProps) {
    return props.appendTableWrapper ? (
        <StyledTableWrapper>
            <StyledTable {...props}>{children}</StyledTable>
        </StyledTableWrapper>
    ) : (
        <StyledTable {...props}>{children}</StyledTable>
    )
}
