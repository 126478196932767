import React from 'react'
import styled from '@emotion/styled'
import { Logout } from '../../Icons/Logout'

export const StyledLogoutSpan = styled('span')(({ theme }) => ({
    color: theme.colors.palette.red500,
    fill: theme.colors.palette.red500,
    textDecoration: 'underlined',
    display: 'grid',
    gridGap: theme.spacing.xs,
    gridTemplateColumns: 'auto 1fr',
}))

export function LogoutSpan() {
    return (
        <StyledLogoutSpan>
            <Logout aria-hidden title="" size="sm" />
            Logout
        </StyledLogoutSpan>
    )
}
