import styled from "@emotion/styled";
import {
  getElevationBySize,
  breakpoint,
  calcRem,
  focusStyle
} from "@the-game/components/utils";
export const StyledNewsCard = styled("a")(({ theme, backgroundImage }) => ({
  background: `${backgroundImage ? `url('${backgroundImage}')` : theme.colors.primary} `,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  width: "100%",
  height: "100%",
  minHeight: calcRem(250),
  textDecoration: "none",
  color: theme.colors.white,
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: calcRem(theme.spacing.md),
  cursor: "pointer",
  overflow: "hidden",
  transition: theme.transitions.swift,
  ":hover": {
    ...getElevationBySize(3)
  },
  ...focusStyle(theme),
  borderRadius: 8,
  [breakpoint("sm")]: {
    borderRadius: 16
  }
}));
export const StyledTitle = styled("span")(
  ({ theme, isFirst }) => ({
    ...isFirst ? {
      fontSize: calcRem(23),
      lineHeight: 28 / 23,
      [breakpoint("sm")]: {
        fontSize: calcRem(31),
        lineHeight: 40 / 31
      },
      [breakpoint("md")]: {
        fontSize: calcRem(38),
        lineHeight: 40 / 38
      }
    } : {
      fontSize: calcRem(14),
      lineHeight: 16 / 14,
      [breakpoint("sm")]: {
        fontSize: calcRem(20),
        lineHeight: 24 / 20
      },
      [breakpoint("md")]: {
        fontSize: calcRem(21),
        lineHeight: 24 / 21
      }
    },
    fontWeight: theme.weights.bold,
    display: "block",
    zIndex: 1
  })
);
export const StyledDate = styled("span")(({ theme }) => ({
  fontWeight: theme.weights.regular,
  zIndex: 1,
  display: "block",
  fontSize: calcRem(12),
  [breakpoint("sm")]: {
    fontSize: calcRem(14)
  }
}));
export const StyledOverlay = styled("div")(
  ({ theme, isFirst }) => ({
    transition: theme.transitions.swift,
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 0,
    opacity: 0.5,
    background: isFirst ? `linear-gradient(180deg, rgba(0,0,0,0.00) 38%, rgba(0,0,0,0.75) 71%, rgba(0,0,0,0.80) 100%)` : `linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.90) 100%)`
  })
);
