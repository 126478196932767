import { calcRem } from '@the-game/components/utils'
import styled from '@emotion/styled'
import { breakpoint } from '@the-game/components/utils'
import { Skeleton } from '../Skeleton'

export const SkeletonScoreSummary = styled(Skeleton)({
    height: calcRem(71),
    width: '100%',
    borderRadius: '8px',

    [breakpoint('sm')]: {
        height: calcRem(112),
    },
})

export default SkeletonScoreSummary
