import React from 'react'
import { StyledButtonAnchor } from './ButtonAnchor.styled'
import { GenericButtonProps } from '../Button'

export function ButtonAnchor({
    children,
    kind = 'primary',
    fluid = false,
    padding = 'auto',
    size = 'md',
    ref,
    ...props
}: Omit<React.HTMLProps<HTMLAnchorElement>, 'size' | 'as'> &
    GenericButtonProps) {
    return (
        <StyledButtonAnchor {...{ kind, fluid, padding, size, ...props }}>
            {children}
        </StyledButtonAnchor>
    )
}

export default ButtonAnchor
