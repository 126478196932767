import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function ChevronUp({
    title = 'Chevron up icon',
    ...props
}: SvgIconProps) {
    return (
        <SvgIcon {...props} title={title} viewBox="0 0 15 9">
            <path d="M1.414 8.485L0 7.071 7.071 0l7.071 7.071-1.414 1.414L7.07 2.828z" />
        </SvgIcon>
    )
}

export default ChevronUp
