import { GameTheme } from '../theme'
import { InputVariants } from './types'

export function getVariantColor(
    variant: InputVariants = 'normal',
    theme: GameTheme,
) {
    switch (variant) {
        case 'success':
            return theme.colors.palette.green500
        case 'warning':
            return theme.colors.palette.orange500
        case 'error':
            return theme.colors.palette.red500
        default:
            return theme.colors.palette.neutral90
    }
}

export default getVariantColor
