import styled from '@emotion/styled'

export const Backdrop = styled('div')(({ theme }) => ({
    zIndex: theme.zIndex.backdrop,
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: theme.colors.black,
    opacity: 0.5,
}))
