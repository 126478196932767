import React from 'react'
import styled from '@emotion/styled'
import { calcRem } from '@the-game/components/utils'
import format from 'date-fns/format'
import { focusStyle } from '@the-game/components/utils'
import { breakpoint } from '@the-game/components/utils'

const StyledWrapper = styled('div')(({ theme }) => ({
    display: 'none',
    [breakpoint('lg')]: {
        display: 'grid',
        gridTemplateRows: `auto 1px auto`,
        gridGap: theme.spacing.md,
        ':first-child': {
            alignSelf: 'center',
        },
    },
}))

const StyledHeading = styled('span')(({ theme }) => ({
    fontSize: calcRem(28),
    lineHeight: 40 / 28,
    alignSelf: 'center',
    verticalAlign: 'center',
    fontWeight: theme.weights.bold,
}))

const StyledDate = styled('span')(({ theme }) => ({
    color: theme.colors.palette.neutral70,
    fontSize: calcRem(14),
    lineHeight: 16 / 14,
}))

const StyledTitle = styled('a')(({ theme }) => ({
    display: 'block',
    fontSize: calcRem(21),
    lineHeight: 32 / 21,
    fontWeight: theme.weights.bold,
    textDecoration: 'none',
    color: theme.colors.black,
    marginBottom: theme.spacing.xs,
    '&:hover': {
        textDecoration: 'underline',
    },
    ...focusStyle(theme),
}))

const StyledLine = styled('div')(({ theme }) => ({
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.palette.neutral50,
}))

export interface NewsItem {
    title: string
    date: Date
    isPremium: boolean
    link: string
}

export function NewsRailItem({ title, date, link }: NewsItem) {
    return (
        <div>
            <StyledTitle href={link}>{title}</StyledTitle>
            <StyledDate>{format(date, 'd MMMM')}</StyledDate>
        </div>
    )
}

export function NewsRail({ items }: { items: NewsItem[] }): JSX.Element {
    return (
        <StyledWrapper>
            <StyledHeading>More stories</StyledHeading>
            <StyledLine />
            {items?.map((item, i) => (
                <NewsRailItem {...item} key={i} />
            ))}
        </StyledWrapper>
    )
}

export default NewsRail
