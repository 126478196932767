import React from 'react'
import { FlagProps } from '.'

export function SouthFremantleBulldogs({
    title = 'SouthFremantleBulldogs',
    ...props
}: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g>
                <rect width="48" height="48" fill="white" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M-3.75736 0.0766953L-8 4.31934L19.5772 31.8965L23.8198 36.1391L28.0624 31.8965L55.6396 4.31934L51.397 0.0766953L23.8198 27.6539L-3.75736 0.0766953Z"
                    fill="#D31346"
                />
            </g>
        </svg>
    )
}

export default SouthFremantleBulldogs
