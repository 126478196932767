import styled from "@emotion/styled";
import theme from "../theme";
import { calcRem } from "../utils";
export const StyledPill = styled("div")(
  ({ theme: theme2 }) => ({
    display: "block",
    borderRadius: calcRem(30),
    padding: `1px ${theme2.spacing.sm}px 2px`,
    letterSpacing: "-0.5px",
    textTransform: "uppercase",
    border: `1px solid ${theme2.colors.primary}`,
    color: theme2.colors.primary,
    fontWeight: theme2.weights.regular,
    fontSize: calcRem(11)
  }),
  ({ variant }) => {
    if (variant) {
      return {
        background: variant === "warning" ? theme.colors.palette.artfulRed : void 0,
        border: "none",
        color: theme.colors.white
      };
    }
  }
);
