import React from 'react'
import { calcRem } from '@the-game/components/utils'
import { breakpoint } from '@the-game/components/utils'
import styled from '@emotion/styled'

interface ContentSidebarProps {
    children?: React.ReactNode
    sidebar?: React.ReactNode
}

export function ContentSidebar({ children, sidebar }: ContentSidebarProps) {
    return (
        <StyledContent>
            {children}
            <aside>{sidebar}</aside>
        </StyledContent>
    )
}

const StyledContent = styled('div')(({ theme }) => ({
    display: 'grid',
    gridColumn: '1 / -1',
    gap: calcRem(theme.spacing.xl),
    padding: calcRem(0, theme.spacing.md),

    [breakpoint('md')]: {
        gridTemplateColumns: '5fr minmax(300px, 3fr)',
    },

    [breakpoint('lg')]: {
        gridColumn: '3 / -2',
    },
}))
