import React from 'react'
import { Table } from '../Table/Table'
import { DisplayLeaderboardEntry, SportName } from '@the-game/api-interfaces'
import { Stack } from '../Stack'
import { breakpoint, calcRem } from '@the-game/components/utils'
import { withClass } from '../utils/withClass'
import { Avatar } from '../Avatar/Avatar'
import { ArrowHorizontal } from '../Icons/ArrowHorizontal'
import styled from '@emotion/styled'
import { Paginator } from '../Paginator'
import { ChevronUp } from '../Icons'
import { Spacing } from '@the-game/components'
import { sortLeaderboard } from './leaderboardSorter'

export const StyledScrollNotification = styled(Stack)({
    [breakpoint('sm')]: {
        display: 'none',
    },
})

const StyledColumnHeaderClassName = 'StyledColumnHeaderClassName'

interface TableBlockProps {
    selected: boolean
}

export const StyledTableBlock = withClass(StyledColumnHeaderClassName)(
    styled('th')<TableBlockProps>(({ selected, theme }) => [
        {
            borderRight: `1px solid ${theme.colors.palette.neutral50}`,
            position: 'relative',
            backgroundColor: selected
                ? theme.colors.palette.neutral30
                : theme.colors.white,
        },
    ]),
)

const StyledTable = styled(Table)(({ theme }) => ({
    [`.${StyledColumnHeaderClassName}`]: {
        paddingLeft: calcRem(8),
        paddingRight: calcRem(36),
        fontWeight: 400,
        backgroundColor: theme.colors.white,
        cursor: 'pointer',
    },
}))

export const StyledTableWrapper = styled('div')(({ theme }) => ({
    display: 'block',
    width: '100%',
    marginBottom: calcRem(theme.spacing.md),
    overflowX: 'auto',
}))

export const StyledRightAlignedTd = styled('td')({
    textAlign: 'end',
})

const StyledColumnHeader = styled('div')({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
})

const StyledStack = styled(Stack)(({ theme }) => ({
    padding: theme.spacing.lg,
}))

const StyledSpan = styled('span')({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
})

const StyledLastRoundInfo = styled('p')(({ theme }) => ({
    color: theme.colors.palette.neutral70,
    marginBottom: theme.spacing.xs,
}))
interface ChevronUpProps {
    leftMargin: Spacing
    /* Some columns had to have some less spacing on desktop */
    desktopLeftMargin?: Spacing
}
const StyledChevronUp = styled(ChevronUp)<ChevronUpProps>(
    ({ theme, leftMargin }) => ({
        position: 'absolute',
        left: `calc(85% + ${calcRem(theme.spacing[leftMargin])})`,
    }),
    ({ theme, desktopLeftMargin, leftMargin }) => ({
        [breakpoint('md')]: {
            left: desktopLeftMargin
                ? `calc(98% + ${calcRem(theme.spacing[desktopLeftMargin])})`
                : `calc(85% + ${calcRem(theme.spacing[leftMargin])})`,
        },
    }),
)

export function ScrollNotification() {
    return (
        <StyledScrollNotification
            direction="row"
            gap="md"
            alignItems="center"
            spacing="md"
        >
            <ArrowHorizontal size="sm" themeColor="bayBlue" />
            <span>Scroll to see more</span>
        </StyledScrollNotification>
    )
}
export function LeaderboardRow({ entry }: { entry: DisplayLeaderboardEntry }) {
    return (
        <tr>
            <td>{entry.rank}</td>
            <td>
                <Stack direction="row" gap="sm">
                    <Avatar src={entry.avatar} teamFlag={entry.teamFlag} />
                    <span>{entry.username}</span>
                </Stack>
            </td>
            {typeof entry.roundScore === 'number' && (
                <StyledRightAlignedTd>{entry.roundScore}</StyledRightAlignedTd>
            )}
            {typeof entry.roundScore === 'number' && (
                <StyledRightAlignedTd>{entry.roundMargin}</StyledRightAlignedTd>
            )}
            <StyledRightAlignedTd>{entry.totalScore}</StyledRightAlignedTd>
            <StyledRightAlignedTd>{entry.totalMargin}</StyledRightAlignedTd>
        </tr>
    )
}

export interface LeaderboardProps {
    rows?: DisplayLeaderboardEntry[]
    highlightedRow?: DisplayLeaderboardEntry
    round?: number
    page?: number
    pageSize?: number
    setPage?: (val: number) => void
    totalItems?: number
    sport: SportName
    selectedColumn?: {
        column: SelectedColumn
        updateSelectedColumn: React.Dispatch<
            React.SetStateAction<SelectedColumn>
        >
    }
    lastCalculatedRound?: number
}

export type TableColumn =
    | 'rank'
    | 'member'
    | 'roundMargin'
    | 'roundScore'
    | 'totalMargin'
    | 'totalScore'

export type ColumnState = 'ascending' | 'descending'

export interface SelectedColumn {
    name: TableColumn
    state: ColumnState
}

export function LeaderboardBase({
    round,
    highlightedRow,
    sport,
    children,
    selectedColumn,
    lastCalculatedRound,
}: React.PropsWithChildren<LeaderboardProps>) {
    const roundLabel = sport === 'Cricket' ? 'Round' : 'Round'
    const marginLabel = sport === 'Cricket' ? 'Sixes' : 'Margin'

    const updateColumns = (event: React.MouseEvent) => {
        // We need to get the list of currently selected columns
        const columnClicked = event.currentTarget.getAttribute(
            'data-columnname',
        ) as TableColumn

        if (columnClicked && selectedColumn) {
            // Check to see if we currently have a selected column

            const sameColumn = selectedColumn.column.name === columnClicked

            const newState: ColumnState = sameColumn
                ? selectedColumn.column.state === 'ascending'
                    ? 'descending'
                    : 'ascending'
                : 'descending'

            selectedColumn.updateSelectedColumn({
                name: columnClicked,
                state: newState,
            })
        }
    }

    const isOpeningRound = sport === 'AFL' && round === 0

    const rotateAmount = selectedColumn?.column.state === 'ascending' ? 180 : 0

    return (
        <React.Fragment>
            {lastCalculatedRound !== undefined && lastCalculatedRound >= 0 && (
                <StyledLastRoundInfo>
                    Leaderboard current as of{' '}
                    {lastCalculatedRound === 0
                        ? 'the Opening Round'
                        : `Round ${lastCalculatedRound}`}
                </StyledLastRoundInfo>
            )}
            <ScrollNotification />
            <StyledTable
                highlightRow={highlightedRow && 1}
                dividers
                appendTableWrapper={false}
            >
                <thead>
                    <tr>
                        <StyledTableBlock
                            data-columnname="rank"
                            className="StyledHeaders"
                            onClick={(e) => updateColumns(e)}
                            selected={selectedColumn?.column.name === 'rank'}
                        >
                            <StyledColumnHeader>
                                <StyledSpan>
                                    Rank
                                    {selectedColumn?.column.name === 'rank' && (
                                        <StyledChevronUp
                                            themeColor="bayBlue"
                                            size="xs"
                                            role="img"
                                            rotate={rotateAmount}
                                            leftMargin={'sm'}
                                        />
                                    )}
                                </StyledSpan>
                            </StyledColumnHeader>
                        </StyledTableBlock>

                        <StyledTableBlock
                            data-columnname="member"
                            onClick={(e) => updateColumns(e)}
                            selected={selectedColumn?.column.name === 'member'}
                        >
                            <StyledColumnHeader>
                                <StyledSpan>
                                    Member
                                    {selectedColumn?.column.name ===
                                        'member' && (
                                        <StyledChevronUp
                                            themeColor="bayBlue"
                                            role="img"
                                            size="xs"
                                            rotate={rotateAmount}
                                            leftMargin={'md'}
                                        />
                                    )}
                                </StyledSpan>
                            </StyledColumnHeader>
                        </StyledTableBlock>

                        {(isOpeningRound || (round && round > 0)) && (
                            <StyledTableBlock
                                data-columnname="roundScore"
                                onClick={(e) => updateColumns(e)}
                                selected={
                                    selectedColumn?.column.name === 'roundScore'
                                }
                            >
                                <StyledColumnHeader>
                                    <StyledSpan>
                                        {roundLabel} Score
                                        {selectedColumn?.column.name ===
                                            'roundScore' && (
                                            <StyledChevronUp
                                                themeColor="bayBlue"
                                                role="img"
                                                size="xs"
                                                rotate={rotateAmount}
                                                leftMargin={'md'}
                                                desktopLeftMargin={'xxs'}
                                            />
                                        )}
                                    </StyledSpan>
                                </StyledColumnHeader>
                            </StyledTableBlock>
                        )}

                        {(isOpeningRound || (round && round > 0)) && (
                            <StyledTableBlock
                                data-columnname="roundMargin"
                                onClick={(e) => updateColumns(e)}
                                selected={
                                    selectedColumn?.column.name ===
                                    'roundMargin'
                                }
                            >
                                <StyledColumnHeader>
                                    <StyledSpan>
                                        {roundLabel} {marginLabel}
                                        {selectedColumn?.column.name ===
                                            'roundMargin' && (
                                            <StyledChevronUp
                                                themeColor="bayBlue"
                                                role="img"
                                                size="xs"
                                                rotate={rotateAmount}
                                                leftMargin={'md'}
                                                desktopLeftMargin={'xxs'}
                                            />
                                        )}
                                    </StyledSpan>
                                </StyledColumnHeader>
                            </StyledTableBlock>
                        )}

                        <StyledTableBlock
                            data-columnname="totalScore"
                            onClick={(e) => updateColumns(e)}
                            selected={
                                selectedColumn?.column.name === 'totalScore'
                            }
                        >
                            <StyledColumnHeader>
                                <StyledSpan>
                                    Total Score
                                    {selectedColumn?.column.name ===
                                        'totalScore' && (
                                        <StyledChevronUp
                                            themeColor="bayBlue"
                                            role="img"
                                            size="xs"
                                            rotate={rotateAmount}
                                            leftMargin={'md'}
                                            desktopLeftMargin={'xxs'}
                                        />
                                    )}
                                </StyledSpan>
                            </StyledColumnHeader>
                        </StyledTableBlock>

                        <StyledTableBlock
                            data-columnname="totalMargin"
                            onClick={(e) => updateColumns(e)}
                            selected={
                                selectedColumn?.column.name === 'totalMargin'
                            }
                        >
                            <StyledColumnHeader>
                                <StyledSpan>
                                    Total {marginLabel}
                                    {selectedColumn?.column.name ===
                                        'totalMargin' && (
                                        <StyledChevronUp
                                            themeColor="bayBlue"
                                            role="img"
                                            size="xs"
                                            rotate={rotateAmount}
                                            leftMargin={'md'}
                                            desktopLeftMargin={'xxs'}
                                        />
                                    )}
                                </StyledSpan>
                            </StyledColumnHeader>
                        </StyledTableBlock>
                    </tr>
                </thead>
                <tbody>
                    {highlightedRow && (
                        <LeaderboardRow
                            key={`currentuser-${highlightedRow.userId}`}
                            entry={highlightedRow}
                        />
                    )}
                    {children}
                </tbody>
            </StyledTable>
        </React.Fragment>
    )
}

export function Leaderboard({
    rows,
    highlightedRow,
    round,
    page = 0,
    pageSize = 50,
    setPage = (_) => undefined,
    totalItems = (rows ?? []).length,
    sport,
    lastCalculatedRound,
}: LeaderboardProps) {
    const [selectedColumn, setSelectedColumn] = React.useState<SelectedColumn>({
        name: 'rank',
        state: 'descending',
    })

    const [sortedData, setSortedData] = React.useState<
        DisplayLeaderboardEntry[] | undefined
    >(undefined)

    React.useEffect(() => {
        if (rows) {
            sortLeaderboard(selectedColumn, rows, setSortedData)
        }
    }, [rows, selectedColumn])

    if (!rows || rows.length === 0) {
        return (
            <StyledStack alignItems="center" justifyContent="center">
                <p>
                    {round !== undefined && round >= 0
                        ? `This competition was not scored for this ${
                              sport === 'Cricket' ? 'round' : 'round'
                          }`
                        : sport === 'AFL'
                        ? `Leaderboard will be available at the end of the Opening Round`
                        : `Leaderboard will be available at the end of ${
                              sport === 'Cricket' ? 'round' : 'round'
                          } 1`}
                </p>
            </StyledStack>
        )
    }

    return (
        <StyledTableWrapper>
            <Paginator
                itemFromData={(entry) => (
                    <LeaderboardRow key={entry.rank} entry={entry} />
                )}
                pageNumber={page}
                pageSize={pageSize}
                goToPage={(i: number) => setPage(i)}
                pageData={sortedData}
                totalItems={totalItems}
                container={({ children }) => (
                    <LeaderboardBase
                        sport={sport}
                        rows={sortedData}
                        round={round}
                        highlightedRow={highlightedRow}
                        selectedColumn={{
                            column: selectedColumn,
                            updateSelectedColumn: setSelectedColumn,
                        }}
                        lastCalculatedRound={lastCalculatedRound}
                    >
                        {children}
                    </LeaderboardBase>
                )}
                controlPlaceHolder={false}
            />
        </StyledTableWrapper>
    )
}
