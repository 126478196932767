import * as z from 'zod'
import { SeasonShape } from './season'
import { SportShape } from './sport'

export const RoundEndLifecycleEventShape = z.object({
    type: z.literal('round-end'),
    sport: SportShape,
    season: z.string(),
    round: z.number(),
})

export type RoundEndLifecycleEvent = z.TypeOf<
    typeof RoundEndLifecycleEventShape
>

export const RoundStartLifecycleEventShape = z.object({
    type: z.literal('round-start'),
    sport: SportShape,
    season: z.string(),
    round: z.number(),
})

export type RoundStartLifecycleEvent = z.TypeOf<
    typeof RoundStartLifecycleEventShape
>

export const RoundChangeFeaturedMatchLifecycleEventShape = z.object({
    type: z.literal('round-change-featured-match-lifecycle-event'),
    sport: SportShape,
    season: z.string(),
    round: z.number(),
    featuredMatchId: z.string(),
})

export type RoundChangeFeaturedMatchLifecycleEvent = z.TypeOf<
    typeof RoundChangeFeaturedMatchLifecycleEventShape
>

export const RoundAddLifecycleEventShape = z.object({
    type: z.literal('round-add'),
    sport: SportShape,
    season: SeasonShape,
    startDate: z.string().optional(),
    endDate: z.string().optional(),
    round: z.number(),
})

export type RoundAddLifecycleEvent = z.TypeOf<
    typeof RoundAddLifecycleEventShape
>
