import { baseFontSize } from './utils/baseFontSize'
import { elevations } from './utils/elevations'
import '@emotion/react'
import { modifyLuminance } from './utils/modifyLuminance'

export interface ThemeColorMap {
    palette: typeof palette
    primary: string
    cardHeader: {
        background: string
        text: string
    }
    white: string
    black: string
    win: string
    draw: string
    loss: string
    gradientButton: {
        background: string
        text: string
    }
    gradient: string

    tipScoreCard: {
        heading: string
    }
    buttonGroup: {
        hover: string
    }
}

type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>
}

export type ThemeColorOverrideMap = Pick<
    DeepPartial<ThemeColorMap>,
    'primary'
> &
    DeepPartial<ThemeColorMap>

export const gradients = {
    aflTipping:
        'linear-gradient(0.25turn, #3E65C0, #3C6BC0, #3A72C0, #3878C0, #357FC0, #3385C0, #308CC0, #2C92C0, #2998C0, #259EC0, #21A3C0, #1CA8C0,#17ACC0,#11B0C0, #09B4C0)',
    aflwTipping: 'linear-gradient(90deg, #F16C52 0%, #720160 100%)',
    waflTipping: 'linear-gradient(90deg, #007A85 0%, #FFF3C8 130%)',
    goldShimmer:
        'linear-gradient(0.125turn, #CB9B51, #F6E27A, #F6F2C0, #F6E27A, #CB9B51)',
    goldShimmerInverse:
        'linear-gradient(0.125turn, #CB9B51, #F6E27A, #D2A758,#F6F2C0)',
    orangeSunburst:
        'linear-gradient(90deg, #FF8900 0%, #FF8C01 5.72%, #FF8F02 12.1%, #FF9203 19.02%, #FF9603 26.38%, #FF9904 34.07%, #FF9D04 41.98%, #FFA104 50%, #FFA405 58.02%, #FFA705 65.93%, #FFAA05 73.62%, #FFAD05 80.98%, #FFB006 87.9%, #FFB306 94.28%, #FFB506 100%);',
}

export const palette = {
    gold900: '#734400',
    bayBlue100: '#d4def7',
    bayBlue200: '#7194e6',
    bayBlue: '#265cd9',
    bayBlue600: '#204eb8',
    bayBlue700: '#122D6C',
    neutral90: '#1e2427',
    neutral70: '#757575',
    neutral50: '#CECECE',
    neutral30: '#f3f3f3',
    green500: '#158936',
    green600: '#127325',
    green700: '#0b521f',
    orange300: '#DB3915',
    orange500: '#D04A0B',
    red500: '#BE0000',
    white: '#fff',
    black: '#000',
    seaGreen: '#53F7A1',
    orangeDelight: '#FFC657',
    cricketOrange: 'hsl(25, 100%, 39%)',
    waflTeal: '#007A85',
    aflw100: '#F16C52',
    aflw20: '#FCE3DE',
    aflw120: '#CB2F10',
    artfulRed: 'hsl(0, 100%, 35%)',
    transparent: 'hsla(0,0%,0%,0)',
    tab: '#4a1f6a',
    teal500: '#04828B',
} as const

const colors: ThemeColorMap = {
    palette,
    primary: palette.bayBlue,
    cardHeader: {
        background: gradients.aflTipping,
        text: palette.white,
    },
    white: palette.white,
    black: palette.black,
    draw: palette.orangeDelight,
    win: palette.seaGreen,
    loss: palette.artfulRed,
    gradientButton: {
        background: gradients.aflTipping,
        text: palette.white,
    },
    gradient: gradients.aflTipping,
    tipScoreCard: {
        heading: palette.neutral90,
    },
    buttonGroup: {
        hover: palette.bayBlue100,
    },
} as const

const waflColors: ThemeColorOverrideMap = {
    primary: palette.waflTeal,
    cardHeader: {
        background: gradients.waflTipping,
        text: palette.white,
    },
    gradientButton: {
        background: gradients.waflTipping,
        text: palette.white,
    },
    gradient: gradients.waflTipping,
    tipScoreCard: {
        heading: palette.neutral90,
    },
    buttonGroup: {
        hover: '#007A8566',
    },
}

const aflwColors: ThemeColorOverrideMap = {
    primary: palette.aflw100,
    cardHeader: {
        background: gradients.aflwTipping,
        text: palette.white,
    },
    gradientButton: {
        background: gradients.aflwTipping,
        text: palette.white,
    },
    gradient: gradients.aflwTipping,
    tipScoreCard: {
        heading: palette.neutral90,
    },
    buttonGroup: {
        hover: palette.aflw20,
    },
}

const cricketColors: ThemeColorOverrideMap = {
    primary: palette.cricketOrange,
    cardHeader: {
        background: gradients.goldShimmer,
        text: palette.white,
    },
    gradientButton: {
        background: gradients.orangeSunburst,
        text: palette.black,
    },
    gradient: gradients.orangeSunburst,
    tipScoreCard: {
        heading: palette.neutral90,
    },
    buttonGroup: {
        hover: modifyLuminance(palette.cricketOrange, 85),
    },
}

const spacing = {
    unset: 0,
    /** xxs: 4 */
    xxs: 4,
    /** xs: 8 */
    xs: 8,
    /** sm: 12 */
    sm: 12,
    /** md: 16 */
    md: 16,
    /** lg: 24 */
    lg: 24,
    /** xl: 32 */
    xl: 32,
    /** xxl: 40 */
    xxl: 40,
    /** xxxl: 48 */
    xxxl: 48,
    /** xxxxl: 64 */
    xxxxl: 64,
} as const

const metrics = {
    icons: {
        xxxs: 8,
        xxs: 12,
        xs: 16,
        sm: 24,
        md: 32,
        lg: 48,
        xl: 64,
        xxl: 96,
        xxxl: 128,
        experts: 64,
    },
    widths: {
        md: '34rem',
        lg: '41rem',
    },
} as const

const breakpoints = {
    xs: 375,
    sm: 768,
    md: 1024,
    lg: 1366,
    xl: 1920,
} as const

const zIndex = {
    tippingBadge: 1,
    tableHeader: 2,
    ladderMarker: 2,
    stickyFooter: 80,
    backdrop: 98,
    modal: 99,
} as const

const fontFamily = "'Jost', Helvetica, Arial, sans-serif"

export const theme = {
    waflColors,
    aflwColors,
    cricketColors,
    colors,
    spacing,
    metrics,
    singleColumnWidth: 544,
    baseFontSize,
    fontFamily,
    weights: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 600,
        bolder: 700,
        black: 900,
    },
    transitions: {
        swift: 'ease-in-out all 0.1s',
        normal: 'ease-in-out all 0.3s',
    },
    elevations,
    breakpoints,
    zIndex,
} as const

export type GameTheme = typeof theme
export type Spacing = keyof typeof spacing
export type Breakpoints = keyof typeof breakpoints
export type Colors = keyof typeof palette
export type IconSizes = keyof typeof metrics.icons

declare module '@emotion/react' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface Theme extends GameTheme {}
}

export default theme
