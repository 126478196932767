import React, { useState } from 'react'
import { ProfileDTO } from '@the-game/api-interfaces'
import { calcRem } from '@the-game/components/utils'
import styled from '@emotion/styled'
import { TheGameLogo } from '../logos'
import { breakpoint } from '@the-game/components/utils'
import { Avatar } from '../Avatar/Avatar'
import { Link } from '../Link'
import { DesktopNavigation } from './components/DesktopNavigation'
import { MobileNavigation } from './components/MobileNavigation'
import { LogoutSpan } from './components/LogoutSpan'
import { ContactUsButton } from './components/ContactUsButton'
import { MenuSection } from './types'
import { VisuallyHidden } from '@the-game/components'

interface GlobalHeaderProps {
    children?: React.ReactNode
    userInfo?: React.ReactNode
    loggedIn?: boolean
    loginButton?: React.ReactNode
    logoutButton?: React.FC
    registerButton?: React.ReactNode
    avatar?: React.ReactNode
    user?: ProfileDTO
    homeLink?: React.FC
    items?: MenuSection[]
    showOfficialSponsor?: boolean
    setScroll?: (val: boolean) => void
}

export const GlobalLogoContainer = styled('div')(({ theme }) => ({
    a: {
        display: 'flex',
    },
    svg: {
        width: 50,
        height: 50,
    },
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'center',
    alignSelf: 'stretch',
    [breakpoint('lg')]: {
        svg: {
            width: 75,
            height: 77,
        },
        a: {
            width: 77,
            height: 77,
        },
        justifySelf: 'stretch',

        borderRight: `1px solid ${theme.colors.palette.neutral30}`,
    },
}))

const StyledHeader = styled('header')(({ theme }) => ({
    backgroundColor: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.palette.neutral50}`,
    gridColumn: '1 / -1',
    gridGap: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    display: 'grid',
    gridTemplateColumns: `2rem 1fr 2rem`,
    height: '4rem',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px -8px 20px rgb(0 0 0 / 17%)',
    [breakpoint('lg')]: {
        height: calcRem(88),
        /**Grid value when sponsor logo is off */
        // gridTemplateColumns: `5.75rem auto auto`,

        /**Grid value when sponsor logo is on */
        gridTemplateColumns: `12.5rem auto auto`,
        justifyContent: 'stretch',
    },
}))

const AvatarMenuItem = styled(Link)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    cursor: 'pointer',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    color: theme.colors.black,
    flexDirection: 'row',
    padding: 0,
    div: {
        height: calcRem(32),
        width: calcRem(32),
    },
    '> :last-child': {
        display: 'none',
    },
    [breakpoint('md')]: {
        height: '100%',
        width: '100%',
        '> :last-child': {
            display: 'flex',
            marginLeft: theme.spacing.xs,
        },
    },
}))

const StyledListItem = styled('li')(({ theme }) => ({
    display: 'grid',
    gridGap: theme.spacing.md,
    gridAutoFlow: 'column',
    alignItems: 'center',
    boxSizing: 'content-box',
    padding: theme.spacing.md,
    borderLeft: `1px solid ${theme.colors.palette.neutral30}`,
    minWidth: '10rem',
}))

const StyledUsername = styled('span')({
    maxWidth: calcRem(128),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
})

const DesktopAvatar = styled('ul')({
    display: 'none',
    [breakpoint('lg')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        height: '100%',
        width: '100%',
        a: {
            textDecoration: 'none',
        },
        button: {
            textAlign: 'left',
        },
    },
})

const MobileOnly = styled('div')({
    display: 'auto',
    [breakpoint('lg')]: {
        display: 'none',
    },
})
const MobileAvatar = styled(Link)({
    display: 'block',
    width: '2rem',
    height: '2rem',
    div: {
        width: '100%',
        height: '100%',
    },
    [breakpoint('lg')]: {
        display: 'none',
    },
})

export const DualLogoContainer = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '50px 1px 50px',
    gridColumnGap: theme.spacing.sm,
    fontSize: calcRem(10),
    color: theme.colors.palette.neutral70,
    alignItems: 'center',
    justifyContent: 'center',

    '> *:first-child': {
        gridRow: '1 / -1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
    },
    img: {
        width: 50,
    },

    [breakpoint('lg')]: {
        '> *:first-child': {
            height: 65,
            svg: {
                width: 65,
                height: 65,
            },
        },
        alignSelf: 'stretch',
        justifySelf: 'stretch',
        gridTemplateColumns: '77px 1fr',
        borderRight: `1px solid ${theme.colors.palette.neutral30}`,
        paddingRight: 16,

        img: {
            width: 65,
            marginLeft: 8,
        },
    },
}))

export const LogoRightSide = styled('div')<{ isSubHeadingCentered: boolean }>(
    ({ theme, isSubHeadingCentered }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '> *:last-child': {
            fill: theme.colors.palette.tab,
            alignSelf: 'flex-start',
            width: 77,
            [breakpoint('lg')]: {
                textAlign: isSubHeadingCentered ? 'center' : 'left',
            },
        },
    }),
)

const Line = styled('div')(({ theme }) => ({
    display: 'flex',
    alignSelf: 'center',
    height: 40,
    backgroundColor: theme.colors.palette.neutral50,
    width: '100%',
    gridRow: '1 / -1',
    [breakpoint('lg')]: {
        display: 'none',
    },
}))

export const StyledInactiveMenuItem = styled('span')(({ theme }) => ({
    cursor: 'default',
    color: theme.colors.palette.neutral50,
    [breakpoint('lg')]: {
        display: 'block',
        padding: `${theme.spacing.md}px ${theme.spacing.lg}px`,
    },
}))

export function GlobalHeader({
    loggedIn = false,
    loginButton,
    logoutButton,
    registerButton,
    homeLink,
    user,
    items = [],
    showOfficialSponsor = true,
    setScroll = (_) => undefined,
}: GlobalHeaderProps) {
    return (
        <StyledHeader>
            <MobileNavigation
                user={user}
                loggedIn={loggedIn}
                logoutButton={logoutButton}
                items={items}
                setScroll={setScroll}
            />
            <DualLogos
                homeLink={homeLink}
                showOfficialSponsor={showOfficialSponsor}
            />
            <DesktopNavigation items={items} />
            {loggedIn ? (
                <MobileAvatar to="/profile">
                    <Avatar src={user?.avatar} teamFlag={user?.teamFlag} />
                </MobileAvatar>
            ) : (
                <MobileOnly>{loginButton}</MobileOnly>
            )}
            <DesktopAvatar>
                {loggedIn ? (
                    <>
                        <StyledListItem>
                            <AvatarMenuItem to="/profile">
                                <Avatar
                                    size="md"
                                    src={user?.avatar}
                                    teamFlag={
                                        !user || user.isDeleted
                                            ? undefined
                                            : user.teamFlag
                                    }
                                />
                                <StyledUsername>
                                    {!user || user.isDeleted
                                        ? undefined
                                        : user.username}
                                    <VisuallyHidden>
                                        (your profile)
                                    </VisuallyHidden>
                                </StyledUsername>
                            </AvatarMenuItem>
                        </StyledListItem>
                        <StyledListItem>
                            <ContactUsButton />
                            {logoutButton &&
                                logoutButton({
                                    children: <LogoutSpan />,
                                })}
                        </StyledListItem>
                    </>
                ) : (
                    <>
                        <StyledListItem>{loginButton}</StyledListItem>
                        <StyledListItem>{registerButton}</StyledListItem>
                    </>
                )}
            </DesktopAvatar>
        </StyledHeader>
    )
}

export function DualLogos({
    homeLink,
    showOfficialSponsor,
}: {
    homeLink?: React.FC
    showOfficialSponsor: boolean
}) {
    const [errorLoading, setErrorLoading] = useState(false)

    function LogoLink() {
        return homeLink ? (
            homeLink({
                children: (
                    <>
                        <TheGameLogo aria-hidden themeColor="black" />
                        <VisuallyHidden>The Game home page</VisuallyHidden>
                    </>
                ),
            })
        ) : (
            <a href="/play">
                <TheGameLogo aria-hidden themeColor="black" />
                <VisuallyHidden>The Game home page</VisuallyHidden>
            </a>
        )
    }

    return showOfficialSponsor && !errorLoading ? (
        <DualLogoContainer>
            <LogoLink />
            <Line />
            <img
                src={`${
                    process.env.NX_API_GATEWAY_URL?.includes('localhost')
                        ? process.env.NX_API_GATEWAY_URL
                        : ''
                }/public-assets/officialpartner.png`}
                alt="Official Partner"
                onError={() => setErrorLoading(true)}
            />
        </DualLogoContainer>
    ) : (
        <GlobalLogoContainer>
            <LogoLink />
        </GlobalLogoContainer>
    )
}
