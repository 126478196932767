import React from 'react'
import styled from '@emotion/styled'
import { calcRem, useIsMobileView } from '@the-game/components/utils'

const StyledSvg = styled('svg')<{ md?: boolean }>(({ md }) => ({
    width: calcRem(md ? 48 : 32),
    height: calcRem(md ? 48 : 32),
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopRightRadius: calcRem(md ? 16 : 12),
    boxShadow: '5px -5px 0 5px #d3d3d3',
}))

export function PremiumIconSm() {
    return (
        <StyledSvg viewBox="0 0 32 32">
            <defs>
                <path
                    id="premium-tag-a"
                    d="M0,0 L24,0 C28.418278,0 32,3.581722 32,8 L32,32 L30.3431458,30.3431458 C28.8428547,28.8428547 26.8080234,28 24.6862915,28 L12,28 C7.581722,28 4,24.418278 4,20 L4,7.3137085 C4,5.19197658 3.15714528,3.15714528 1.65685425,1.65685425 L0,0 Z"
                />
                <linearGradient
                    id="premium-tag-c"
                    x1="-.007%"
                    x2="99.976%"
                    y1="49.957%"
                    y2="49.957%"
                >
                    <stop offset="0%" stopColor="#043DE2" />
                    <stop offset="100%" stopColor="#1257E0" />
                </linearGradient>
                <linearGradient
                    id="premium-tag-d"
                    x1="100.758%"
                    x2="3.774%"
                    y1="2.966%"
                    y2="102.451%"
                >
                    <stop offset="0%" stopColor="#F400AB" />
                    <stop offset="100%" stopColor="#AA01E1" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="premium-tag-b" fill="#fff">
                    <use href="#premium-tag-a" />
                </mask>
                <use fill="#FFF" href="#premium-tag-a" />
                <path
                    stroke="#CECECE"
                    strokeWidth="2"
                    d="M0,0 L24,0 C28.418278,-8.11624501e-16 32,3.581722 32,8 L32,32 L32,32"
                    mask="url(#premium-tag-b)"
                />
                <g transform="translate(11 8)">
                    <path
                        fill="url(#premium-tag-c)"
                        d="M12.1865031,4.81481481 C12.0828221,4.72654321 11.3570552,4.22901235 10.4079755,4.30123457 C8.82883436,4.06851852 8.63742331,2.55185185 8.63742331,2.55185185 L2.54417178,8.72283951 C4.13128834,9.0037037 4.36257669,10.5845679 4.36257669,10.5845679 L4.37055215,10.5765432 C4.37055215,12.687037 6.08527607,12.9679012 6.50797546,12.9679012 C6.56380368,12.9679012 6.59570552,12.9679012 6.59570552,12.9679012 C6.59570552,12.9679012 7.60858896,12.9518519 8.22269939,12.2135802 C8.74110429,11.595679 8.65337423,10.6888889 8.65337423,10.3037037 C8.65337423,9.8382716 9.07607362,8.81111111 9.80184049,8.73888889 C11.301227,8.65061728 11.6282209,8.52222222 11.9871166,8.31358025 C12.3460123,8.10493827 12.8644172,7.69567901 12.9202454,6.63641975 C12.9680982,5.5691358 12.3220859,4.93518519 12.1865031,4.81481481 Z"
                    />
                    <path
                        fill="url(#premium-tag-d)"
                        d="M6.41226994,0.0240740741 C6.41226994,0.0240740741 5.3993865,0.0401234568 4.78527607,0.778395062 C4.26687117,1.3962963 4.35460123,2.30308642 4.35460123,2.6882716 C4.35460123,3.1537037 3.97177914,4.24506173 3.20613497,4.29320988 C1.72269939,4.29320988 1.28404908,4.4537037 0.925153374,4.67037037 C0.566257669,4.88703704 0.0877300613,5.24012346 0.0398773006,6.35555556 C-0.00797546012,7.41481481 0.685889571,8.04876543 0.821472393,8.1691358 C0.957055215,8.28950617 2.05766871,9.16419753 3.58895706,8.41790123 C5.10429448,7.67160494 6.5398773,6.25123457 6.5398773,6.25123457 C6.5398773,6.25123457 8.43006135,4.67839506 8.62944785,2.76851852 C8.82883436,0.320987654 6.93865031,0.0160493827 6.5,0.0160493827 C6.44417178,0.0240740741 6.41226994,0.0240740741 6.41226994,0.0240740741"
                    />
                </g>
            </g>
        </StyledSvg>
    )
}

export function PremiumIconMd() {
    return (
        <StyledSvg viewBox="0 0 48 48">
            <defs>
                <path
                    id="premium-tag-48px-a"
                    d="M0,2.84217094e-14 L32,2.84217094e-14 C40.836556,2.67984604e-14 48,7.163444 48,16 L48,48 L48,48 L46.6862915,46.6862915 C43.6857094,43.6857094 39.6160468,42 35.372583,42 L22,42 C13.163444,42 6,34.836556 6,26 L6,12.627417 C6,8.38395316 4.31429055,4.31429055 1.3137085,1.3137085 L0,2.84217094e-14 L0,2.84217094e-14 Z"
                />
                <linearGradient
                    id="premium-tag-48px-c"
                    x1="-.007%"
                    x2="99.976%"
                    y1="49.957%"
                    y2="49.957%"
                >
                    <stop offset="0%" stopColor="#043DE2" />
                    <stop offset="100%" stopColor="#1257E0" />
                </linearGradient>
                <linearGradient
                    id="premium-tag-48px-d"
                    x1="100.758%"
                    x2="3.774%"
                    y1="2.966%"
                    y2="102.451%"
                >
                    <stop offset="0%" stopColor="#F400AB" />
                    <stop offset="100%" stopColor="#AA01E1" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="premium-tag-48px-b" fill="#fff">
                    <use href="#premium-tag-48px-a" />
                </mask>
                <use fill="#FFF" href="#premium-tag-48px-a" />
                <path
                    stroke="#CECECE"
                    strokeWidth="2"
                    d="M0,-7.10542736e-15 L32,-7.10542736e-15 C40.836556,-8.72867636e-15 48,7.163444 48,16 L48,48 L48,48"
                    mask="url(#premium-tag-48px-b)"
                />
                <g transform="translate(16 10)">
                    <path
                        fill="url(#premium-tag-48px-c)"
                        d="M20.6233129,8.14814815 C20.4478528,7.99876543 19.2196319,7.15679012 17.6134969,7.27901235 C14.9411043,6.88518519 14.6171779,4.31851852 14.6171779,4.31851852 L4.30552147,14.7617284 C6.99141104,15.237037 7.38282209,17.9123457 7.38282209,17.9123457 L7.39631902,17.8987654 C7.39631902,21.4703704 10.2981595,21.945679 11.0134969,21.945679 C11.1079755,21.945679 11.1619632,21.945679 11.1619632,21.945679 C11.1619632,21.945679 12.8760736,21.9185185 13.9153374,20.6691358 C14.792638,19.6234568 14.6441718,18.0888889 14.6441718,17.437037 C14.6441718,16.6493827 15.3595092,14.9111111 16.5877301,14.7888889 C19.1251534,14.6395062 19.6785276,14.4222222 20.2858896,14.0691358 C20.8932515,13.7160494 21.7705521,13.0234568 21.8650307,11.2308642 C21.9460123,9.42469136 20.8527607,8.35185185 20.6233129,8.14814815 Z"
                    />
                    <path
                        fill="url(#premium-tag-48px-d)"
                        d="M10.8515337,0.0407407407 C10.8515337,0.0407407407 9.13742331,0.0679012346 8.09815951,1.31728395 C7.2208589,2.36296296 7.36932515,3.89753086 7.36932515,4.54938272 C7.36932515,5.33703704 6.72147239,7.18395062 5.42576687,7.2654321 C2.91533742,7.2654321 2.17300613,7.53703704 1.56564417,7.9037037 C0.958282209,8.27037037 0.148466258,8.86790123 0.0674846626,10.7555556 C-0.0134969325,12.5481481 1.1607362,13.6209877 1.39018405,13.8246914 C1.6196319,14.0283951 3.48220859,15.508642 6.07361963,14.245679 C8.63803681,12.982716 11.0674847,10.5790123 11.0674847,10.5790123 C11.0674847,10.5790123 14.2662577,7.91728395 14.603681,4.68518519 C14.9411043,0.543209877 11.7423313,0.0271604938 11,0.0271604938 C10.9055215,0.0407407407 10.8515337,0.0407407407 10.8515337,0.0407407407"
                    />
                </g>
            </g>
        </StyledSvg>
    )
}

export function PremiumIcon() {
    const isMobile = useIsMobileView()
    return isMobile ? <PremiumIconSm /> : <PremiumIconMd />
}
