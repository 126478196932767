import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function CheckRound({ title = 'Check mark', ...props }: SvgIconProps) {
    return (
        <SvgIcon
            {...props}
            title={title}
            data-testid="check-mark"
            viewBox="0 0 24 24"
        >
            <path d="M12,1 C18.0751,1 23,5.92487 23,12 C23,18.0751 18.0751,23 12,23 C5.92487,23 1,18.0751 1,12 C1,5.92487 5.92487,1 12,1 Z M12,3 C7.02944,3 3,7.02944 3,12 C3,16.9706 7.02944,21 12,21 C16.9706,21 21,16.9706 21,12 C21,7.02944 16.9706,3 12,3 Z M15.8995,7.8284 L17.3137,9.24262 L10.2426,16.3137 L6,12.071 L7.41421,10.6568 L10.2426,13.4853 L15.8995,7.8284 Z" />
        </SvgIcon>
    )
}
export default CheckRound
