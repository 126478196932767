import * as z from 'zod'
import { atLeastOneId } from './custom'
import { SeasonShape } from './season'
import { SportShape } from './sport'

export const MatchSystemLockedLifecycleEventShape = z
    .object({
        id: z.string().nonempty().optional(),
        externalId: z.string().nonempty().optional(),
        type: z.literal('match-system-locked-lifecycle-event'),
        sport: SportShape,
        season: SeasonShape,
        round: z.number(),
        reason: z.string().min(8, 'Provide some context for the change'),
    })
    .refine(atLeastOneId.matcher, atLeastOneId.options)

export type MatchSystemLockedLifecycleEvent = z.TypeOf<
    typeof MatchSystemLockedLifecycleEventShape
>

export const MatchSystemUnlockedLifecycleEventShape = z
    .object({
        id: z.string().nonempty().optional(),
        externalId: z.string().nonempty().optional(),
        type: z.literal('match-system-unlocked-lifecycle-event'),
        sport: SportShape,
        season: SeasonShape,
        round: z.number(),
    })
    .refine(atLeastOneId.matcher, atLeastOneId.options)

export type MatchSystemUnlockedLifecycleEvent = z.TypeOf<
    typeof MatchSystemUnlockedLifecycleEventShape
>

export const MatchHomeAwaySwappedLifecycleEventShape = z
    .object({
        id: z.string().nonempty().optional(),
        externalId: z.string().nonempty().optional(),
        type: z.literal('match-home-away-swapped-lifecycle-event'),
        sport: SportShape,
        season: z.string(),
        round: z.number(),
        reason: z.string().min(8, 'Provide some context for the change'),
    })
    .refine(atLeastOneId.matcher, atLeastOneId.options)

export type MatchHomeAwaySwappedLifecycleEvent = z.TypeOf<
    typeof MatchHomeAwaySwappedLifecycleEventShape
>
