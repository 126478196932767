import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Options({ title = 'Options icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon title={title} viewBox="0 0 18 17" {...props}>
            <path d="M4 0a4.002 4.002 0 013.874 3H16v2H7.874A4.002 4.002 0 010 4a4 4 0 014-4zm0 6a2 2 0 100-4 2 2 0 000 4zM14 17a4.002 4.002 0 01-3.874-3H2v-2h8.126A4.002 4.002 0 0118 13a4 4 0 01-4 4zm0-2a2 2 0 100-4 2 2 0 000 4z" />
        </SvgIcon>
    )
}

export default Options
