import React from 'react'
import { FlagProps } from '.'

export function Melbourne({ title = 'Melbourne', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h48v48H0z" fill="#000B2E" />
                <path
                    d="M24-33.941L57.941 0 24 33.941-9.941 0z"
                    fill="#CA1424"
                />
            </g>
        </svg>
    )
}

export default Melbourne
