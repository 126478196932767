import { CSSObject } from '@emotion/react'
import { breakpoint } from './breakpoint'
import { calcRem } from './calcRem'
import { Levels } from './types'

export function headingPropsFromSize(size: Levels): CSSObject {
    switch (size) {
        case '1':
            return {
                fontSize: calcRem(48),
                lineHeight: 1,
                [breakpoint('md')]: {
                    fontSize: calcRem(61),
                    lineHeight: 64 / 61,
                },

                [breakpoint('lg')]: {
                    fontSize: calcRem(80),
                    lineHeight: 88 / 80,
                },
            }
        case '2':
            return {
                fontSize: calcRem(40),
                lineHeight: 48 / 40,
                [breakpoint('md')]: {
                    fontSize: calcRem(49),
                    lineHeight: 56 / 49,
                },

                [breakpoint('lg')]: {
                    fontSize: calcRem(67),
                    lineHeight: 72 / 67,
                },
            }
        case '3':
            return {
                fontSize: calcRem(33),
                lineHeight: 40 / 33,
                [breakpoint('md')]: {
                    fontSize: calcRem(39),
                    lineHeight: 48 / 39,
                },

                [breakpoint('lg')]: {
                    fontSize: calcRem(51),
                    lineHeight: 64 / 51,
                },
            }
        case '4':
            return {
                fontSize: calcRem(28),
                lineHeight: 40 / 28,
                [breakpoint('md')]: {
                    fontSize: calcRem(31),
                    lineHeight: 40 / 31,
                },

                [breakpoint('lg')]: {
                    fontSize: calcRem(38),
                    lineHeight: 48 / 38,
                },
            }
        case '5':
            return {
                fontSize: calcRem(23),
                lineHeight: 32 / 23,
                [breakpoint('md')]: {
                    fontSize: calcRem(25),
                    lineHeight: 32 / 25,
                },
                [breakpoint('lg')]: {
                    fontSize: calcRem(28),
                    lineHeight: 40 / 28,
                },
            }
        case '6':
            return {
                fontSize: calcRem(19),
                lineHeight: 24 / 19,
                [breakpoint('md')]: {
                    fontSize: calcRem(20),
                    lineHeight: 24 / 20,
                },
                [breakpoint('lg')]: {
                    fontSize: calcRem(21),
                    lineHeight: 32 / 21,
                },
            }
    }
}
export default headingPropsFromSize
