import { Button } from "../Button";
import { calcRem } from "@the-game/components/utils";
import { StyledInput } from "../Input/Input.styled";
import styled from "@emotion/styled";
import { focusStyle } from "@the-game/components/utils";
import getPrimarySportColor from "../utils/getPrimarySportColor";
export const StyledSpinButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start"
});
export const StyledSpinInput = styled(StyledInput)(({ theme, sportName }) => ({
  padding: 0,
  margin: 0,
  appearance: "textfield",
  MozAppearance: "textfield",
  WebkitAppearance: "textfield",
  textAlign: "center",
  width: calcRem(60),
  borderWidth: 2,
  borderColor: sportName ? getPrimarySportColor(sportName, theme) : "inherit",
  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "&:disabled": {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.palette.neutral50
  }
}));
export const StyledSpinButton = styled(Button)(({ theme }) => ({
  padding: calcRem(theme.spacing.xxs, theme.spacing.md),
  margin: 0,
  borderWidth: 2,
  height: calcRem(40),
  ...focusStyle(theme),
  "&:first-child": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0
  },
  "&:last-child": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0
  }
}));
