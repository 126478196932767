import { Team } from '@the-game/api-interfaces'
import { Stack } from '../Stack'
import { Avatar } from '../Avatar/Avatar'
import styled from '@emotion/styled'
import React, { ReactHTML } from 'react'

interface MemberTileProps {
    component?: keyof ReactHTML
    name: string
    username: string
    teamFlag?: Team
    avatar?: string | undefined
    isEditing?: boolean
}

const StyledUserDetails = styled('p')({
    margin: 0,
    strong: {
        display: 'block',
    },
})

export function CompetitionMemberTile({
    avatar,
    name,
    username,
    teamFlag,
    component = 'div',
}: MemberTileProps) {
    return (
        <Stack component={component} gap="xs" alignItems="center">
            <Avatar size="lg" src={avatar} teamFlag={teamFlag} />
            <StyledUserDetails>
                <strong>{username}</strong>
                {name}
            </StyledUserDetails>
        </Stack>
    )
}
