import { CSSObject } from '@emotion/react'

export const visuallyHiddenStyle: CSSObject = {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    clipPath: 'inset(50%)',
    whiteSpace: 'nowrap',
    borderWidth: 0,
}

export default visuallyHiddenStyle
