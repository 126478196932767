import React, { Fragment, useState, useEffect } from 'react'
import { Label } from '../Label/Label'
import { FormControl } from '../FormControl/FormControl'
import { StyledInput, StyledInputContainer } from '../Input/Input.styled'
import { usePopper } from 'react-popper'
import { StyledDropdownModal, StyledBranding } from './AddressField.styled'
import { FormattedAddress, AddressSummary } from '@the-game/api-interfaces'
import { ManualAddressField } from './ManualAddressField'
import styled from '@emotion/styled'
import { Backdrop } from '../Backdrop'
import { FormHelp } from '../FormHelp/FormHelp'
import { LinkButton } from '../LinkButton'
import { useDebounce } from 'use-debounce'

export interface AddressFieldProps {
    id: string
    className?: string
    required?: boolean
    logo?: string
    fetchAddresses: (search: string | undefined) => Promise<AddressSummary[]>
    fetchAddress: (placeId: string | undefined) => Promise<FormattedAddress>
    onChange: (address?: FormattedAddress) => void
    value: FormattedAddress
    fieldErrors?: { [key: string]: string | undefined }
    searchTermValue?: string
}

const StyledFocusArea = styled('div')(({ theme }) => ({
    zIndex: theme.zIndex.backdrop,
    position: 'relative',
}))

export function AddressField({
    id,
    className,
    required = true,
    logo,
    onChange,
    value,
    fetchAddresses,
    fetchAddress,
    fieldErrors,
    searchTermValue,
}: AddressFieldProps) {
    const [searchTerm, setSearchTerm] = useState(searchTermValue || '')
    const [results, setResults] = useState([] as AddressSummary[])
    const [isSearching, setIsSearching] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showBackdrop, setShowBackdrop] = useState(false)
    const [showManualAddress, setShowManualAddress] = useState(false)
    const [selected, setSelected] = useState<number>()

    const [debouncedSearchTerm] = useDebounce(searchTerm, 500)

    useEffect(() => {
        if (debouncedSearchTerm && showModal) {
            setIsSearching(true)
            fetchAddresses(debouncedSearchTerm).then((results) => {
                setIsSearching(false)
                setResults(results)
            })
        } else {
            setResults([])
        }
    }, [debouncedSearchTerm, fetchAddresses, showModal])

    const [
        popperElement,
        setPopperElement,
    ] = React.useState<HTMLUListElement | null>(null)

    const [
        referenceElement,
        setReferenceElement,
    ] = React.useState<HTMLInputElement | null>(null)

    const width = referenceElement?.clientWidth

    const popper = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, -2],
                },
            },
        ],
    })

    const { styles, attributes } = popper

    const selectAndCloseList = async (selectedAddress?: AddressSummary) => {
        setShowBackdrop(false)
        setResults([])
        setSelected(undefined)
        setShowModal(false)
        if (!selectedAddress) {
            setSearchTerm('')
            onChange()
        } else {
            setSearchTerm(selectedAddress.description)
            const detail = await fetchAddress(selectedAddress.placeId)
            onChange({ ...detail, id: selectedAddress.placeId ?? '' })
        }
    }

    const handleKeydown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Escape':
                e.preventDefault()
                selectAndCloseList(undefined)
                break
            case 'ArrowDown':
                if (showModal) {
                    e.preventDefault()
                    if (selected === undefined) {
                        setSelected(0)
                    } else if (selected < results.length) {
                        setSelected(selected + 1)
                    }
                }
                break
            case 'ArrowUp':
                if (showModal) {
                    e.preventDefault()
                    if (selected === undefined) {
                        setSelected(0)
                    } else if (selected > 0) {
                        setSelected(selected - 1)
                    }
                }
                break
            case 'Enter':
                if (showModal) {
                    e.preventDefault()
                    if (selected !== undefined) {
                        if (selected === results.length) {
                            setShowManualAddress(true)
                        } else {
                            await selectAndCloseList(results[selected])
                            referenceElement?.blur()
                        }
                    } else {
                        selectAndCloseList(undefined)
                    }
                }
                break
        }
    }

    if (showManualAddress) {
        return (
            <Fragment>
                <LinkButton
                    onClick={() => {
                        setSearchTerm('')
                        onChange()
                        setShowManualAddress(false)
                    }}
                >
                    Search for address
                </LinkButton>
                <ManualAddressField
                    onChange={onChange}
                    value={value}
                    fieldErrors={fieldErrors}
                    required={required}
                />
            </Fragment>
        )
    }

    return (
        <FormControl className={className}>
            {showBackdrop && <Backdrop onClick={() => selectAndCloseList()} />}
            <Label htmlFor={id} required={required}>
                Address
            </Label>
            <StyledFocusArea>
                <StyledInputContainer>
                    <StyledInput
                        id={id}
                        name="address"
                        variant={
                            fieldErrors?.addressSearch ? 'error' : 'normal'
                        }
                        onChange={({ currentTarget: { value } }) => {
                            const show = value.length > 0
                            setShowModal(show)
                            setShowBackdrop(show)
                            setSearchTerm(value)
                        }}
                        value={searchTerm}
                        type="text"
                        ref={setReferenceElement}
                        fluid={true}
                        autoComplete="chrome-off"
                        onKeyDown={handleKeydown}
                        placeholder="Start typing your address"
                        role="textbox"
                    />
                </StyledInputContainer>
            </StyledFocusArea>
            <StyledDropdownModal
                ref={setPopperElement}
                style={{
                    ...styles.popper,
                    width,
                }}
                {...attributes.popper}
                role="listbox"
                hide={!showModal}
            >
                {isSearching && <li>Loading...</li>}
                {results.map(({ placeId, description }, index) => (
                    <li
                        role="option"
                        aria-selected={selected === index}
                        key={placeId}
                        onMouseEnter={() => setSelected(index)}
                        onClick={() =>
                            selectAndCloseList({ placeId, description })
                        }
                    >
                        {description}
                    </li>
                ))}
                {searchTerm && searchTerm?.length > 2 ? (
                    <li
                        role="option"
                        key="no-address-option"
                        onMouseEnter={() => setSelected(results.length)}
                        aria-selected={selected === results.length}
                        onClick={() => {
                            selectAndCloseList(undefined)
                            setShowManualAddress(true)
                        }}
                    >
                        Can't find your address? Enter it manually here.
                    </li>
                ) : (
                    <li>Type to find your address</li>
                )}
                {logo && <StyledBranding logo={logo} />}
            </StyledDropdownModal>
            <FormHelp variant={fieldErrors?.addressSearch ? 'error' : 'normal'}>
                {fieldErrors?.addressSearch}
            </FormHelp>

            <LinkButton
                onClick={() => {
                    onChange()
                    setShowManualAddress(true)
                }}
            >
                Enter address manually
            </LinkButton>
        </FormControl>
    )
}
