import { calcRem, breakpoint, fontRemScale } from "@the-game/components/utils";
import styled from "@emotion/styled";
export const Badge = styled("div")(({ theme }) => ({
  borderRadius: 2,
  border: `1px solid ${theme.colors.palette.neutral50}`,
  padding: `${calcRem(2)} ${calcRem(1)}`,
  color: theme.colors.palette.neutral90,
  fontSize: fontRemScale(0.875),
  lineHeight: 1,
  height: calcRem(20),
  width: calcRem(20)
}));
export const BadgeList = styled("div")(
  ({ size, theme }) => ({
    display: "grid",
    gridColumnGap: 2,
    alignSelf: "flex-end",
    span: {
      width: "100%",
      fontSize: size === "sm" ? fontRemScale(0.75) : fontRemScale(1),
      fontWeight: size === "sm" ? theme.weights.medium : theme.weights.bold
    },
    gridTemplateColumns: `repeat(5, ${size === "sm" ? calcRem(14) : calcRem(24)})`,
    [breakpoint("sm")]: {
      gridTemplateColumns: `repeat(5, ${size === "sm" ? calcRem(20) : calcRem(24)})`,
      span: {
        fontSize: size === "sm" ? fontRemScale(0.75) : fontRemScale(1)
      }
    }
  })
);
export const StyledSpan = styled("span")({
  gridColumn: "span 5",
  textAlign: "right",
  fontSize: fontRemScale(0.875)
});
