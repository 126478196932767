import React from 'react'
import { Global, css } from '@emotion/react'

// Font scale generated using the follow configuration
// @link https://utopia.fyi/generator-mk-ii?c=360,48,1.2,1920,90,1.333,0,5,1024

export function GameGlobalStyles() {
    return (
        // GAME-39: CSS Reset Global Styles
        <Global
            styles={css`
                :root {
                    --fluid-min-width: 360;
                    --fluid-max-width: 1920;

                    --fluid-screen: 100vw;
                    --fluid-bp: calc(
                        (
                                var(--fluid-screen) - var(--fluid-min-width) /
                                    16 * 1rem
                            ) /
                            (var(--fluid-max-width) - var(--fluid-min-width))
                    );
                }

                @media screen and (min-width: 1920px) {
                    :root {
                        --fluid-screen: calc(var(--fluid-max-width) * 1px);
                    }
                }

                :root {
                    --f--5-min: 19.29;
                    --f--5-max: 21.38;
                    --step--5: calc(
                        ((var(--f--5-min) / 16) * 1rem) +
                            (var(--f--5-max) - var(--f--5-min)) *
                            var(--fluid-bp)
                    );

                    --f--4-min: 23.15;
                    --f--4-max: 28.51;
                    --step--4: calc(
                        ((var(--f--4-min) / 16) * 1rem) +
                            (var(--f--4-max) - var(--f--4-min)) *
                            var(--fluid-bp)
                    );

                    --f--3-min: 27.78;
                    --f--3-max: 38;
                    --step--3: calc(
                        ((var(--f--3-min) / 16) * 1rem) +
                            (var(--f--3-max) - var(--f--3-min)) *
                            var(--fluid-bp)
                    );

                    --f--2-min: 33.33;
                    --f--2-max: 50.65;
                    --step--2: calc(
                        ((var(--f--2-min) / 16) * 1rem) +
                            (var(--f--2-max) - var(--f--2-min)) *
                            var(--fluid-bp)
                    );

                    --f--1-min: 40;
                    --f--1-max: 67.52;
                    --step--1: calc(
                        ((var(--f--1-min) / 16) * 1rem) +
                            (var(--f--1-max) - var(--f--1-min)) *
                            var(--fluid-bp)
                    );

                    --f-0-min: 48;
                    --f-0-max: 90;
                    --step-0: calc(
                        ((var(--f-0-min) / 16) * 1rem) +
                            (var(--f-0-max) - var(--f-0-min)) * var(--fluid-bp)
                    );
                }

                *,
                *::before,
                *::after {
                    box-sizing: border-box;
                }

                ul[class],
                ol[class] {
                    padding: 0;
                }

                body,
                h1,
                h2,
                h3,
                h4,
                p,
                ul[class],
                ol[class],
                li,
                figure,
                figcaption,
                blockquote,
                dl,
                dd {
                    margin: 0;
                }

                body {
                    background-color: #fff;
                    font-family: 'Jost', Helvetica, Arial, sans-serif;
                    min-height: 100vh;
                    scroll-behavior: smooth;
                    text-rendering: optimizeSpeed;
                    line-height: 1.5;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }

                ul[class],
                ol[class] {
                    list-style: none;
                }

                img {
                    max-width: 100%;
                    display: block;
                }

                input,
                button,
                textarea,
                select {
                    font: inherit;
                }

                a:not([class]) {
                    text-decoration-skip-ink: auto;
                }

                abbr {
                    text-decoration: none;
                }

                @media (prefers-reduced-motion: reduce) {
                    * {
                        animation-duration: 0.01ms !important;
                        animation-iteration-count: 1 !important;
                        transition-duration: 0.01ms !important;
                        scroll-behavior: auto !important;
                    }
                }
            `}
        />
    )
}

export default GameGlobalStyles
