import React from 'react'
import {
    StyledHeaderContainer,
    StyledMarginInputCard,
    StyledMarginSpinButtonContainer,
    StyledHeadingH1,
} from './MarginInputCard.styled'
import { SpinButton } from '../SpinButton/SpinButton'
import { Tooltip } from '../Tooltip/Tooltip'
import { MatchStatus } from '@the-game/api-interfaces'
import { useGameContext } from '@the-game/web-common'

export interface MarginInputCardProps {
    onChange?: (value: number) => void
    max?: number
    min?: number
    value?: number
    matchStatus: MatchStatus
    disableInput?: boolean
    label: string
    tooltipContent: React.ReactNode
}

export const MarginInputCard = React.forwardRef<
    HTMLInputElement,
    MarginInputCardProps
>((props, ref) => {
    const {
        onChange,
        max,
        min,
        value,
        matchStatus,
        disableInput,
        label,
        tooltipContent,
    } = props

    const { sport } = useGameContext()
    const sportThemeColor =
        sport === 'AFLW'
            ? 'aflw100'
            : sport === 'Cricket'
            ? 'cricketOrange'
            : 'bayBlue'

    return (
        <StyledMarginInputCard>
            <StyledHeaderContainer>
                {label}
                <Tooltip
                    id="margin-input-tooltip"
                    large
                    header={<StyledHeadingH1>{label}</StyledHeadingH1>}
                    sportThemeColor={sportThemeColor}
                >
                    {tooltipContent}
                </Tooltip>
            </StyledHeaderContainer>
            <StyledMarginSpinButtonContainer>
                <SpinButton
                    id="marginSpinButton"
                    max={max}
                    min={min}
                    onChange={onChange}
                    value={value}
                    disabled={matchStatus === 'in-progress' || disableInput}
                    ref={ref}
                />
            </StyledMarginSpinButtonContainer>
        </StyledMarginInputCard>
    )
})
