import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { set as setCookie, get as getCookie } from 'js-cookie'
import {
    StyledPromoCard,
    StyledCard,
    StyledContent,
    StyledBadgeGroup,
    StyledImage,
    StyledCloseButton,
} from './AppPromoCard.styled'
import { StyledCompCardPill } from '../CompetitionScoreCard/CompetitionScoreCard.styled'
import { Box } from '@the-game/components'
import appPromoLgRender from './assets/app-promo-afl-LG-nosponsor@3x.png'
import playStoreBadgeRender from './assets/google-play-badge.png'
import qrCode from './assets/QR_https___thewe.st_TheGameAFL.png'
import AppStoreBadge from './AppStoreBadge'
import VisuallyHidden from '../VisuallyHidden'
import { StyledAlertActionButtonCross } from '../AlertBanner/AlertBanner.styled'

const COOKIE_APP_PROMO_CARD = 'appPromo2022CardDismissed'

export function AppPromoCard({ dismissable = true }) {
    const theme = useTheme()
    const [isVisible, setIsVisible] = useState(true)
    function dismissPromoCard() {
        setCookie(COOKIE_APP_PROMO_CARD, 'true')
        setIsVisible(false)
    }

    const shouldHideCard = getCookie(COOKIE_APP_PROMO_CARD)
    if (dismissable && (!isVisible || shouldHideCard)) {
        return null
    }
    return (
        <StyledPromoCard>
            <Box position="relative">
                <StyledCard elevation={3}>
                    <StyledContent>
                        <h3>Have you got The Game App?</h3>
                        <p>Make tipping easy and download it here</p>
                        <StyledBadgeGroup>
                            <a
                                href="https://apps.apple.com/au/app/the-game-tipping/id1638897595"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <AppStoreBadge />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.thegameapp"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={playStoreBadgeRender}
                                    alt="Google Play"
                                />
                            </a>
                            <a
                                href="/"
                                target=""
                                rel="noreferrer"
                                className={'qrcode'}
                            >
                                <img src={qrCode} alt="QR download app" />
                            </a>
                        </StyledBadgeGroup>
                    </StyledContent>
                    <StyledImage src={appPromoLgRender} alt="Get our app" />
                    {dismissable && (
                        <StyledCloseButton onClick={dismissPromoCard}>
                            <VisuallyHidden>Close</VisuallyHidden>
                            <StyledAlertActionButtonCross
                                fill={theme.colors.palette.neutral70}
                            />
                        </StyledCloseButton>
                    )}
                </StyledCard>
            </Box>
        </StyledPromoCard>
    )
}
