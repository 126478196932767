import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function AddPlayer({
    title = 'Add player icon',
    ...props
}: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 20 18" title={title} {...props}>
            <path
                fillRule="evenodd"
                d="M6 8a4 4 0 100-8 4 4 0 000 8zm0-2a2 2 0 100-4 2 2 0 000 4zM9 11a1 1 0 011 1v6h2v-6a3 3 0 00-3-3H3a3 3 0 00-3 3v6h2v-6a1 1 0 011-1h6zM16 4h2v2h2v2h-2v2h-2V8h-2V6h2z"
            />
        </SvgIcon>
    )
}

export default AddPlayer
