import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'
import cuid from 'cuid'

interface FootballProps extends SvgIconProps {
    gradientFill?: boolean
}

export function WaflFootball({
    title = 'WAFL Icon',
    gradientFill = false,
    ...props
}: FootballProps) {
    const identifier = cuid()

    return (
        <SvgIcon
            {...{ title, ...props }}
            width="40"
            height="40"
            viewBox="0 0 40 40"
        >
            <path d="M23.7522 1.05078C22.6041 0.821152 21.4196 1.21076 20.632 2.07714L13.0836 10.3804L5.16323 15.4206C3.91011 16.2181 3.33582 17.7484 3.75493 19.1734L7.50002 31.9067V35.0113C7.50002 37.3839 9.90861 38.9969 12.1025 38.0936L23.6631 33.3333H25C26.841 33.3333 28.3334 31.841 28.3334 30V28.9082C27.2368 29.3777 26.1125 29.7293 25 29.9594V30H24.7971C23.961 30.162 23.133 30.255 22.33 30.2774L10.8334 35.0113L10.8334 31.9067C10.8334 31.5883 10.7877 31.2716 10.6979 30.9661L6.95281 18.2328L14.8732 13.1926C15.1231 13.0336 15.3508 12.8419 15.5501 12.6227L23.0985 4.31938L25 4.69969L25 6.36626C26.1085 5.96867 27.2328 5.68982 28.3334 5.53341V4.69969C28.3334 3.11075 27.2118 1.7427 25.6537 1.43109L23.7522 1.05078Z" />
            <path d="M23.7522 1.05078C22.6041 0.821152 21.4196 1.21076 20.632 2.07714L13.0836 10.3804L5.16323 15.4206C3.91011 16.2181 3.33582 17.7484 3.75493 19.1734L7.50002 31.9067V35.0113C7.50002 37.3839 9.90861 38.9969 12.1025 38.0936L23.6631 33.3333H25C26.841 33.3333 28.3334 31.841 28.3334 30V28.9082C27.2368 29.3777 26.1125 29.7293 25 29.9594V30H24.7971C23.961 30.162 23.133 30.255 22.33 30.2774L10.8334 35.0113L10.8334 31.9067C10.8334 31.5883 10.7877 31.2716 10.6979 30.9661L6.95281 18.2328L14.8732 13.1926C15.1231 13.0336 15.3508 12.8419 15.5501 12.6227L23.0985 4.31938L25 4.69969L25 6.36626C26.1085 5.96867 27.2328 5.68982 28.3334 5.53341V4.69969C28.3334 3.11075 27.2118 1.7427 25.6537 1.43109L23.7522 1.05078Z" />
            <path d="M22.955 17.7088C22.4343 17.1881 21.59 17.1881 21.0693 17.7088C20.5486 18.2295 20.5486 19.0738 21.0693 19.5944L24.4085 22.9336C24.9292 23.4543 25.7734 23.4543 26.2941 22.9336C26.8148 22.4129 26.8148 21.5686 26.2941 21.0479L22.955 17.7088Z" />
            <path d="M22.955 17.7088C22.4343 17.1881 21.59 17.1881 21.0693 17.7088C20.5486 18.2295 20.5486 19.0738 21.0693 19.5944L24.4085 22.9336C24.9292 23.4543 25.7734 23.4543 26.2941 22.9336C26.8148 22.4129 26.8148 21.5686 26.2941 21.0479L22.955 17.7088Z" />
            <path d="M23.5737 15.2046C24.0944 14.6839 24.9386 14.6839 25.4593 15.2046L28.7984 18.5437C29.3191 19.0644 29.3191 19.9086 28.7984 20.4293C28.2777 20.95 27.4335 20.95 26.9128 20.4293L23.5737 17.0902C23.053 16.5695 23.053 15.7253 23.5737 15.2046Z" />
            <path d="M23.5737 15.2046C24.0944 14.6839 24.9386 14.6839 25.4593 15.2046L28.7984 18.5437C29.3191 19.0644 29.3191 19.9086 28.7984 20.4293C28.2777 20.95 27.4335 20.95 26.9128 20.4293L23.5737 17.0902C23.053 16.5695 23.053 15.7253 23.5737 15.2046Z" />
            <path d="M27.9636 12.7002C27.4429 12.1795 26.5986 12.1795 26.0779 12.7002C25.5572 13.2209 25.5572 14.0651 26.0779 14.5858L29.4171 17.9249C29.9378 18.4456 30.782 18.4456 31.3027 17.9249C31.8234 17.4042 31.8234 16.56 31.3027 16.0393L27.9636 12.7002Z" />
            <path d="M27.9636 12.7002C27.4429 12.1795 26.5986 12.1795 26.0779 12.7002C25.5572 13.2209 25.5572 14.0651 26.0779 14.5858L29.4171 17.9249C29.9378 18.4456 30.782 18.4456 31.3027 17.9249C31.8234 17.4042 31.8234 16.56 31.3027 16.0393L27.9636 12.7002Z" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.0035 26.9994C20.6918 30.6877 28.6904 28.669 32.8643 24.4951C37.0382 20.3212 39.0569 12.3226 35.3686 8.6343C31.6803 4.94602 23.6817 6.96474 19.5078 11.1386C15.3339 15.3125 13.3152 23.3111 17.0035 26.9994ZM24.2551 26.0756C26.7505 25.6025 29.2977 24.2904 30.9787 22.6095C32.6596 20.9285 33.9717 18.3813 34.4447 15.8859C34.9308 13.322 34.4384 11.4753 33.483 10.5199C32.5276 9.56451 30.6809 9.07212 28.117 9.55816C25.6216 10.0312 23.0744 11.3433 21.3934 13.0243C19.7125 14.7052 18.4004 17.2524 17.9273 19.7478C17.4413 22.3117 17.9337 24.1584 18.8891 25.1138C19.8445 26.0692 21.6912 26.5616 24.2551 26.0756Z"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.0035 26.9994C20.6918 30.6877 28.6904 28.669 32.8643 24.4951C37.0382 20.3212 39.0569 12.3226 35.3686 8.6343C31.6803 4.94602 23.6817 6.96474 19.5078 11.1386C15.3339 15.3125 13.3152 23.3111 17.0035 26.9994ZM24.2551 26.0756C26.7505 25.6025 29.2977 24.2904 30.9787 22.6095C32.6596 20.9285 33.9717 18.3813 34.4447 15.8859C34.9308 13.322 34.4384 11.4753 33.483 10.5199C32.5276 9.56451 30.6809 9.07212 28.117 9.55816C25.6216 10.0312 23.0744 11.3433 21.3934 13.0243C19.7125 14.7052 18.4004 17.2524 17.9273 19.7478C17.4413 22.3117 17.9337 24.1584 18.8891 25.1138C19.8445 26.0692 21.6912 26.5616 24.2551 26.0756Z"
            />
        </SvgIcon>
    )
}
export default WaflFootball
