import { useState, useLayoutEffect } from 'react'
import { theme } from '../theme'

export function useIsMobileView() {
    const [mobileView, setMobileView] = useState(false)

    useLayoutEffect(() => {
        function handleResize() {
            setMobileView(window.innerWidth < theme.breakpoints.sm)
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return mobileView
}

export default useIsMobileView
