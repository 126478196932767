import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { calcRem } from '@the-game/components/utils'
import { focusStyle } from '@the-game/components/utils'
import { ChevronLeft } from '../Icons/ChevronLeft'
import styled from '@emotion/styled'

export function QuickLink({ children, ...props }: LinkProps<unknown>) {
    return (
        <StyledLink {...props}>
            <ChevronLeft width={16} height={16} /> {children}
        </StyledLink>
    )
}

const StyledLink = styled(Link)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    padding: calcRem(theme.spacing.sm),
    paddingLeft: 0,
    color: theme.colors.primary,
    fontFamily: theme.fontFamily,

    svg: {
        width: 16,
        height: 16,
        marginRight: calcRem(theme.spacing.xs),
        fill: 'currentcolor',
    },

    ...focusStyle(theme),

    '&:hover': {
        color: theme.colors.palette.neutral90,
    },
}))
