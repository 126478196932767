import React from 'react'
import { FlagProps } from '.'

export function WestPerthFalcons({
    title = 'WestPerthFalcons',
    ...props
}: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g>
                <rect width="48" height="48" fill="#00529B" />
                <rect
                    x="-4.5"
                    y="46.5"
                    width="72"
                    height="8"
                    transform="rotate(-45 -4.5 46.5)"
                    fill="#E31835"
                />
            </g>
        </svg>
    )
}

export default WestPerthFalcons
