import styled from '@emotion/styled'
import { breakpoint } from '@the-game/components/utils'
import { TABLET_STICKY_HEIGHT } from '../utils/constants'
import { stickyStyles } from '../MobileSticky'

export const TabletSticky = styled('div')(({ theme }) => ({
    display: 'none',
    [breakpoint('sm')]: {
        ...stickyStyles(theme),

        height: TABLET_STICKY_HEIGHT,
    },
    [breakpoint('md')]: {
        display: 'none',
    },
}))

export default TabletSticky
