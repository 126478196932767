import React from 'react'
import { SvgIcon, SvgIconProps } from '../../Icons/SvgIcon'

export function Up({ title = 'Up rank', ...props }: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 12 12" title={title} {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h12v12H0z" />
                <path fill="#158936" d="M6 4l4 4H2z" />
            </g>
        </SvgIcon>
    )
}

export default Up
