import * as z from 'zod'

const sportMap = {
    afl: 'AFL',
    aflw: 'AFLW',
    cricket: 'Cricket',
    nrl: 'NRL',
    wafl: 'WAFL',
} as const

export const sportNames = Object.values(sportMap)

export const SportShape = z.enum(sportNames as [SportName, ...SportName[]])

export const SportSelectionShape = z.union([SportShape, z.literal('')])

export type SportName = typeof sportNames[number]

export function isSportName(sport: unknown): sport is SportName {
    if (typeof sport !== 'string') {
        return false
    }
    return (sportNames as string[]).includes(sport)
}

/**
 * Takes a lowercase string that looks like a sport, and converts it to a SportName.
 * Returns undefined if there is no matching sport.
 **/
export function massageSport(rough: string | undefined): SportName | undefined {
    if (!rough) {
        return undefined
    }

    return (sportMap as Record<string, SportName>)[rough.toLowerCase() as any]
}
