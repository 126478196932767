import React from 'react'
import { FlagProps } from '.'

export function SwanDistrict({ title = 'SwanDistrict', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g>
                <rect width="48" height="48" fill="#FFC526" />
                <rect
                    x="29"
                    width="48"
                    height="10"
                    transform="rotate(90 29 0)"
                    fill="black"
                />
                <rect
                    x="38"
                    width="48"
                    height="9"
                    transform="rotate(90 38 0)"
                    fill="white"
                />
                <rect
                    x="19"
                    width="48"
                    height="9"
                    transform="rotate(90 19 0)"
                    fill="white"
                />
                <rect
                    x="10"
                    width="48"
                    height="10"
                    transform="rotate(90 10 0)"
                    fill="black"
                />
                <rect
                    x="48"
                    width="48"
                    height="10"
                    transform="rotate(90 48 0)"
                    fill="black"
                />
            </g>
        </svg>
    )
}

export default SwanDistrict
