import React from 'react'
import { Heading } from '../Heading/Heading'
import { calcRem } from '@the-game/components/utils'
import { Football } from '../Icons/Football'
import { Box } from '../Box'
import { Spacing } from '../theme'
import styled from '@emotion/styled'

interface CardHeaderProps {
    children: React.ReactNode
    padding: Spacing
}

export function CardHeader({ padding, children }: CardHeaderProps) {
    return (
        <StyledCardHeader component="header" padding={padding} spacing="md">
            <Football size="sm" rotate={45} />
            <Heading level="3" size="6">
                {children}
            </Heading>
        </StyledCardHeader>
    )
}

const StyledCardHeader = styled(Box)<Pick<CardHeaderProps, 'padding'>>(
    ({ theme, padding }) => ({
        display: 'flex',
        alignItems: 'center',
        marginTop: calcRem(-theme.spacing[padding]),
        marginLeft: calcRem(-theme.spacing[padding]),
        marginRight: calcRem(-theme.spacing[padding]),
        background: theme.colors.cardHeader.background,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        color: theme.colors.cardHeader.text,

        svg: {
            fill: 'currentcolor',
            marginRight: calcRem(theme.spacing.xxs),
        },

        h3: {
            marginBottom: 0,
        },
    }),
)
