import React from 'react'
import { SvgIcon, SvgIconProps } from '../../Icons/SvgIcon'

export function Down({ title = 'Add player icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 12 12" title={title} {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h12v12H0z" />
                <path fill="#B30000" d="M6 8l4-4H2z" />
            </g>
        </SvgIcon>
    )
}

export default Down
