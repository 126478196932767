import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Logout({ title = 'Logout', ...props }: SvgIconProps) {
    return (
        <SvgIcon title={title} {...props} viewBox="0 0 24 24">
            <path d="M8.51,4 L8.51,6 L4.51,6 L4.51,18 L8.51,18 L8.51,20 L4.51,20 C3.40543,20 2.51,19.1046 2.51,18 L2.51,6 C2.51,4.89543 3.40543,4 4.51,4 L8.51,4 Z M15.09052,4.6572 L21.48142,10.9941 L15.14452,17.385 L13.72432,15.9768 L17.64352,12.0242 L8.50062,12.0242 C7.94832,12.0242 7.50062,11.5765 7.50062,11.0242 C7.50062,10.4719 7.94832,10.0242 8.50062,10.0242 L17.66272,10.0242 L13.68232,6.0774 L15.09052,4.6572 Z" />
        </SvgIcon>
    )
}

export default Logout
