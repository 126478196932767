import React from 'react'
import { Box } from '../Box'
import CSS from 'csstype'
import styled from '@emotion/styled'
import { Levels } from '../utils/types'
import { breakpoint, calcRem, headingPropsFromSize } from '../utils'

export interface HeadingProps {
    level?: Levels
    children?: React.ReactNode
    textAlign?: CSS.Property.TextAlign
    id?: string
    className?: string
    size?: Levels
    underlined?: boolean
}

const levelToComponent = {
    '1': 'h1',
    '2': 'h2',
    '3': 'h3',
    '4': 'h4',
    '5': 'h5',
    '6': 'h6',
} as const

export function Heading({
    level = '1',
    underlined = false,
    children,
    id,
    className,
    size = level,
    textAlign,
}: React.PropsWithChildren<HeadingProps>) {
    return (
        <StyledHeading
            id={id}
            component={levelToComponent[level]}
            className={className}
            size={size}
            textAlign={textAlign}
            underlined={underlined}
        >
            {children}
        </StyledHeading>
    )
}

const StyledHeading = styled(Box)<{ size: Levels; underlined: boolean }>(
    ({ theme, size }) => ({
        margin: 0,
        marginBottom: calcRem(theme.spacing.sm),
        fontWeight: theme.weights.bold,
        wordBreak: 'break-word',

        ...headingPropsFromSize(size),
    }),
    ({ underlined, theme }) =>
        underlined && {
            borderBottom: `1px solid ${theme.colors.palette.neutral50}`,
            textAlign: 'center',
            padding: theme.spacing.md,
            marginBottom: theme.spacing.xl,
            [breakpoint('md')]: {
                display: 'inline-flex',
                flexDirection: 'row',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingLeft: theme.spacing.xl,
                paddingRight: theme.spacing.xl,
            },
        },
)
