import React from 'react'
import { StyledButtonLink } from '../ButtonLink/ButtonLink.styled'
import { GenericButtonProps } from '../Button'
import { LinkProps } from 'react-router-dom'

export type LinkButtonProps = GenericButtonProps &
    React.HTMLProps<HTMLAnchorElement>

export function ButtonLink({
    className,
    children,
    kind = 'primary',
    fluid = false,
    variant,
    size = 'md',
    padding = 'auto',
    ...props
}: GenericButtonProps &
    Omit<React.HTMLProps<HTMLAnchorElement>, 'size'> &
    React.PropsWithoutRef<LinkProps<unknown>>): JSX.Element {
    const { to, shape } = props
    return (
        <StyledButtonLink
            {...{
                kind,
                fluid,
                padding,
                variant,
                to,
                size,
                className,
                shape,
            }}
        >
            {children}
        </StyledButtonLink>
    )
}

export default ButtonLink
