import React from 'react'
import { FlagProps } from '.'

export function Geelong({ title = 'Geelong', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path d="M0 19h48v10H0z" fill="#002D59" />
                <path d="M0 10h48v9H0zm0 19h48v9H0z" fill="#FFF" />
                <path d="M0 38h48v10H0zM0 0h48v10H0z" fill="#002D59" />
            </g>
        </svg>
    )
}

export default Geelong
