import styled from "@emotion/styled";
export const StyledBranding = styled("li")(
  ({ theme, logo }) => ({
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
    padding: 0,
    height: theme.spacing.lg
  })
);
export const StyledDropdownModal = styled("ul")(
  ({ theme, hide }) => ({
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.colors.white,
    visibility: hide ? "hidden" : void 0,
    boxSizing: "content-box",
    border: `2px solid ${theme.colors.primary}`,
    cursor: "pointer",
    li: {
      padding: theme.spacing.xs,
      '&[aria-selected="true"]': {
        backgroundColor: theme.colors.palette.neutral30
      }
    }
  })
);
