import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function CrossRound({ title = 'Cross', ...props }: SvgIconProps) {
    return (
        <SvgIcon
            {...props}
            title={title}
            data-testid="cross-mark"
            viewBox="0 0 24 24"
        >
            <path d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M9.51140295,8.14038077 L9.61289944,8.20970461 L9.70710678,8.29289322 L12,10.585 L14.2928932,8.29289322 C14.6834175,7.90236893 15.3165825,7.90236893 15.7071068,8.29289322 C16.0675907,8.65337718 16.0953203,9.22060824 15.7902954,9.61289944 L15.7071068,9.70710678 L13.415,12 L15.7071068,14.2928932 C16.0976311,14.6834175 16.0976311,15.3165825 15.7071068,15.7071068 C15.3466228,16.0675907 14.7793918,16.0953203 14.3871006,15.7902954 L14.2928932,15.7071068 L12,13.415 L9.70710678,15.7071068 C9.31658249,16.0976311 8.68341751,16.0976311 8.29289322,15.7071068 C7.93240926,15.3466228 7.90467972,14.7793918 8.20970461,14.3871006 L8.29289322,14.2928932 L10.585,12 L8.29289322,9.70710678 C7.90236893,9.31658249 7.90236893,8.68341751 8.29289322,8.29289322 C8.62333685,7.96244959 9.12750547,7.91161211 9.51140295,8.14038077 Z" />
        </SvgIcon>
    )
}

export default CrossRound
