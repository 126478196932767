import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function ArrowHorizontal({
    title = 'Horizontal Arrow',
    ...props
}: SvgIconProps) {
    return (
        <SvgIcon {...props} title={title} viewBox="0 0 24 10">
            <path d="M4.243.757l1.414 1.415L3.828 4h16.344l-1.829-1.828L19.757.757 24 5l-4.243 4.243-1.414-1.415L20.171 6H3.828l1.829 1.828-1.414 1.415L0 5z" />
        </SvgIcon>
    )
}
