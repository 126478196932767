export class SimpleDate {
    constructor({
        day,
        month,
        year,
    }: {
        day?: string
        month?: string
        year?: string
    }) {
        this.day = day ?? ''
        this.month = month ?? ''
        this.year = year ?? ''
    }

    day: string
    month: string
    year: string

    get isoDate() {
        if (this.day && this.month && this.year) {
            const day = Number(this.day)
            const month = Number(this.month)
            const year = Number(this.year)

            const iso = `${year}-${month >= 10 ? month : `${0}${month}`}-${
                day >= 10 ? day : `${0}${day}`
            }T00:00:00.000Z`

            return new Date(iso).toDateString() === 'Invalid Date'
                ? 'Invalid Date'
                : iso
        } else {
            return ''
        }
    }
}

export default SimpleDate
