import { SportName } from '@the-game/api-interfaces'
import React, { useEffect, useState } from 'react'
import {
    StyledSportSwitcherContainer,
    StyledTabs,
    StyledTab,
} from './SportSwitcher.styled'

export interface SportSwitcherProps {
    switchSport: (sport: SportName) => void
    selectedSport: SportName
    hasAflW: boolean
}

export function SportSwitcher({
    switchSport,
    selectedSport,
    hasAflW,
}: SportSwitcherProps) {
    const [selectedTab, switchTab] = useState<SportName>(selectedSport)

    function handleTabChange(sport: SportName) {
        switchTab(sport)
        return switchSport(sport)
    }

    useEffect(() => {
        switchTab(selectedSport)
    }, [selectedSport])

    return (
        <StyledSportSwitcherContainer>
            <StyledTabs>
                <StyledTab
                    onClick={() => handleTabChange('AFL')}
                    isActive={selectedTab === 'AFL'}
                >
                    AFL
                </StyledTab>
                {hasAflW && (
                    <StyledTab
                        onClick={() => handleTabChange('AFLW')}
                        isActive={selectedTab === 'AFLW'}
                    >
                        AFLW
                    </StyledTab>
                )}
                <StyledTab
                    onClick={() => handleTabChange('Cricket')}
                    isActive={selectedTab === 'Cricket'}
                >
                    Cricket
                </StyledTab>
            </StyledTabs>
        </StyledSportSwitcherContainer>
    )
}
