import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Random({ title = 'Random icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon title={title} viewBox="0 0 24 24" {...props}>
            <path d="M16.75,13.5 L21.25,16 L16.75,18.5 L16.75,17 L14.2962318,17 L12.578,15.037 L13.906,13.518 L15.203,15 L16.75,15 L16.75,13.5 Z M16.75,5.5 L21.25,8 L16.75,10.5 L16.75,9 L15.203,9 L8.20376823,17 L2.75,17 L2.75,15 L7.295,15 L14.2962318,7 L16.75,7 L16.75,5.5 Z M8.20376823,7 L9.92,8.963 L8.591,10.481 L7.295,9 L2.75,9 L2.75,7 L8.20376823,7 Z" />
        </SvgIcon>
    )
}

export default Random
