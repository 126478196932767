import * as z from 'zod'
import { RoundPlayerPosition } from '../api-interfaces'
import { SeasonShape } from './season'
import { SportShape } from './sport'

export const FormguidePlayerStatsUpdatedLifecycleEventShape = z.object({
    type: z.literal('formguide-player-stats-updated-lifecycle-event'),
    matchExternalId: z.string(),
    playerExternalId: z.string(),
    sport: SportShape,
    season: SeasonShape,
    round: z.number().optional(),

    playerName: z.string().optional(),
    positions: z.array(z.nativeEnum(RoundPlayerPosition)).optional(),

    seasonMatches: z.number().optional(),
    seasonAverageKicks: z.number().optional(),
    seasonAverageHandballs: z.number().optional(),
    seasonAverageDisposals: z.number().optional(),
    seasonAverageMarks: z.number().optional(),
    careerHighDisposals: z.number().optional(),
    careerHighMarks: z.number().optional(),
    careerHighGoals: z.number().optional(),

    seasonGoals: z.number().optional(),
    seasonBehinds: z.number().optional(),
    seasonAccuracyPercentage: z.number().optional(),
    seasonGoalAssists: z.number().optional(),
    seasonBehindAssists: z.number().optional(),
    seasonTotalAssists: z.number().optional(),
})
export type FormguidePlayerStatsUpdatedLifecycleEvent = z.TypeOf<
    typeof FormguidePlayerStatsUpdatedLifecycleEventShape
>
