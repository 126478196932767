import React from 'react'
import { FlagProps } from '.'

export function PerthScorchers({
    title = 'Perth Scorchers',
    ...props
}: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            {...props}
            viewBox="0 0 96 96"
        >
            <title>{title}</title>
            <g clipPath="url(#clip0_786:47630)">
                <rect
                    y="96"
                    width="96"
                    height="32"
                    transform="rotate(-90 0 96)"
                    fill="#C01B01"
                />
                <rect
                    x="32"
                    y="96"
                    width="96"
                    height="32"
                    transform="rotate(-90 32 96)"
                    fill="#F86401"
                />
                <rect
                    x="64"
                    y="96"
                    width="96"
                    height="32"
                    transform="rotate(-90 64 96)"
                    fill="#FFA001"
                />
            </g>
            <defs>
                <clipPath id="clip0_786:47630">
                    <rect width="96" height="96" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PerthScorchers
