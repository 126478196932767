import React from 'react'
import { StyledBaseWrapper, StyledBase } from './Base.styled'

export function Base({
    children,
    grey,
    takeover,
}: {
    children: React.ReactNode
    grey?: boolean
    takeover?: boolean
}) {
    return (
        <StyledBaseWrapper grey={grey}>
            <StyledBase takeover={takeover}>{children}</StyledBase>
        </StyledBaseWrapper>
    )
}
