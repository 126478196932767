import { Card } from "../Card/Card";
import styled from "@emotion/styled";
import { calcRem } from "@the-game/components/utils";
import { breakpoint } from "@the-game/components/utils";
import { StyledPill } from "../Pill/Pill.styled";
import { Heading } from "../Heading/Heading";
export const StyledCard = styled(Card)(
  ({ theme, newGame, isComingSoon }) => ({
    display: "grid",
    flexDirection: "column",
    gridGap: theme.spacing.xs,
    position: "relative",
    overflow: "hidden",
    padding: theme.spacing.sm,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    mergin: theme.spacing.sm,
    // Start Tipping / Register now Button Styling
    "> a": {
      position: "relative",
      top: "-10px",
      justify: "space-between",
      padding: theme.spacing.xxs,
      paddingLeft: theme.spacing.md,
      marginLeft: theme.spacing.sm,
      marginTop: theme.spacing.md,
      fontSize: calcRem(16),
      zIndex: 2,
      minWidth: "12rem",
      maxWidth: "12rem",
      boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.0758031), 0px 12px 30px -11px rgba(0, 0, 0, 0.0529606), 0px 10px 14px -10px rgba(0, 0, 0, 0.0961265), 0px 8px 12px -8px rgba(0, 0, 0, 0.300344)"
    },
    [breakpoint("sm")]: {
      gridTemplateColumns: newGame || isComingSoon ? "180px 1fr 330px" : "auto 160px 330px",
      gridTemplateRows: "1fr auto",
      maxHeight: newGame ? calcRem(200) : "none",
      padding: theme.spacing.md,
      paddingLeft: theme.spacing.xxs,
      paddingTop: theme.spacing.md,
      paddingBottom: theme.spacing.xl,
      "> a": {
        gridRowStart: newGame ? "1" : "2",
        gridColumnStart: newGame ? "2" : "1",
        alignSelf: newGame ? "end" : "inherit",
        paddingLeft: theme.spacing.md
      }
    },
    [breakpoint("lg")]: {
      gridTemplateColumns: newGame || isComingSoon ? "160px 1fr 330px" : "auto 160px 330px",
      maxHeight: newGame ? calcRem(180) : "none",
      "> a": {
        marginLeft: newGame ? "0px" : theme.spacing.lg
      }
    }
  })
);
export const StyledHeading = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2rem auto",
  gridTemplateRows: "2rem 4rem",
  alignItems: "center",
  justifyContent: "flex-start",
  zIndex: 1,
  paddingLeft: theme.spacing.sm,
  h3: {
    marginBottom: 0
  },
  "> :last-child": {
    alignSelf: "start",
    gridColumn: "1 / -1"
  },
  [breakpoint("md")]: {
    marginTop: theme.spacing.md,
    marginLeft: theme.spacing.md
  }
}));
export const CricketTippingGraphic = styled("img")(
  ({ newGame, isComingSoon }) => ({
    position: "absolute",
    right: "-32%",
    top: "-5px",
    zIndex: 0,
    [breakpoint("sm")]: {
      right: newGame || isComingSoon ? "-15%" : "15%",
      width: "70%",
      top: "-20px"
    }
  })
);
export const AflTippingGraphic = styled("img")(
  ({ newGame, isComingSoon }) => ({
    position: "absolute",
    right: "-100px",
    top: "-63px",
    width: "422px",
    zIndex: 0,
    [breakpoint("sm")]: {
      right: newGame || isComingSoon ? "-2%" : "21%",
      width: "500px",
      top: "-141px"
    }
  })
);
export const StyledComingSoon = styled("div")(({ theme }) => ({
  position: "relative",
  padding: `${calcRem(theme.spacing.xxs)} 0 0 ${calcRem(theme.spacing.sm)}`,
  [breakpoint("sm")]: {
    padding: 0,
    top: "60%"
  },
  [breakpoint("lg")]: {
    left: "15%"
  }
}));
export const StyledYouAreAllSet = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.palette.green500,
  borderRadius: "8px",
  position: "relative",
  padding: calcRem(8),
  fontFamily: theme.fontFamily,
  color: theme.colors.palette.white,
  [breakpoint("sm")]: {
    padding: calcRem(16),
    width: calcRem(188),
    marginLeft: calcRem(32),
    top: "11%"
  }
}));
export const StyledSeasonDate = styled("span")(({ theme }) => ({
  color: theme.colors.palette.neutral70,
  fontSize: calcRem(17),
  fontWeight: 500
}));
export const StyledCompCardPill = styled(StyledPill)(
  ({ theme }) => ({
    position: "absolute",
    zIndex: 1,
    lineHeight: calcRem(24),
    top: `-${24 / 2}px`,
    left: calcRem(theme.spacing.md),
    fontWeight: theme.weights.bold,
    fontStyle: "italic",
    fontSize: calcRem(14)
  })
);
export const StyledGameTypeHeading = styled(Heading)({
  wordBreak: "normal"
});
