import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Hamburger({
    title = 'Hamburger Icon',
    ...props
}: SvgIconProps) {
    return (
        <SvgIcon {...{ title, ...props }} viewBox="2 2 28 28">
            <path d="M28,22.6666667 C28.7364,22.6666667 29.3333333,23.2636 29.3333333,24 C29.3333333,24.6836762 28.8186306,25.2473279 28.1554986,25.3243614 L28,25.3333333 L4,25.3333333 C3.26362667,25.3333333 2.66666667,24.7362667 2.66666667,24 C2.66666667,23.3162 3.18139238,22.7526544 3.84450634,22.6756367 L4,22.6666667 L28,22.6666667 Z M28,14.6666667 C28.7364,14.6666667 29.3333333,15.2636 29.3333333,16 C29.3333333,16.7364 28.7364,17.3333333 28,17.3333333 L4,17.3333333 C3.26362667,17.3333333 2.66666667,16.7364 2.66666667,16 C2.66666667,15.2636 3.26362667,14.6666667 4,14.6666667 L28,14.6666667 Z M28,6.66666667 C28.7364,6.66666667 29.3333333,7.26362667 29.3333333,8 C29.3333333,8.73637333 28.7364,9.33333333 28,9.33333333 L4,9.33333333 C3.26362667,9.33333333 2.66666667,8.73637333 2.66666667,8 C2.66666667,7.26362667 3.26362667,6.66666667 4,6.66666667 L28,6.66666667 Z"></path>
        </SvgIcon>
    )
}

export default Hamburger
