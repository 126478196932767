import React from 'react'
import {
    StyledCard,
    StyledResultsStack,
    StyledTipsSubmittedStack,
    StyledScoreStack,
    StyledCorrectTips,
    StyledScore,
} from './ScoreSummaryCard.styled'
import { WinIndicator } from '../WinIndicator'
import { VisuallyHidden } from '../VisuallyHidden'
import { RoundStatus } from '@the-game/api-interfaces'
import { MarginCard } from '../MarginCard/MarginCard'
import { Card } from '../Card/Card'
import { Heading } from '../Heading/Heading'

export interface ScoreSummaryCardProps {
    roundStatus?: RoundStatus
    results?: ('won' | 'lost' | 'default')[]
    marginText: string
    tippedMargin?: number
    marginScore?: number
    winningMargin?: number
    tipped: number
    totalMatches: number
}

export function ScoreSummaryCard({
    roundStatus = RoundStatus.Scheduled,
    results = [],
    marginText,
    tippedMargin,
    marginScore,
    winningMargin,
    tipped,
    totalMatches,
}: ScoreSummaryCardProps): JSX.Element {
    const winningTipCount = results.filter((r) => r === 'won').length

    if (roundStatus === RoundStatus.Completed) {
        return (
            <StyledCard elevation={0}>
                <StyledScoreStack
                    direction="column"
                    justifyContent="space-between"
                >
                    <StyledCorrectTips>Correct tips</StyledCorrectTips>
                    <StyledScore>
                        <span aria-hidden="true">
                            {winningTipCount}/{results.length}
                        </span>
                        <VisuallyHidden>
                            ({winningTipCount} out of {results.length} tips
                            correct)
                        </VisuallyHidden>
                    </StyledScore>
                </StyledScoreStack>
                <StyledResultsStack
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                >
                    {results.map((result, i) => (
                        <WinIndicator variant={result} key={i} />
                    ))}
                </StyledResultsStack>
                <MarginCard
                    isResultIn
                    isSummary
                    tippedMargin={tippedMargin}
                    marginScore={marginScore}
                    winningMargin={winningMargin}
                    marginText={marginText}
                />
            </StyledCard>
        )
    }

    return (
        <Card elevation={0}>
            <StyledTipsSubmittedStack>
                <Heading level="6">Tips submitted</Heading>
                <Heading level="6">
                    <span aria-hidden="true">
                        {tipped}/{totalMatches}
                    </span>
                    <VisuallyHidden>
                        ({tipped} out of {totalMatches} matches)
                    </VisuallyHidden>
                </Heading>
            </StyledTipsSubmittedStack>
        </Card>
    )
}
