import React from 'react'
import formatDistance from 'date-fns/formatDistance'
import { PremiumIcon } from './PremiumIcon'
import {
    NewsCardProps,
    StyledDate,
    StyledNewsCard,
    StyledOverlay,
    StyledTitle,
} from './NewsCard.styled'

export function NewsCard({
    title,
    image,
    link,
    isPremium,
    date,
    isFirst = false,
}: NewsCardProps): JSX.Element {
    let requestSize = 1024
    if (window.devicePixelRatio < 2) {
        requestSize = isFirst ? 1024 : 500
    } else {
        requestSize = isFirst ? 1200 : 700
    }

    if (window.innerWidth < 600) {
        if (window.devicePixelRatio < 2) {
            requestSize = isFirst ? 550 : 270
        } else {
            requestSize = isFirst ? 1024 : 540
        }
    }

    return (
        <StyledNewsCard
            backgroundImage={`${image}?imwidth=${requestSize}`}
            href={link}
            target="_blank"
        >
            <StyledOverlay isFirst={isFirst} />
            {isPremium && <PremiumIcon />}
            <StyledTitle isFirst={isFirst}>{title}</StyledTitle>
            <StyledDate>
                {formatDistance(date, new Date(), { addSuffix: true })}
            </StyledDate>
        </StyledNewsCard>
    )
}

export default NewsCard
