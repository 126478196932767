import React from 'react'
import { TabTouchLogo } from '../logos'
import { VisuallyHidden } from '../VisuallyHidden'
import {
    MultiBetContainerStyled,
    StyledWinReturn,
    StyledBetNow,
    StyledWinAndBet,
    StyledBettingInformation,
} from './MultiBet.styled'

const oddsCurrencyFormat = Intl.NumberFormat('en-au', {
    currency: 'aud',
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
})

export interface MultiBetProps {
    winReturn: number | null
    tabtouchWebsiteUrl?: string
    tabTouchLinkPressed: (type: 'multi') => void
}

export function MultiBet({
    winReturn,
    tabtouchWebsiteUrl,
    tabTouchLinkPressed,
}: MultiBetProps) {
    if (!winReturn) {
        return null
    }

    const formattedReturn = oddsCurrencyFormat.format(winReturn)
    return (
        <MultiBetContainerStyled>
            <StyledBettingInformation>
                <TabTouchLogo fillText="#FFFFFF" />
                <div>
                    <p>
                        Back your tips with TABtouch.
                        <br /> A $1 Multi could win.
                    </p>
                    <small>Live odds subject to change.</small>
                </div>
            </StyledBettingInformation>
            <StyledWinAndBet>
                <StyledWinReturn>
                    <VisuallyHidden>Your multi return is </VisuallyHidden>
                    {formattedReturn}
                </StyledWinReturn>
                <StyledBetNow
                    onClick={() => tabTouchLinkPressed('multi')}
                    href={tabtouchWebsiteUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    Bet now
                </StyledBetNow>
            </StyledWinAndBet>
        </MultiBetContainerStyled>
    )
}
