import React from 'react'
import {
    StyledControlPlaceholder,
    StyledEllipsis,
    StyledPaginationButton,
    StyledControlContainer,
} from './Paginator.styled'
import cuid from 'cuid'

export interface PaginatorProps<T> {
    itemFromData: (item: T, nth: number) => React.ReactNode
    pageNumber: number
    pageSize: number
    totalItems: number
    goToPage: (pageNumber: number) => void
    pageData: T[] | undefined
    /** Optionally override the default container of the iterable content */
    container?: React.FC
    /** Optionally provide a padding element for empty rows on the last page */
    emptyItem?: React.FC
    /**  Displays a control placeholder as a fallback if we only have one row of pages, otherwise displays nothing */
    controlPlaceHolder?: boolean
}

function FinalContainer({
    children,
    alternateContainer: AlternateContainer,
}: {
    children: React.ReactNode
    alternateContainer?: React.FC
}) {
    return AlternateContainer ? (
        <AlternateContainer key="alternate-container">
            {children}
        </AlternateContainer>
    ) : (
        <div key="standard-container">{children}</div>
    )
}

function PageNumberStyledPaginationButton({
    StyledPaginationButtonNumber,
    current,
    goToPage,
}: {
    StyledPaginationButtonNumber: number
    current: boolean
    goToPage: (pageNumber: number) => void
}) {
    return (
        <StyledPaginationButton
            onClick={() => goToPage(StyledPaginationButtonNumber)}
            aria-label={`Go to page ${StyledPaginationButtonNumber}`}
            kind={current ? 'primary' : 'neutral'}
        >
            {StyledPaginationButtonNumber}
        </StyledPaginationButton>
    )
}

// The last page may not have as many elements as the other pages,
// we'll want to render padding rows so we take up as much space as the other pages
function EmptyItems<T>({
    emptyItem: EmptyItem,
    pageSize,
    totalItems,
    pageData,
}: {
    emptyItem: React.FC
    pageSize: number
    totalItems: number
    pageData: T[]
}): JSX.Element {
    const numberOfEmptyRows =
        Math.min(pageSize, totalItems || 0) - pageData.length
    const emptyElements: React.ReactNode[] = []
    for (let i = 0; i < numberOfEmptyRows; i++) {
        emptyElements.push(<EmptyItem key={`empty-page-row-${i}`} />)
    }
    return (emptyElements as unknown) as JSX.Element
}

export function Paginator<T>({
    itemFromData,
    pageNumber,
    pageSize,
    totalItems,
    goToPage,
    pageData = [],
    container,
    emptyItem,
    controlPlaceHolder,
}: PaginatorProps<T>) {
    const pageStyledPaginationButtons: React.ReactNode[] = []
    // If we don't know the total, assume a page size of one
    const washedTotalItems = totalItems || pageSize
    const numberOfPages = Math.ceil(washedTotalItems / pageSize)

    // We show at most 11 squares
    // Back, forward, first, last, 2xEllipses, 5 floating buttons
    // If we only have 9 pages, we have no need of ellipses,
    // just show all the buttons
    const needAnyEllipses = numberOfPages > 9

    const needStartEllipsis = needAnyEllipses && pageNumber > 5
    const needEndEllipsis = needAnyEllipses && pageNumber < numberOfPages - 4

    const justBeforeCurrentPage = pageNumber - 2
    const theLastSevenPages = numberOfPages - 6

    const firstButtonNumber = needStartEllipsis
        ? Math.max(Math.min(justBeforeCurrentPage, theLastSevenPages), 0)
        : 1

    const justAfterCurrentPage = pageNumber + 2
    const theFirstSevenPages = 7

    const lastButtonNumber = needEndEllipsis
        ? Math.min(
              Math.max(justAfterCurrentPage, theFirstSevenPages),
              numberOfPages,
          )
        : numberOfPages

    for (let i = firstButtonNumber; i <= lastButtonNumber; i++) {
        pageStyledPaginationButtons.push(
            <PageNumberStyledPaginationButton
                current={pageNumber === i}
                goToPage={goToPage}
                StyledPaginationButtonNumber={i}
                key={cuid()}
            />,
        )
    }

    const paginationControls = (
        <StyledControlContainer>
            <StyledPaginationButton
                onClick={() => goToPage(pageNumber - 1)}
                disabled={pageNumber === 1}
                kind="neutral"
                aria-label="Go to previous page"
            >
                &lt;
            </StyledPaginationButton>

            {needStartEllipsis && (
                <PageNumberStyledPaginationButton
                    current={pageNumber === 1}
                    goToPage={goToPage}
                    StyledPaginationButtonNumber={1}
                />
            )}
            {needStartEllipsis && <StyledEllipsis>...</StyledEllipsis>}
            {pageStyledPaginationButtons}
            {needEndEllipsis && <StyledEllipsis>...</StyledEllipsis>}
            {needEndEllipsis && (
                <PageNumberStyledPaginationButton
                    current={pageNumber === numberOfPages}
                    goToPage={goToPage}
                    StyledPaginationButtonNumber={numberOfPages}
                />
            )}
            <StyledPaginationButton
                kind="neutral"
                aria-label="Go to next page"
                disabled={pageNumber === numberOfPages}
                onClick={() => goToPage(pageNumber + 1)}
            >
                &gt;
            </StyledPaginationButton>
        </StyledControlContainer>
    )

    return (
        <>
            <FinalContainer alternateContainer={container}>
                {pageData.map(itemFromData)}
                {emptyItem && (
                    <EmptyItems
                        pageData={pageData}
                        pageSize={pageSize}
                        totalItems={totalItems}
                        emptyItem={emptyItem}
                    />
                )}
            </FinalContainer>
            {numberOfPages > 1 ? (
                paginationControls
            ) : controlPlaceHolder ? (
                <StyledControlPlaceholder />
            ) : null}
        </>
    )
}
