import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { Levels } from '../utils/types'
import React from 'react'

export interface SkeletonProps {
    width?: string | number
    height?: string | number
    size?: Levels
    variant?: 'default' | 'circle'
    borderRadius?: number
    offset?: number
}

const defaultSizes = {
    default: { width: 400, height: 200 },
    circle: { width: 40, height: 40 },
}

const pulse = keyframes({
    '0%': {
        opacity: 0.03,
    },
    '50%': {
        opacity: 0.055,
    },
    '100%': {
        opacity: 0.03,
    },
})

export const StyledSkeleton = styled('div')<SkeletonProps>(
    ({
        theme,
        variant = 'default',
        width = defaultSizes[variant].width,
        height,
        borderRadius = 4,
        offset = 0.5,
    }) => ({
        backgroundColor: theme.colors.black,
        opacity: 0.03,
        width,
        height,
        animation: `${pulse} 1.5s ease-in-out ${offset}s infinite`,
        borderRadius: variant === 'circle' ? '50%' : borderRadius,
    }),
)

export function Skeleton(props: SkeletonProps) {
    return <StyledSkeleton {...props} role="progressbar" />
}

export default Skeleton
