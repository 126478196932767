import * as z from 'zod'

const AtThisVenueShape = z.object({
    squadId: z.number(),
    squadName: z.string(),
    played: z.string(),
    wins: z.string(),
    losses: z.string(),
    lastTenMatchesWins: z.string(),
    lastTenMatchesLosses: z.string(),
    highestScore: z.string(),
    lowestScore: z.string(),
    greatestWinningMargin: z.string(),
})

const CurrentSeasonShape = z.object({
    squadId: z.number(),
    squadName: z.string(),
    played: z.string(),
    wins: z.string(),
    losses: z.string(),
    winsLast5games: z.string(),
    lossesLast5games: z.string(),
    winsLocal: z.string(),
    lossesLocal: z.string(),
    winsInterstate: z.string(),
    lossesInterstate: z.string(),
    avgPointsFor: z.string(),
    avgPointsAgainst: z.string(),
    highestScore: z.string(),
    lowestScore: z.string(),
    greatestWinningMargin: z.string(),
    playersUsed: z.string(),
})

const PositionShape = z.union([
    z.literal('BPL'),
    z.literal('BPR'),
    z.literal('FB'),
    z.literal('HBFL'),
    z.literal('HBFR'),
    z.literal('CHB'),
    z.literal('WL'),
    z.literal('WR'),
    z.literal('C'),
    z.literal('HFFL'),
    z.literal('HFFR'),
    z.literal('CHF'),
    z.literal('FPL'),
    z.literal('FPR'),
    z.literal('FF'),
    z.literal('RK'),
    z.literal('RR'),
    z.literal('R'),
    z.literal('INT'),
    z.literal('EMERG'),
    z.string(),
])

const TeamListPlayerShape = z
    .object({
        playerId: z.number(),
        playerName: z.string(),
        position: PositionShape,
    })
    .nonstrict()

const TeamListsShape = z
    .object({
        squadId: z.number(),
        squadName: z.string(),
        player: z.array(TeamListPlayerShape),
    })
    .nonstrict()

const PlayerAveragePlayerShape = z
    .object({
        playerId: z.string(), // it's a string here, but a number in team lists :/
        playerName: z.string(),
        seasonMatches: z.string(),
        seasonKicks: z.string(),
        seasonHandballs: z.string(),
        seasonDisposals: z.string(),
        seasonMarks: z.string(),
        careerHighDisposals: z.string(),
        careerHighMarks: z.string(),
        careerHighGoals: z.string(),
    })
    .nonstrict()

const PlayerAveragesShape = z
    .object({
        squadId: z.number(),
        squadName: z.string(),
        player: z.array(PlayerAveragePlayerShape),
    })
    .nonstrict()

const PlayerSeasonScoresAssistsPlayerShape = z
    .object({
        playerId: z.string(),
        playerName: z.string(),
        matches: z.string(),
        goals: z.string(),
        behinds: z.string(),
        accuracyPercentage: z.string(),
        goalAssists: z.string(),
        behindAssists: z.string(),
        totalAssists: z.string(),
    })
    .nonstrict()

const PlayerSeasonScoresAssistsShape = z
    .object({
        squadId: z.number(),
        squadName: z.string(),
        player: z.array(PlayerSeasonScoresAssistsPlayerShape),
    })
    .nonstrict()
export type PlayerSeasonScoresAssists = z.TypeOf<
    typeof PlayerSeasonScoresAssistsShape
>

const AflFormguideShapeBase = z
    .object({
        competitionId: z.number(),
        competitionName: z.string(),
        atThisVenue: z.object({
            homeTeam: AtThisVenueShape,
            awayTeam: AtThisVenueShape,
        }),
        currentSeason: z.object({
            homeTeam: CurrentSeasonShape,
            awayTeam: CurrentSeasonShape,
        }),
        teamLists: z.object({
            homeTeam: TeamListsShape,
            awayTeam: TeamListsShape,
        }),
        playerAverages: z.object({
            homeTeam: PlayerAveragesShape,
            awayTeam: PlayerAveragesShape,
        }),
    })
    .nonstrict()

const AflFormguideShape_2020 = AflFormguideShapeBase.extend({
    playerScoresAssists: z.object({
        homeTeam: PlayerSeasonScoresAssistsShape,
        awayTeam: PlayerSeasonScoresAssistsShape,
    }),
})
export type AflFormGuide2020 = z.TypeOf<typeof AflFormguideShape_2020>

const AflFormguideShape_2021 = AflFormguideShapeBase.extend({
    playerSeasonScoresAssists: z.object({
        homeTeam: PlayerSeasonScoresAssistsShape,
        awayTeam: PlayerSeasonScoresAssistsShape,
    }),
})
export type AflFormGuide2021 = z.TypeOf<typeof AflFormguideShape_2021>

const AflFormguideShape = z.union([
    AflFormguideShape_2020,
    AflFormguideShape_2021,
])
const FormguideReportShape = z.object({
    reportName: z.string(),
    reportJobId: z.number(),
    aflFormguide: AflFormguideShape,
})
export const FormguideShape = z.object({
    report: FormguideReportShape,
})

export type Formguide = z.TypeOf<typeof FormguideShape>
export type AtThisVenue = z.TypeOf<typeof AtThisVenueShape>
export type CurrentSeason = z.TypeOf<typeof CurrentSeasonShape>
export type AflFormguide = z.TypeOf<typeof AflFormguideShape>
