import { keyframes } from '@emotion/react'
import { calcRem } from '@the-game/components/utils'
import { IconSizes } from '../theme'
import styled from '@emotion/styled'

const spin = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
})

export const duration = 1400
export const Spinner = styled('div')<{ size?: IconSizes; offset?: number }>(
    ({ theme, size = 'xxxl', offset = 0 }) => ({
        border: `${calcRem(theme.metrics.icons[size] / 8)} solid ${
            theme.colors.palette.neutral30
        }`,
        borderTop: `${calcRem(theme.metrics.icons[size] / 8)} solid ${
            theme.colors.palette.neutral50
        }`,
        borderRadius: '50%',
        width: calcRem(theme.metrics.icons[size]),
        height: calcRem(theme.metrics.icons[size]),
        margin: 'auto',
        animation: `${spin} ${duration}ms linear infinite`,
        animationDelay: `${offset}ms`,
    }),
)

export default Spinner
