import {
    CompetitionTipsMatch,
    CompetitionTipsMember,
    TeamToWin,
} from '@the-game/api-interfaces'
import React from 'react'
import { Avatar } from '../Avatar/Avatar'
import { WinIndicator } from '../WinIndicator'
import { QuestionMark } from '../Icons/QuestionMark'
import {
    StyledGrid,
    StyledResult,
    StyledPlaceholder,
    StyledWrapper,
    StyledRowHeading,
    StyledHeader,
    StyledTeam,
    StyledLine,
    StyledUsername,
    StyledAvatar,
    EmptyRowContent,
} from './WhoTippedWhat.styled'
import {
    PaginationInformation,
    paginationReady,
} from '../pagination/pagination'
import { Paginator } from '../Paginator/Paginator'

export function TipResult({
    tip,
    match: { home, away, winner },
}: {
    tip: TeamToWin
    match: CompetitionTipsMatch
}) {
    return tip && tip !== 'NONE' ? (
        <StyledResult>
            <StyledAvatar
                elevation={1}
                size="auto"
                teamFlag={tip === 'HOME' ? home : away}
            />

            {winner !== 'NONE' && (
                <WinIndicator
                    variant={
                        tip === winner || winner === 'DRAW' ? 'won' : 'lost'
                    }
                />
            )}
        </StyledResult>
    ) : (
        <StyledPlaceholder>
            <QuestionMark themeColor="neutral50" />
        </StyledPlaceholder>
    )
}

export function WhoTippedWhat({
    currentUserMember,
    matches,
    pagination,
    goToPage,
    userColumnLabel = 'Member',
}: {
    currentUserMember?: CompetitionTipsMember
    members: CompetitionTipsMember[]
    matches: CompetitionTipsMatch[]
    pagination?: PaginationInformation<CompetitionTipsMember>
    goToPage: (page: number) => void
    userColumnLabel?: string
}) {
    return (
        <StyledWrapper>
            <StyledGrid columns={matches.length}>
                <StyledLine variant="faint" />
                <StyledRowHeading>{userColumnLabel}</StyledRowHeading>
                {matches.map(({ home, away, winner, id }) => (
                    <StyledHeader key={id}>
                        <StyledTeam winner={winner === 'HOME'}>
                            {home}
                        </StyledTeam>{' '}
                        <span>vs</span>
                        <StyledTeam winner={winner === 'AWAY'}>
                            {away}
                        </StyledTeam>
                    </StyledHeader>
                ))}

                {currentUserMember && (
                    <React.Fragment>
                        <StyledLine variant={'blue'} />
                        <StyledUsername data-testid={'user-member-tips'}>
                            <Avatar
                                src={currentUserMember.avatar}
                                teamFlag={currentUserMember.teamFlag}
                                name={currentUserMember.name}
                            />
                            {currentUserMember.name}
                        </StyledUsername>
                        {matches.map((match, i) => (
                            <TipResult
                                tip={currentUserMember.tips[i]}
                                match={match}
                                key={`result-user-${i}`}
                            />
                        ))}
                    </React.Fragment>
                )}

                {paginationReady(pagination) && (
                    <Paginator
                        {...pagination}
                        pageSize={pagination.pageSize}
                        totalItems={pagination.totalItems}
                        goToPage={goToPage}
                        container={React.Fragment}
                        emptyItem={() => (
                            <>
                                <StyledLine variant="faint" />
                                <EmptyRowContent />
                            </>
                        )}
                        itemFromData={({ name, avatar, teamFlag, tips }, i) => {
                            return (
                                <React.Fragment key={`row-${i}`}>
                                    <StyledLine
                                        variant={i === 0 ? 'blue' : 'faint'}
                                    />

                                    <StyledUsername>
                                        <Avatar
                                            src={avatar}
                                            teamFlag={teamFlag}
                                            name={name}
                                        />{' '}
                                        {name}
                                    </StyledUsername>

                                    {matches.map((match, i) => (
                                        <TipResult
                                            tip={tips[i]}
                                            match={match}
                                            key={`result-${i}`}
                                        />
                                    ))}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
                <StyledLine variant="strong" />
            </StyledGrid>
        </StyledWrapper>
    )
}

export default WhoTippedWhat
