import React from 'react'
import { StyledButtonGroup } from './ButtonGroup.styled'

export interface ButtonGroupItemProps {
    id: string
    children: React.ReactNode
    disabled?: boolean
    value: string
    isChecked?: boolean
    onClick?: () => void
    ref?: React.RefObject<HTMLButtonElement>
}

export interface ButtonGroupProps {
    defaultValue?: string
    children: React.ReactElement<ButtonGroupItemProps>[]
    disabled?: boolean
    className?: string
    onChange: (value: string) => void
    'aria-labelledby'?: string
}

export function ButtonGroup({
    children,
    defaultValue,
    onChange,
    disabled,
    className,
}: ButtonGroupProps) {
    const itemRefs = React.useRef<React.RefObject<HTMLButtonElement>[]>()

    const getItemRefs = React.useCallback(() => {
        // Dan says: https://github.com/facebook/react/issues/14490#issuecomment-454973512
        const instance = itemRefs.current

        if (instance !== undefined) {
            return instance
        }

        const refs = React.Children.map(children, () => {
            return React.createRef<HTMLButtonElement>()
        })

        itemRefs.current = refs
        return refs
    }, [children])

    const [focusIndex, setFocusIndex] = React.useState(() => {
        const valueIndex = children.findIndex(
            (child) => child.props.value === defaultValue,
        )
        return valueIndex !== -1 ? valueIndex : 0
    })
    const prevFocusIndex = React.useRef(focusIndex)

    const items = React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
            onClick: () => {
                setFocusIndex(index)
            },
            isChecked: focusIndex === index,
            ref: getItemRefs()[index],
            disabled,
        })
    })

    React.useEffect(() => {
        if (focusIndex !== prevFocusIndex.current) {
            getItemRefs()[focusIndex].current?.focus()
            prevFocusIndex.current = focusIndex
            onChange(children[focusIndex].props.value)
        }
    }, [focusIndex, getItemRefs, children, onChange])

    // Interactivity modelled on WAI Aria best practices
    // https://www.w3.org/TR/wai-aria-practices-1.1/examples/radio/radio-1/radio-1.html
    function onKeyDownHandler(evt: React.KeyboardEvent<HTMLDivElement>) {
        const wasKeyBack = ['ArrowLeft', 'ArrowUp'].includes(evt.key)
        const wasKeyForward = ['ArrowRight', 'ArrowDown'].includes(evt.key)

        if (wasKeyBack) {
            const updatedFocus = focusIndex - 1
            if (updatedFocus < 0) {
                setFocusIndex(React.Children.count(children) - 1)
                return
            }
            setFocusIndex(updatedFocus)
            return
        }

        if (wasKeyForward) {
            const updatedFocus = focusIndex + 1
            if (updatedFocus >= React.Children.count(children)) {
                setFocusIndex(0)
                return
            }
            setFocusIndex(updatedFocus)
            return
        }
    }

    return (
        <StyledButtonGroup
            role="radiogroup"
            onKeyDown={onKeyDownHandler}
            className={className}
        >
            {items}
        </StyledButtonGroup>
    )
}
