import styled from "@emotion/styled";
import { calcRem } from "../utils";
export const StyledEditorWrapper = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.colors.palette.neutral50}`,
  padding: `${calcRem(theme.spacing.md)} ${calcRem(
    theme.spacing.md
  )} 0 ${calcRem(theme.spacing.md)}`
}));
export const StyledControlsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  borderBottom: `1px solid ${theme.colors.palette.neutral50}`
}));
export const StyledConrols = styled("div")(({ theme }) => ({
  cursor: "pointer",
  marginBottom: calcRem(theme.spacing.md)
}));
export const StyledEditor = styled("div")(({ theme }) => ({
  margin: `${calcRem(theme.spacing.md)} 0`,
  minHeight: "15vh"
}));
export const StyledControlButton = styled("span")(
  ({ theme, isActive }) => ({
    color: isActive ? theme.colors.palette.bayBlue : theme.colors.palette.neutral70,
    cursor: "pointer",
    marginRight: calcRem(theme.spacing.md)
  })
);
export const CustomEditorClasses = styled.div`
    .align-left > div {
        text-align: left;
    }
    .align-center > div {
        text-align: center;
    }
    .align-right > div {
        text-align: right;
    }
`;
