import * as z from 'zod'

export const LadderItemShape = z.object({
    position: z.number(),
    squadId: z.number(),
    squadName: z.string(),
    played: z.number(),
    win: z.number(),
    loss: z.number(),
    drawn: z.number(),
    bye: z.number(),
    homeWins: z.number(),
    homeLosses: z.number(),
    homeDraws: z.number(),
    awayWins: z.number(),
    awayLosses: z.number(),
    awayDraws: z.number(),
    ptsFor: z.number(),
    ptsAgainst: z.number(),
    percentage: z.number(),
    points: z.number(),
    last5: z.string(),
})

export const LadderShape = z
    .object({
        report: z
            .object({
                reportName: z.string(),
                reportJobId: z.number(),
                aflLadder: z
                    .object({
                        roundNumber: z.number().optional(),
                        competitionId: z.number(),
                        competitionName: z.string(),
                        ladder: z.array(LadderItemShape),
                    })
                    .nonstrict(),
            })
            .nonstrict(),
    })
    .nonstrict()

export type Ladder = z.TypeOf<typeof LadderShape>
export type LadderItem = z.TypeOf<typeof LadderItemShape>
