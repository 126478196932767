import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { theme, ThemeColorOverrideMap } from './theme'
import { SportName } from '@the-game/api-interfaces'
import { mergeThemeColorValues } from './utils'

interface GameThemeProviderProps {
    children?: React.ReactNode
    colorOverrides?: ThemeColorOverrideMap
    sport?: SportName
}

export function GameThemeProvider({
    children,
    colorOverrides,
    sport,
}: GameThemeProviderProps) {
    const updatedTheme = mergeThemeColorValues(theme, sport, colorOverrides)

    return <ThemeProvider theme={updatedTheme}>{children}</ThemeProvider>
}

export default GameThemeProvider
