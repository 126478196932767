import React from 'react'
import { InputVariants } from '@the-game/components/utils'
import { StyledFormHelp } from './FormHelper.styled'

export interface FormHelpProps {
    variant?: InputVariants
}

export function FormHelp({
    children,
    variant,
}: React.PropsWithChildren<FormHelpProps>) {
    return <StyledFormHelp variant={variant}>{children}</StyledFormHelp>
}

export default FormHelp
