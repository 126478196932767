import styled from "@emotion/styled";
import { Card } from "@the-game/components";
import { calcRem, breakpoint } from "@the-game/components/utils";
export const StyledLadderCard = styled(Card)(({ theme }) => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "3rem 1fr",
  gridTemplateRows: "1fr 1fr",
  gridGap: theme.spacing.sm,
  flexDirection: "row",
  justifyContent: "flex-start",
  overflow: "hidden",
  alignItems: "center",
  "> h3": {
    marginBottom: 0
  },
  a: {
    zIndex: 2,
    gridColumnStart: -2,
    gridRowStart: 2,
    maxWidth: "12rem",
    justifySelf: "flex-end"
  },
  [breakpoint("sm")]: {
    gridTemplateColumns: "auto 1fr auto",
    gridTemplateRows: "1fr",
    height: calcRem(80),
    gridRowStart: 1,
    a: {
      gridRowStart: 1
    }
  }
}));
