import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Check({ title = 'Check mark', ...props }: SvgIconProps) {
    return (
        <SvgIcon
            {...props}
            title={title}
            data-testid="check-mark"
            viewBox="0 0 16 12"
        >
            <path d="M6 12L0 6l2-2 4 4 8-8 2 2z" />
        </SvgIcon>
    )
}

export default Check
