import React from 'react'
import { Content } from '../Content'
import { Spinner, duration } from '../Spinner'

export function LoadingSplashScreen({
    children,
}: React.PropsWithChildren<React.ReactNode>) {
    const offset = -(Date.now() % duration)
    return (
        <Content layout="splash">
            <Spinner offset={offset} role="progressbar" />
            {children}
        </Content>
    )
}

export default LoadingSplashScreen
