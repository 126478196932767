import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Plus({ title = 'Plus icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon title={title} viewBox="1 1 22 22" {...props}>
            <path d="M12,2 C17.5228,2 22,6.47715 22,12 C22,17.5228 17.5228,22 12,22 C6.47715,22 2,17.5228 2,12 C2,6.47715 6.47715,2 12,2 Z M12,4 C7.58172,4 4,7.58172 4,12 C4,16.4183 7.58172,20 12,20 C16.4183,20 20,16.4183 20,12 C20,7.58172 16.4183,4 12,4 Z M12,6 C12.51285,6 12.9355092,6.38604429 12.9932725,6.88337975 L13,7 L13,11 L17,11 C17.5523,11 18,11.4477 18,12 C18,12.51285 17.613973,12.9355092 17.1166239,12.9932725 L17,13 L13,13 L13,17 C13,17.5523 12.5523,18 12,18 C11.48715,18 11.0644908,17.613973 11.0067275,17.1166239 L11,17 L11,13 L7,13 C6.44772,13 6,12.5523 6,12 C6,11.48715 6.38604429,11.0644908 6.88337975,11.0067275 L7,11 L11,11 L11,7 C11,6.44772 11.4477,6 12,6 Z"></path>
        </SvgIcon>
    )
}

export default Plus
