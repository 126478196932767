import React from 'react'
import { Button } from '../Button'
import {
    calcRem,
    focusStyle,
    breakpoint,
    fontRemScale,
} from '@the-game/components/utils'
import { ChevronLeft } from '../Icons/ChevronLeft'
import { ChevronDown } from '../Icons/ChevronDown'
import { Select } from '../Select/Select'
import { Skeleton } from '../Skeleton'
import styled from '@emotion/styled'
import { VisuallyHidden } from '../VisuallyHidden'
import { SportName } from '@the-game/api-interfaces'
import { modifyLuminance } from '../utils/modifyLuminance'

export interface RoundNavigatorProps {
    totalRounds: number
    value: number | undefined
    leaderboard?: boolean
    sport: SportName
    onChange: (value: number | undefined) => void
}

const StyledGrid = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: `${calcRem(56)} auto ${calcRem(56)} `,
    gridTemplateRows: calcRem(56),
    gridGap: theme.spacing.xxs,
    button: {
        width: '100%',
        height: '100%',
    },
}))

const StyledSelect = styled(Select)(({ theme }) => ({
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    fontSize: fontRemScale(1.5),
    backgroundImage: 'none',
    padding: 0,
    paddingLeft: theme.spacing.md,
    fontWeight: theme.weights.bold,
    display: 'flex',
    flexAlign: 'center',
    width: '100%',
    height: '100%',
    border: 'none',
    position: 'absolute',
    transition: 'all 0.1s ease-in-out',
    option: {
        backgroundColor: theme.colors.white,
        color: theme.colors.palette.neutral90,
    },
    '&:focus': {
        backgroundColor: modifyLuminance(theme.colors.primary, 50),
    },

    ...focusStyle(theme),

    '&:hover': {
        backgroundColor: modifyLuminance(theme.colors.primary, 50),
        ...theme.elevations.Elevation2,
    },
    [breakpoint('md')]: {
        textAlignLast: 'right',
        paddingRight: `calc(50% - ${calcRem(52)})`,
        paddingLeft: `calc(40% - ${calcRem(65)})`,
    },
}))

const StyledFlex = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    svg: {
        position: 'absolute',
        right: 20,
    },

    [breakpoint('md')]: {
        svg: {
            position: 'absolute',
            right: `calc(50% - ${calcRem(80)})`,
        },
    },
})

function RoundNavigatorSkeleton() {
    const props = {
        width: '100%',
        height: '100%',
    }
    return (
        <StyledGrid>
            <Skeleton {...props} /> <Skeleton {...props} />{' '}
            <Skeleton {...props} />
        </StyledGrid>
    )
}

export function RoundNavigator({
    value,
    totalRounds,
    leaderboard = false,
    sport,
    onChange,
}: RoundNavigatorProps) {
    const rounds = React.useMemo(() => {
        const knownRounds = new Set(
            new Array(totalRounds).fill(null).map((_, i) => i + 1),
        ) // 1, 2, 3, ..., {totalRounds - 1}, {totalRounds}
        if (value) {
            knownRounds.add(value)
        }

        //Add Support for AFL 2024's Round '0'
        if (sport === 'AFL') {
            knownRounds.add(0)
        }

        return [...knownRounds.values()]
    }, [sport, totalRounds, value])

    if (value === null) {
        return <RoundNavigatorSkeleton />
    }

    rounds.sort((a, b) => a - b)

    let thisIndex: number

    if (!!value || value === 0) {
        thisIndex = rounds.indexOf(value)
    }

    const handlePreviousRound = () => {
        if (value === undefined) {
            //In theory this shouldn't happen
            onChange(undefined)
        } else {
            const prevRound = value === 0 ? undefined : rounds[thisIndex - 1]
            onChange(prevRound)
        }
    }

    const handleNextRound = () => {
        if (value === undefined) {
            // If value is undefined, set it to the first round i.e Round 0 for AFL, Round 1 for WAFL / Cricket
            onChange(rounds[0])
        } else {
            onChange(value + 1)
        }
    }

    const handleRoundChange = (newValue: string | undefined) => {
        if (newValue === 'undefined') {
            onChange(undefined)
        } else {
            onChange(Number(newValue))
        }
    }

    const roundLabel = sport === 'Cricket' ? 'Round' : 'Round'
    return (
        <StyledGrid>
            <Button
                shape="square"
                /**
                 * Disable going back on the 'first' round, which is `0` for AFL (for 2024 at least)
                 * and `1` for WAFL and Cricket.
                 * If it's a leaderboard page, we do want to be able to go back to 'undefined',
                 * which will retrieve the overall Season standings
                 */
                disabled={
                    !leaderboard && value !== undefined
                        ? rounds.indexOf(value) === 0
                        : value === undefined
                }
                onClick={handlePreviousRound}
                padding={0}
            >
                <ChevronLeft size="xxs" themeColor="white" />
                <VisuallyHidden>Previous {roundLabel}</VisuallyHidden>
            </Button>
            <StyledFlex>
                <StyledSelect
                    value={value ?? 'undefined'}
                    aria-label={roundLabel}
                    defaultOption={false}
                    onChange={({ currentTarget }) =>
                        handleRoundChange(currentTarget.value)
                    }
                >
                    {!!leaderboard && (
                        <option key="season" value="undefined">
                            Season
                        </option>
                    )}
                    {rounds.map((num) => (
                        <option key={num} value={num}>
                            {num === 0
                                ? 'Opening Round'
                                : roundLabel + ' ' + num}
                        </option>
                    ))}
                </StyledSelect>
                <ChevronDown
                    size="xs"
                    themeColor="white"
                    pointerEvents="none"
                />
            </StyledFlex>

            <Button
                shape="square"
                padding={0}
                onClick={handleNextRound}
                disabled={value !== undefined && value >= totalRounds}
            >
                <ChevronLeft
                    title="Chevron right icon"
                    size="xxs"
                    themeColor="white"
                    rotate={180}
                />
                <VisuallyHidden>Next {roundLabel}</VisuallyHidden>
            </Button>
        </StyledGrid>
    )
}
