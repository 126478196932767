import React from 'react'
import { StyledSelect, StyledSelectProps } from './Select.styled'

export interface SelectProps
    extends StyledSelectProps,
        React.SelectHTMLAttributes<HTMLSelectElement> {
    required?: boolean
    defaultOption?: boolean
    placeholder?: string | undefined
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
    (
        {
            required = false,
            fluid = false,
            children,
            defaultOption = true,
            placeholder,
            ...props
        },
        ref,
    ) => (
        <StyledSelect
            fluid={fluid}
            required={required}
            {...props}
            isDefault={props.value === ''}
            ref={ref}
        >
            {defaultOption && (
                <option key="default" value="" disabled>
                    {placeholder ?? '-'}
                </option>
            )}

            {children}
        </StyledSelect>
    ),
)

export default Select
