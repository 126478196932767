import React from 'react'
import { FlagProps } from '.'

export function MelbourneRenegades({
    title = 'Melbourne Renegades',
    ...props
}: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            {...props}
            viewBox="0 0 96 96"
        >
            <title>{title}</title>
            <g clipPath="url(#clip0_786:47604)">
                <rect
                    x="-2"
                    y="-2"
                    width="141.421"
                    height="70.7107"
                    transform="rotate(45 -2 -2)"
                    fill="black"
                />
                <rect
                    x="48"
                    y="-52"
                    width="141.421"
                    height="70.7107"
                    transform="rotate(45 48 -52)"
                    fill="#D72E34"
                />
            </g>
            <defs>
                <clipPath id="clip0_786:47604">
                    <rect width="96" height="96" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default MelbourneRenegades
