import {
  calcRem,
  fontRemScale,
  insetFocusStyle
} from "@the-game/components/utils";
import styled from "@emotion/styled";
import Card from "../Card";
export const FormGuideAccordionButton = styled("button")(({ theme }) => ({
  cursor: "pointer",
  width: "100%",
  backgroundColor: theme.colors.white,
  borderTop: `1px solid ${theme.colors.palette.neutral50}`,
  borderBottom: `1px solid ${theme.colors.palette.neutral50}`,
  margin: 0,
  // override some weird Safari behaviour
  padding: calcRem(theme.spacing.md),
  borderLeft: 0,
  borderRight: 0,
  color: theme.colors.palette.neutral90,
  fontWeight: theme.weights.regular,
  fontSize: fontRemScale(1),
  boxShadow: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:focus": {
    backgroundColor: theme.colors.white,
    color: theme.colors.primary
  },
  ...insetFocusStyle(theme),
  "&:hover": {
    backgroundColor: theme.colors.white,
    color: theme.colors.primary
  },
  svg: {
    transition: ".3s",
    marginLeft: calcRem(theme.spacing.sm),
    height: calcRem(theme.metrics.icons.xs),
    width: calcRem(theme.metrics.icons.xs),
    path: {
      fill: theme.colors.primary
    }
  }
}));
export const StyledPercentageWrapper = styled("div")({
  height: calcRem(4),
  backgroundColor: "#FF8900",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gridColumn: "1 / -1"
});
export const StyledPercentageBar = styled("div")(
  ({ percentage, theme }) => ({
    width: `${percentage.toFixed()}%`,
    backgroundColor: theme.colors.primary,
    borderRight: `${theme.colors.white} 2px solid`,
    height: "100%",
    justifyContent: "flex-start"
  })
);
export const FormGuideWrapper = styled("ul")(
  ({ theme, toggled }) => ({
    maxHeight: toggled ? calcRem(1e3) : "0px",
    transition: `max-height ease .3s`,
    overflow: "hidden",
    backgroundColor: theme.colors.white,
    boxShadow: "none"
  })
);
export const FormGuideAccordionGrid = styled("li")(({ theme }) => ({
  display: "grid",
  maxWidth: calcRem(350),
  margin: "auto",
  gridTemplateColumns: `1fr auto 1fr`,
  gap: theme.spacing.md,
  padding: `${calcRem(theme.spacing.lg)} ${calcRem(theme.spacing.md)}`,
  borderBottom: `1px solid ${theme.colors.palette.neutral30}`,
  textAlign: "center",
  "& > *:first-child": {
    justifySelf: "start"
  },
  "& > *:last-child": {
    justifySelf: "end"
  },
  "& .data": {
    fontWeight: theme.weights.bolder,
    alignSelf: "center",
    fontSize: fontRemScale(1.25)
  },
  "& .title": {
    alignSelf: "center",
    textDecoration: "none",
    color: theme.colors.black,
    "&:hover": {
      color: theme.colors.primary
    }
  }
}));
export const StyledTitle = styled("span")(({ theme }) => ({
  fontWeight: theme.weights.bolder,
  alignSelf: "center",
  fontSize: fontRemScale(1.25)
}));
export const ShortFormGuideGrid = styled("li")(({ theme }) => ({
  display: "grid",
  maxWidth: calcRem(700),
  margin: "auto",
  width: "100%",
  gridTemplateColumns: `1fr auto 1fr`,
  gap: theme.spacing.md,
  padding: `${calcRem(6)} ${calcRem(16)}`,
  borderBottom: `1px solid ${theme.colors.palette.neutral30}`,
  textAlign: "center",
  "& > *:first-child": {
    justifySelf: "start"
  },
  "& > *:last-child": {
    justifySelf: "end"
  },
  "& .home-data": {
    fontWeight: theme.weights.bolder,
    alignSelf: "left",
    fontSize: fontRemScale(1.25)
  },
  "& .away-data": {
    fontWeight: theme.weights.bolder,
    alignSelf: "right",
    justifySelf: "right",
    fontSize: fontRemScale(1.25)
  },
  "& .title": {
    alignSelf: "center",
    textDecoration: "none",
    color: theme.colors.black,
    "&:hover": {
      color: theme.colors.primary
    }
  }
}));
export const StyledShortFormGuide = styled(Card)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "none",
  padding: calcRem(8),
  borderRadius: `0px 0px ${calcRem(16)} ${calcRem(16)}`,
  backgroundColor: theme.colors.palette.neutral30,
  // Needs to mimic the change the containing card itself uses
  "@media (max-width: 400px)": {
    borderRadius: `0px 0px ${calcRem(8)} ${calcRem(8)}`
  }
}));
