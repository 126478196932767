import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { environment } from '../../environments/environment'

export const initialiseRumSdk = () => {
    datadogRum.init({
        applicationId: 'cd9e4001-f879-4f91-8418-b4fc23482eab',
        clientToken: 'pub8d28e7cfa53e5c8fe8c9e2c34c8389cd',
        site: 'datadoghq.com',
        service: 'the-game',
        env: environment.production ? 'prod' : 'non-prod',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 20,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording()
}

export const initialiseBrowserLogs = () => {
    if (environment.production) {
        datadogLogs.init({
            clientToken: 'pubaf9574603a8fe44ace95a7ed7562ca0f',
            site: 'datadoghq.com',
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
            service: 'thegame.com.au',
            silentMultipleInit: true,
        })
    }
}
