import React from 'react'
import styled from '@emotion/styled'

interface TabTouchLogoProps {
    className?: string
    title?: string
    fillText?: string
}

const StyledSvg = styled('svg')(() => ({
    fill: 'none',
}))

export function TabTouchLogo({
    className,
    title = 'TAB Touch logo',
    fillText = '#48296C',
    ...props
}: TabTouchLogoProps) {
    return (
        <StyledSvg
            viewBox="0 0 206.2 127.5"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            role="img"
            focusable={false}
            {...props}
        >
            <title>{title}</title>

            <g>
                <path
                    d="M106.1 107H102.6V119.3C102.6 120.7 102.7 121 103.9 121H106C106.5 121 107 121.4 107 121.9V126.2C107 126.7 106.6 127 106 127.1C104.5 127.4 103.1 127.5 101.9 127.5C97 127.5 93.8 126 93.8 119.2V107L90.8 106.3C90.3 106.2 89.8 105.8 89.8 105.3V101.5C89.8 101 90.2 100.5 90.8 100.5H93.8V96.8C93.8 96.3 94.3 95.9 94.8 95.8L101.6 94.6C102.4 94.5 102.6 95 102.6 95.5V100.5H106.1C106.6 100.5 107 100.9 107 101.4V106.1C107 106.6 106.6 107 106.1 107Z"
                    fill={fillText}
                />
                <path
                    d="M120.9 127.5C113.3 127.5 108.9 123.2 108.9 115.1V112.5C108.9 104.5 113.3 100.1 120.9 100.1C128.5 100.1 132.8 104.4 132.8 112.5V115.1C132.8 123.1 128.5 127.5 120.9 127.5ZM124.1 112.5C124.1 108.9 123 107.5 120.9 107.5C118.8 107.5 117.6 108.9 117.6 112.5V115.1C117.6 118.7 118.8 120.2 120.9 120.2C123 120.2 124.1 118.8 124.1 115.1V112.5Z"
                    fill={fillText}
                />
                <path
                    d="M158.1 127.1H151.8C151.3 127.1 150.9 126.7 150.9 126.2V125.2C149.3 126.4 147.1 127.6 143.7 127.6C136.9 127.6 135.3 122.5 135.3 117.1V101.6C135.3 101.1 135.7 100.6 136.2 100.6H143C143.5 100.6 144 101.1 144 101.6V117C144 119.4 144.5 120.2 146.8 120.2C147.8 120.2 149.2 119.7 150.2 119.2V101.5C150.2 101 150.6 100.5 151.1 100.5H157.9C158.4 100.5 158.9 101 158.9 101.5V126.1C159 126.7 158.6 127.1 158.1 127.1Z"
                    fill={fillText}
                />
                <path
                    d="M178.8 126.7C177.3 127.1 175.2 127.4 172.6 127.4C165.6 127.4 161.7 122.9 161.7 115.5V112.1C161.7 104.7 165.6 100.1 172.6 100.1C175.3 100.1 177.6 100.5 178.8 100.8C179.4 100.9 179.7 101.2 179.7 101.8V107C179.7 107.5 179.4 108 178.7 107.9C177.1 107.8 175.7 107.6 173.5 107.6C171.5 107.6 170.4 108.8 170.4 112.2V115.6C170.4 119 171.5 120.2 173.5 120.2C175.7 120.2 177.2 120.1 178.7 119.9C179.4 119.8 179.7 120.2 179.7 120.8V125.9C179.7 126.4 179.4 126.6 178.8 126.7Z"
                    fill={fillText}
                />
                <path
                    d="M205.3 127.1H198.4C197.9 127.1 197.5 126.7 197.5 126.2V110.5C197.5 108.1 196.5 107.5 194.6 107.5C193.6 107.5 192.3 107.9 191.1 108.5V126.1C191.1 126.6 190.7 127.1 190.2 127.1H183.4C182.9 127.1 182.4 126.6 182.4 126.1V92.7C182.4 92.2 182.8 91.8 183.3 91.8H190.2C190.7 91.8 191.1 92.2 191.1 92.7V101.9C193.1 100.5 195.1 100.2 197.8 100.2C204.4 100.2 206.1 104.8 206.1 110.5V126.2C206.2 126.7 205.8 127.1 205.3 127.1Z"
                    fill={fillText}
                />
                <path
                    d="M55.2 94.8L53.5 88.3C53 86.3 50.8 84.7 48.6 84.7H35L24.8 127H33.9C35.3 127 36.6 126 36.9 124.7L38.3 118.8H49.4L55.2 94.8ZM40.4 109.9L43.3 97.8H44.3L47.2 109.9H40.4Z"
                    fill={fillText}
                />
                <path
                    d="M82.3 103.7C84.7 101.9 86.2 98.9 86.2 95.8C86.2 88.7 81.3 84.7 72.9 84.7H60L54.4 108V122.6C54.4 124.9 54.9 127 58.7 127H73.3C81.8 127 88 121.4 88 113.8C88 109.4 85.9 105.6 82.3 103.7ZM64.9 94.1H72.2C74.3 94.1 75.4 95.1 75.4 97C75.4 99.1 74.2 100.1 71.9 100.1H64.9V94.1ZM72.4 117.6H64.9V108.9H72.4C75.4 108.9 77.2 110.5 77.2 113.3C77.2 114.3 76.8 117.6 72.4 117.6Z"
                    fill={fillText}
                />
                <path
                    d="M22.3 127.1L24.1 119.6V94.9H30.1L32.6 84.7H2.2C0.7 84.7 0 85.3 0 86.8V92.9C0 94.2 0.8 94.9 2.2 94.9H12.3V125C12.3 126.4 13.1 127.1 14.7 127.1H21.2H22.3Z"
                    fill={fillText}
                />
                <path
                    d="M97.8 15.5L92.9 51.3L172.3 37.4L177.2 1.60001L97.8 15.5Z"
                    fill="#E7751D"
                />
                <path
                    d="M107.3 84.8L198.2 68.9L203.1 33.3L112.2 49.2L107.3 84.8Z"
                    fill="#D9D8D6"
                />
                <path
                    d="M107.6 33.7L105.8 45.3L100.9 46.2L102.7 34.7L101.3 18.1L106 17.3L106.2 27.2L109.4 16.7L113.8 15.9L107.6 33.7Z"
                    fill={fillText}
                />
                <path
                    d="M120.2 14.4C124.7 13.6 126.4 16.2 125.8 20.5L123.5 35.7C122.8 40.1 120.9 43.1 117.3 43.7L116.3 43.9C112.1 44.6 110 42.3 110.7 37.8L113 22.9C113.7 18.4 115.2 15.4 119.2 14.6L120.2 14.4ZM118.1 21.5L115.7 37.4C115.6 38.2 115.8 39 116.9 38.8C118 38.6 118.3 37.7 118.4 37L120.8 21.1C121 19.9 120.6 19.3 119.7 19.5C118.7 19.6 118.3 20.3 118.1 21.5Z"
                    fill={fillText}
                />
                <path
                    d="M128.8 13.3L133.8 12.4L130.4 34.7C130.2 35.8 130.6 36.4 131.4 36.3C132.3 36.1 132.6 35.5 132.8 34.3L136.2 12L141.2 11.1L137.8 33C137 38.1 135.2 40.5 131.6 41.2L130.5 41.4C126.8 42.1 124.7 39.9 125.4 35.2L128.8 13.3Z"
                    fill={fillText}
                />
                <path
                    d="M146.5 14.2C146.2 16.2 145.3 18 145 18.7L142.7 19.1C143.1 18.2 143.7 16.6 144 15.4L142.4 15.7L143.2 10.7L147.3 10L146.5 14.2Z"
                    fill={fillText}
                />
                <path
                    d="M148.3 9.8L153.3 8.9L152.3 27.8L157 8.3L161.7 7.5L154 36.8L147.8 37.9L148.3 9.8Z"
                    fill={fillText}
                />
                <path
                    d="M173 5.5L172.2 10.6L166.7 11.6L165.7 17.9L170.1 17.1L169.3 22.3L164.9 23.1L163.8 30.2L169.6 29.2L168.8 34.4L158.2 36.3L162.6 7.5L173 5.5Z"
                    fill={fillText}
                />
                <path
                    d="M116.3 80.2C113.3 80.7 112 78.3 112.4 75.4L115 58.2C115.5 54.7 116.8 51.3 121.1 50.6L122.1 50.4C126.9 49.6 127.9 52.4 127.3 56L126.8 59.1L122.1 59.9L122.6 56.8C122.8 55.6 122.6 55 121.5 55.2C120.6 55.4 120.3 56 120.1 57.2L117.7 73.1C117.5 74.4 117.9 75 118.8 74.8C119.7 74.6 120.3 74 120.5 72.8L121.5 66.6L119.7 66.9L120.5 62.1L127 61L124.3 78.4L121.4 78.9C121.4 78.3 121.2 77 121 76.3C120.5 77.5 118.9 79.7 116.8 80.1L116.3 80.2Z"
                    fill="#512D6D"
                />
                <path
                    d="M136.4 47.9C140.9 47.1 142.6 49.7 142 54L139.7 69.2C139 73.6 137.1 76.6 133.5 77.2L132.5 77.4C128.3 78.1 126.2 75.8 126.9 71.3L129.2 56.4C129.9 51.9 131.4 48.9 135.4 48.2L136.4 47.9ZM134.3 55L131.9 70.9C131.8 71.7 132 72.5 133.1 72.3C134.2 72.1 134.5 71.2 134.6 70.5L137 54.6C137.2 53.4 136.8 52.8 135.9 53C134.8 53.1 134.5 53.8 134.3 55Z"
                    fill="#512D6D"
                />
                <path
                    d="M155.9 44.8L155.1 49.9L151.7 50.5L148.1 74.2L143.2 75.1L146.8 51.4L143.3 52L144.1 46.9L155.9 44.8Z"
                    fill="#512D6D"
                />
                <path
                    d="M173.6 41.7L172.8 46.8L169.4 47.4L165.8 71.1L160.9 72L164.6 48.3L161.1 48.9L161.9 43.8L173.6 41.7Z"
                    fill="#512D6D"
                />
                <path
                    d="M175.3 69.4L170.4 70.3L174.8 41.5L179.7 40.6L178 51.6L180.6 51.1L182.3 40.1L187.2 39.2L182.8 68L177.9 68.9L179.8 56.8L177.2 57.3L175.3 69.4Z"
                    fill="#512D6D"
                />
                <path
                    d="M199 37.2L198.2 42.3L192.7 43.3L191.7 49.6L196.1 48.8L195.3 54L190.9 54.8L189.8 61.9L195.6 60.9L194.8 66.1L184.2 68L188.6 39.2L199 37.2Z"
                    fill="#512D6D"
                />
                <path
                    d="M204.6 31.7L173.6 37.1L178.7 0L96.6 14.4L91.3 52.9L110.8 49.5L105.7 86.5L152 78.4L147.5 90.2L199.3 70L204.6 31.7ZM92.9 51.3L97.8 15.5L177.2 1.6L172.3 37.4L92.9 51.3ZM112.2 49.2L203.1 33.3L198.2 68.9L107.3 84.8L112.2 49.2Z"
                    fill="#512D6D"
                />
                <path
                    d="M147.5 90.1L152.1 78.3L199.3 70L147.5 90.1Z"
                    fill="#8246AF"
                />
            </g>
        </StyledSvg>
    )
}

export default TabTouchLogo
