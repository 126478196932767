import { calcRem } from "@the-game/components/utils";
import styled from "@emotion/styled";
export const StyledLabel = styled("label")(({ theme }) => ({
  display: "block",
  marginBottom: theme.spacing.xs,
  fontFamily: theme.fontFamily
}));
export const StyledLabelRequired = styled("span")(({ theme }) => ({
  marginRight: calcRem(theme.spacing.xxs),
  color: theme.colors.palette.red500,
  fontWeight: theme.weights.medium
}));
