import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Trophy({ title = 'Trophy icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon title={title} viewBox="2 2 19 18" {...props}>
            <path d="M17,4 L17,11 C17,13.3434063 15.387885,15.3102019 13.2122675,15.8520469 L13,15.9 L13,18 L15,18 L15,20 L9,20 L9,18 L11,18 L11,15.9 C8.78908,15.4511781 7.10791125,13.5435883 7.0049973,11.2255704 L7,11 L7,4 L17,4 Z M15,6 L9,6 L9,11 C9,12.6569 10.3431,14 12,14 C13.597725,14 14.903664,12.7511226 14.9949075,11.1762773 L15,11 L15,6 Z M20,6 L20,11 L18,11 L18,6 L20,6 Z M6,6 L6,11 L4,11 L4,6 L6,6 Z"></path>
        </SvgIcon>
    )
}

export default Trophy
