import { getDiagInfo } from './diaginfo'

const subject = 'The Game Support Request'

export const contactMailToRef = (userSub?: string) =>
    `mailto:support@thegame.com.au?subject=${subject}&body=${getDiagInfo(
        userSub,
    )}`

export default contactMailToRef
