import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Info({ title = 'Info Icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon {...{ title, ...props }} viewBox="0 0 22 22">
            <path
                d="M332,852 C338.07508,852 343,856.924865 343,863 C343,869.07508 338.07508,874 332,874 C325.924865,874 321,869.07508 321,863 C321,856.924865 325.924865,852 332,852 Z M332,854 C327.029435,854 323,858.029435 323,863 C323,867.970588 327.029435,872 332,872 C336.970588,872 341,867.970588 341,863 C341,858.029435 336.970588,854 332,854 Z M332,860.97937 C332.5523,860.97937 333,861.4271 333,861.9794 L333,867.9794 C333,868.5317 332.5523,868.9794 332,868.9794 C331.4477,868.9794 331,868.5317 331,867.9794 L331,861.9794 C331,861.4271 331.4477,860.97937 332,860.97937 Z M332,857.05115 C332.5523,857.05115 333,857.49886 333,858.05115 C333,858.60343 332.5523,859.05115 332,859.05115 C331.4477,859.05115 331,858.60343 331,858.05115 C331,857.49886 331.4477,857.05115 332,857.05115 Z"
                transform="translate(-321 -852)"
            />
        </SvgIcon>
    )
}

export default Info
