import React from 'react'
import { Box } from '../Box'
import { visuallyHiddenStyle } from '@the-game/components/utils'
import styled from '@emotion/styled'

interface VisuallyHiddenProps {
    children: React.ReactNode
    component?: keyof React.ReactHTML
}

export const StyledBox = styled(Box)({
    ...visuallyHiddenStyle,
})

export function VisuallyHidden({
    children,
    component = 'span',
    ...props
}: VisuallyHiddenProps) {
    return (
        <StyledBox component={component} {...props}>
            {children}
        </StyledBox>
    )
}
