import React from 'react'
import { CompetitionMemberTile } from '../CompetitionMemberTile'
import { MemberDTO } from '@the-game/api-interfaces'
import styled from '@emotion/styled'

import { Button } from '../Button'
import { LinkButton } from '../LinkButton'
import { calcRem, fontRemScale } from '@the-game/components/utils'
import { Checkbox } from '../Checkbox/Checkbox'
import { Modal } from '../Modal'
import { Stack } from '../Stack'
import { Info } from '../Icons/Info'

export interface MemberListProps {
    members: MemberDTO[]
    isOwner: boolean
    competitionId: string
    currentUser: string
    removeMembers: (args: {
        competitionId: string
        members: string[]
    }) => Promise<any>
    joined: boolean
}

export const StyledHeadingWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
})

export const StyledHeading = styled('span')(({ theme }) => ({
    strong: {
        fontSize: fontRemScale(1.25),
        fontWeight: theme.weights.bold,
    },
}))

export const StyledGrid = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing.md,
    gridTemplateRows: calcRem(50),
    marginTop: theme.spacing.md,
}))

export const StyledModalGrid = styled('div')(({ theme }) => ({
    display: 'grid',
    gridGap: theme.spacing.md,
    gridTemplateColumns: '1fr 1fr',
    button: {
        marginTop: 8,
    },
    p: {
        gridColumnEnd: 'span 2',
    },
    strong: {
        gridColumnEnd: 'span 2',
        fontSize: calcRem(18),
        lineHeight: 24 / 18,
        display: 'flex',
        svg: {
            marginRight: theme.spacing.sm,
        },
    },
}))

export const StyledMemberList = styled(Stack)(({ theme }) => ({
    borderTop: `1px solid ${theme.colors.palette.neutral50}`,
    borderBottom: `1px solid ${theme.colors.palette.neutral50}`,
    maxHeight: '50vh',
    overflow: 'auto',
    li: {
        minHeight: calcRem(80),
        display: 'flex',
        alignItems: 'center',
        label: {
            height: '100%',

            padding: theme.spacing.sm,
            transition: theme.transitions.swift,
            '&:hover': {
                backgroundColor: theme.colors.palette.neutral30,
            },
        },
    },
}))

export function CompetitionMemberList({
    members,
    isOwner,
    competitionId,
    removeMembers,
    currentUser,
    joined,
}: MemberListProps) {
    const [isEditing, setIsEditing] = React.useState(false)
    const [modal, setModal] = React.useState(false)

    const [checklist, setChecklist] = React.useState<Record<string, boolean>>(
        {},
    )

    const toDelete = Object.entries(checklist)
        .filter(([_, val]) => val)
        .map(([key, _]) => key)

    const deletionCount = toDelete.length

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
        target,
    }) => {
        setChecklist({ ...checklist, [target.value]: !checklist[target.value] })
    }

    const handleCancel = () => {
        setIsEditing(false)
        setModal(false)
        setChecklist({})
    }

    const handleUpdate = async (members: string[]) => {
        setModal(false)

        const response = await removeMembers({
            competitionId,
            members,
        })

        if (response) {
            handleCancel()
        }
    }

    return (
        <>
            <StyledHeadingWrapper>
                <StyledHeading>
                    <strong>Members</strong> ({members?.length ?? 0})
                </StyledHeading>
                {isOwner ? (
                    isEditing ? (
                        <LinkButton variant="danger" onClick={handleCancel}>
                            Cancel
                        </LinkButton>
                    ) : (
                        <LinkButton
                            variant="danger"
                            onClick={() => setIsEditing(true)}
                        >
                            Remove Members
                        </LinkButton>
                    )
                ) : (
                    joined && (
                        <LinkButton
                            variant="danger"
                            onClick={() => setModal(true)}
                        >
                            Leave Competition
                        </LinkButton>
                    )
                )}
            </StyledHeadingWrapper>
            {isEditing ? (
                <form>
                    <StyledMemberList
                        direction="column"
                        component="ul"
                        dividers="subtle"
                    >
                        {members.map(({ userId, ...rest }) => (
                            <li key={userId}>
                                <Checkbox
                                    alignItems="center"
                                    fluid
                                    onChange={handleChange}
                                    value={userId}
                                    checked={checklist[userId] ?? false}
                                >
                                    <CompetitionMemberTile
                                        key={userId}
                                        {...rest}
                                    />
                                </Checkbox>
                            </li>
                        ))}
                    </StyledMemberList>
                </form>
            ) : (
                <StyledMemberList
                    component="ul"
                    direction="column"
                    dividers="subtle"
                >
                    {members.map(({ userId, ...rest }) => (
                        <CompetitionMemberTile
                            key={userId}
                            {...rest}
                            component="li"
                        />
                    ))}
                </StyledMemberList>
            )}

            <StyledGrid>
                {isEditing && deletionCount > 0 && (
                    <>
                        <Button
                            variant="ghost"
                            kind="neutral"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button onClick={() => setModal(true)} kind="danger">
                            Remove Members
                        </Button>
                    </>
                )}
            </StyledGrid>

            {modal && (
                <Modal>
                    {isOwner ? (
                        <StyledModalGrid>
                            <strong>
                                <Info size="sm" />
                                Remove Members
                            </strong>
                            <p>
                                Are you sure you want to remove {deletionCount}{' '}
                                member{deletionCount !== 1 && 's'}?
                            </p>
                            <p>
                                Removed members will no longer have access to
                                this competition.
                            </p>
                            <Button
                                variant="ghost"
                                kind="neutral"
                                onClick={() => setModal(false)}
                                autoFocus
                            >
                                No
                            </Button>
                            <Button
                                kind="danger"
                                onClick={() => handleUpdate(toDelete)}
                            >
                                Yes, remove
                            </Button>
                        </StyledModalGrid>
                    ) : (
                        <StyledModalGrid>
                            <strong>
                                <Info size="sm" />
                                Leave competition
                            </strong>
                            <p>
                                Are you sure you want to leave this competition?
                            </p>
                            <Button
                                variant="ghost"
                                kind="neutral"
                                onClick={() => setModal(false)}
                                autoFocus
                            >
                                No
                            </Button>
                            <Button
                                kind="danger"
                                onClick={() => handleUpdate([currentUser])}
                            >
                                Yes, leave
                            </Button>
                        </StyledModalGrid>
                    )}
                </Modal>
            )}
        </>
    )
}

export default CompetitionMemberList
