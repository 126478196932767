import React from 'react'
import { calcRem } from '@the-game/components/utils'
import { IconSizes, palette } from '../theme'
import styled from '@emotion/styled'
import { SportName } from '@the-game/api-interfaces'

export interface SvgIconProps extends React.SVGProps<SVGSVGElement> {
    className?: string
    title?: string
    size?: IconSizes | undefined
    themeColor?: keyof typeof palette
    viewBox?: string
    rotate?: number
    border?: number
    sport?: SportName
}

const StyledSvg = styled('svg')<SvgIconProps>(
    ({ theme, size, themeColor, rotate, border }) => {
        return {
            width: size ? calcRem(theme.metrics.icons[size]) : '100%',
            height: size ? calcRem(theme.metrics.icons[size]) : 'auto',
            fill: themeColor ? theme.colors.palette[themeColor] : 'bayBlue',
            transition: 'ease-in-out 0.1s all',
            transform: rotate ? `rotate(${rotate}deg)` : undefined,
            borderColor: themeColor
                ? theme.colors.palette[themeColor]
                : undefined,
            borderStyle: 'solid',
            borderWidth: border ?? 0,
            borderRadius: border ? '50%' : undefined,
            padding: border ? 3 : undefined,
        }
    },
)

export function SvgIcon({
    title = 'Icon',
    children,
    ...props
}: React.PropsWithChildren<SvgIconProps>) {
    return (
        <StyledSvg xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
            {title && <title>{title}</title>}
            {children}
        </StyledSvg>
    )
}

export default SvgIcon
