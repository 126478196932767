import React from 'react'
import { FlagProps } from '.'

export function SydneySixers({ title = 'Sydney Sixers', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            {...props}
            viewBox="0 0 96 96"
        >
            <title>{title}</title>
            <g clipPath="url(#clip0_786:47638)">
                <rect y="54" width="96" height="21" fill="#DCDCDC" />
                <rect y="75" width="96" height="21" fill="black" />
                <rect y="32" width="96" height="22" fill="white" />
                <rect width="96" height="32" fill="#FF0074" />
            </g>
            <defs>
                <clipPath id="clip0_786:47638">
                    <rect width="96" height="96" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SydneySixers
