import * as z from 'zod'

export type DeletePersonalNotificationsEvent = z.TypeOf<
    typeof DeletePersonalNotificationsEventShape
>

export const DeletePersonalNotificationsEventShape = z.object({
    type: z.literal('delete-personal-notifications'),
    timestamp: z.number(),
})
