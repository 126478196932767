import React, { ReactElement, useEffect, useState } from 'react'
import {
    StyledStickyStack,
    StyledSubmitButton,
    StickySubmitButtonProps,
    StyledContainer,
    Relative,
} from './StickySubmitButton.styled'
import { CheckRound } from '../Icons/CheckRound'
import { theme } from '../theme'

function SubmitContainer({
    children,
    sticky,
}: {
    children: React.ReactNode
    sticky: boolean
}) {
    return sticky ? (
        <Relative>
            <StyledStickyStack>{children}</StyledStickyStack>
            <StyledContainer></StyledContainer>
        </Relative>
    ) : (
        <StyledContainer>{children}</StyledContainer>
    )
}

export function StickySubmitButton({
    pristine,
    isSaved,
    isSaving,
    container,
    openModal,
}: StickySubmitButtonProps): ReactElement {
    const [stickButton, setStickButton] = useState(true)

    useEffect(() => {
        const determineStickiness = () => {
            const windowHeight = window.innerHeight
            const windowWidth = window.innerWidth
            const offset =
                windowWidth > theme.breakpoints.md
                    ? 2
                    : windowWidth > theme.breakpoints.sm
                    ? 90
                    : 50

            setStickButton(
                !!container &&
                    container.getBoundingClientRect().bottom >=
                        windowHeight - offset,
            )
        }

        window.addEventListener('scroll', determineStickiness)
        window.addEventListener('resize', determineStickiness)
        determineStickiness()
        return () => {
            window.removeEventListener('scroll', determineStickiness)
            window.removeEventListener('resize', determineStickiness)
        }
    }, [container])

    return (
        <SubmitContainer sticky={stickButton}>
            <StyledSubmitButton
                type="submit"
                kind={isSaved ? 'neutral' : 'action'}
                disabled={isSaving || isSaved}
                {...{ pristine, isSaved, isSaving }}
                onClick={openModal}
            >
                {pristine ? (
                    'Choose your tips'
                ) : isSaved ? (
                    <>
                        <CheckRound />
                        Tips submitted
                    </>
                ) : (
                    'Submit tips'
                )}
            </StyledSubmitButton>
        </SubmitContainer>
    )
}
