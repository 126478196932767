import React from 'react'
import { StyledLabelRequired } from '../Label/Label.styled'
import { calcRem } from '@the-game/components/utils'
import styled from '@emotion/styled'

export const StyledRequiredLegend = styled('div')(({ theme }) => ({
    marginBottom: calcRem(theme.spacing.xxl),
}))

export function RequiredLegend(): JSX.Element {
    return (
        <StyledRequiredLegend aria-hidden={true}>
            <StyledLabelRequired>*</StyledLabelRequired>
            Required information
        </StyledRequiredLegend>
    )
}
export default RequiredLegend
