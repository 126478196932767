import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function AvatarIcon({ title = 'Avatar icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon {...{ title, ...props }} viewBox="20 20 44 44">
            <path d="M42 20a22 22 0 110 44 22 22 0 010-44zm0 30c-5 0-9 2-11 6a18 18 0 0023 0c-3-4-7-6-12-6zm0-26a18 18 0 00-14 29 18 18 0 0128 0c3-3 4-7 4-11 0-10-8-18-18-18zm0 4a8 8 0 110 16 8 8 0 010-16zm0 4a4 4 0 100 8 4 4 0 000-8z" />
        </SvgIcon>
    )
}

export default AvatarIcon
