import * as z from 'zod'
import { RoundPlayerPosition, Team } from '../api-interfaces'
import { SeasonShape } from './season'
import { SportShape } from './sport'

export const SquadListsPlayerUpdatedLifecycleEventShape = z.object({
    type: z.literal('squad-list-player-updated-lifecycle-event'),
    rootKey: z.string().optional(),
    externalId: z.string(),
    givenName: z.string(),
    familyName: z.string(),
    jumperNumber: z.number().optional(),
    positions: z.array(z.nativeEnum(RoundPlayerPosition)).optional(),
    team: z.nativeEnum(Team),
    sport: SportShape,
    season: SeasonShape,
})
export type SquadListsPlayerUpdatedLifecycleEvent = z.TypeOf<
    typeof SquadListsPlayerUpdatedLifecycleEventShape
>
