import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Login({ title = 'Login', ...props }: SvgIconProps) {
    return (
        <SvgIcon title={title} {...props} viewBox="0 0 24 24">
            <path d="M4.514 20h4v-2h-4V6h4V4h-4a2 2 0 00-2 2v12a2 2 0 002 2zm10.747-4.023l-1.42 1.408-6.337-6.39 6.391-6.338 1.408 1.42-3.98 3.947h9.162a1 1 0 110 2h-9.143l3.92 3.953z" />
        </SvgIcon>
    )
}

export default Login
