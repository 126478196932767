import React from 'react'
import { FlagProps } from '.'

export function PortAdelaide({ title = 'Port Adelaide', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h48v48H0z" fill="#000" />
                <path
                    d="M-3.577-6.743L24 20.834 51.577-6.743 55.82-2.5 24 29.32-7.82-2.5l4.243-4.243z"
                    fill="#008BAE"
                />
                <path
                    d="M-3.577 1.757L24 29.334 51.577 1.757 55.82 6 24 37.82-7.82 6l4.243-4.243z"
                    fill="#FFF"
                />
            </g>
        </svg>
    )
}

export default PortAdelaide
