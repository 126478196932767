import React, { ChangeEvent } from 'react'
import Label from '../Label/Label'
import { Input } from '../Input/Input'
import { StyledDatePickerContainer } from './DatePicker.styled'
import FormControl from '../FormControl/FormControl'
import FormHelp from '../FormHelp/FormHelp'
import { Heading } from '../Heading/Heading'
import { SimpleDate } from '../utils/SimpleDate'

export interface DatePickerProps {
    onChange?: (arg: SimpleDate) => void | undefined
    required?: boolean | undefined
    id: string
    name?: string | undefined
    value?: SimpleDate | undefined
    subheading?: string | undefined
    minYear?: number | undefined
    maxYear?: number | undefined
    helperText?: string
    error?: boolean
}
export function DatePicker({
    id,
    required = false,
    onChange,
    value,
    subheading,
    helperText,
    error,
}: DatePickerProps): JSX.Element {
    const handleChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
        if (value && onChange) {
            onChange(
                new SimpleDate({
                    ...value,
                    [currentTarget.name]: currentTarget.value,
                }),
            )
        }
    }

    return (
        <FormControl>
            <Heading size="6" level="3">
                {subheading}
            </Heading>
            <StyledDatePickerContainer>
                <div>
                    <Label htmlFor={`${id}-day`} required={required}>
                        Day
                    </Label>
                    <Input
                        id={`${id}-day`}
                        name="day"
                        type="text"
                        placeholder="DD"
                        value={value?.day ?? ''}
                        onChange={handleChange}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        variant={error ? 'error' : 'normal'}
                        maxLength={2}
                        fluid
                        data-cy="day"
                    />
                </div>
                <div>
                    <Label htmlFor={`${id}-month`} required={required}>
                        Month
                    </Label>
                    <Input
                        id={`${id}-month`}
                        name="month"
                        type="text"
                        placeholder="MM"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        value={value?.month ?? ''}
                        onChange={handleChange}
                        variant={error ? 'error' : 'normal'}
                        maxLength={2}
                        fluid
                        data-cy="month"
                    />
                </div>
                <div>
                    <Label htmlFor={`${id}-year`} required={required}>
                        Year
                    </Label>
                    <Input
                        id={`${id}-year`}
                        name="year"
                        type="text"
                        placeholder="YYYY"
                        inputMode="numeric"
                        pattern="[0-9]{4}"
                        value={value?.year ?? ''}
                        variant={error ? 'error' : 'normal'}
                        onChange={handleChange}
                        maxLength={4}
                        fluid
                        data-cy="year"
                    />
                </div>
            </StyledDatePickerContainer>
            <FormHelp variant={error ? 'error' : 'normal'}>
                {helperText}
            </FormHelp>
        </FormControl>
    )
}

export default DatePicker
