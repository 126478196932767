import { FormattedAddress } from '@the-game/api-interfaces'
import React from 'react'
import { TextField } from '../TextField/TextField'

export interface ManualAddressFieldProps {
    onChange: (value: FormattedAddress) => void
    value: FormattedAddress
    required?: boolean
    fieldErrors?: { [key: string]: string | undefined }
}

export function ManualAddressField({
    required = false,
    onChange,
    value,
    fieldErrors,
}: ManualAddressFieldProps) {
    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ ...value, [target.name]: target.value })
    }
    return (
        <>
            <TextField
                label="Unit number"
                name="unitNumber"
                id="unit-number"
                fluid={false}
                value={value && value.unitNumber}
                onChange={handleChange}
                placeholder="Enter number"
            />
            <TextField
                required={required}
                label="Street number"
                name="streetNumber"
                id="street-number"
                fluid={false}
                value={value && value.streetNumber}
                onChange={handleChange}
                placeholder="Enter number"
                helperText={fieldErrors?.addressStreetNumber}
                error={!!fieldErrors?.addressStreetNumber}
            />
            <TextField
                label="Street name"
                required={required}
                name="streetName"
                value={value && value.streetName}
                id="street-name"
                placeholder="Enter street name"
                onChange={handleChange}
                helperText={fieldErrors?.addressStreetName}
                error={!!fieldErrors?.addressStreetName}
            />
            <TextField
                label="Suburb"
                required={required}
                name="suburb"
                value={value && value.suburb}
                placeholder="Enter suburb"
                onChange={handleChange}
                id="suburb"
                helperText={fieldErrors?.addressSuburb}
                error={!!fieldErrors?.addressSuburb}
            />
            <TextField
                label="Postcode"
                required={required}
                id="postcode"
                name="postcode"
                fluid={false}
                value={value && value.postcode}
                placeholder="Enter postcode"
                onChange={handleChange}
                helperText={fieldErrors?.addressPostcode}
                error={!!fieldErrors?.addressPostcode}
            />
        </>
    )
}
