import styled from "@emotion/styled";
import { focusStyle } from "@the-game/components/utils";
export const StyledLinkButton = styled("button")(({ theme, variant = "default" }) => ({
  display: "inline",
  padding: 0,
  appearance: "none",
  background: "transparent",
  border: 0,
  color: variant === "default" ? theme.colors.primary : variant === "white" ? theme.colors.white : theme.colors.loss,
  cursor: "pointer",
  textTransform: "none",
  textDecoration: "underline",
  transition: "color linear 0.3s",
  fill: theme.colors.primary,
  ...focusStyle(theme),
  "&:hover": {
    color: theme.colors.palette.neutral90,
    fill: theme.colors.palette.neutral90
  }
}));
