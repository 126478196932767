import { calcRem } from "@the-game/components/utils";
import { visuallyHiddenStyle } from "@the-game/components/utils";
import styled from "@emotion/styled";
export const StyledRadioChildren = styled("div")(({ theme }) => ({
  margin: 0,
  fontFamily: theme.fontFamily
}));
export const StyledRadioLabel = styled("label")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `${calcRem(theme.spacing.lg)} auto`,
  gridGap: calcRem(theme.spacing.xs),
  margin: calcRem(theme.spacing.sm),
  color: theme.colors.palette.neutral90,
  cursor: "pointer"
}));
export const StyledRadio = styled("div")(({ theme }) => ({
  display: "inline-block",
  width: calcRem(theme.spacing.lg),
  height: calcRem(theme.spacing.lg),
  padding: 2,
  borderRadius: "50%",
  border: `2px solid`
}));
export const StyledRadioCheck = styled("div")({
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  transition: "background-color ease-out 0.1s"
});
export const StyledRadioInput = styled("input")(({ theme }) => ({
  ...visuallyHiddenStyle,
  [`&:focus-visible + div`]: {
    borderColor: theme.colors.primary
  },
  // TODO: component selector
  [`&:checked + div > div`]: {
    backgroundColor: theme.colors.primary
  },
  [`&:disabled + div`]: {
    borderColor: theme.colors.palette.neutral50,
    backgroundColor: theme.colors.palette.neutral30
  },
  [`&:disabled:checked + div > div`]: {
    backgroundColor: theme.colors.palette.neutral50
  }
}));
