import React from 'react'
import { FlagProps } from '.'

export function Carlton({ title = 'Carlton', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <path d="M0 0h48v48H0z" fill="#0C1C2D" fillRule="evenodd" />
        </svg>
    )
}

export default Carlton
