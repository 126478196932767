import { calcRem } from '../utils/calcRem'
import { Direction } from '../utils/types'
import { Spacing } from '../theme'
import styled from '@emotion/styled'
import { Box } from '../Box'
import CSS from 'csstype'

export interface StackSpecificProps {
    gap?: Spacing | 'space-between'
    direction?: Direction
    dividers?: 'subtle' | 'strong'
    alignItems?: CSS.Property.AlignItems
    justifyContent?: CSS.Property.JustifyContent
}

export const Stack = styled(Box)<StackSpecificProps>(
    ({ direction: flexDirection, justifyContent, alignItems }) => ({
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection,
        alignItems,
        justifyContent,
    }),
    // Direction styling
    ({ theme, gap, direction = 'row' }) =>
        gap &&
        gap !== 'space-between' && {
            '& > *:not(:last-child)': {
                marginBottom:
                    direction === 'column'
                        ? calcRem(theme.spacing[gap])
                        : undefined,
                marginRight:
                    direction === 'row'
                        ? calcRem(theme.spacing[gap])
                        : undefined,
            },
        },
    ({ gap }) =>
        gap &&
        gap === 'space-between' && {
            justifyContent: 'space-between',
        },
    // Divider styling
    ({ theme, dividers, direction = 'row', gap = 'unset' }) => {
        if (!dividers) {
            return undefined
        }

        const transformDistance =
            gap === 'space-between' ? '-50%' : calcRem(-theme.spacing[gap] / 2)

        return {
            '& > *': {
                position: 'relative',

                ':not(:first-child)::before': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    flexGrow: 1,
                    width: direction === 'row' ? 1 : '100%',
                    height: direction === 'column' ? 1 : '100%',
                    content: '""',
                    backgroundColor:
                        dividers === 'strong'
                            ? theme.colors.palette.neutral50
                            : theme.colors.palette.neutral30,
                    transform:
                        direction === 'row'
                            ? `translateX(${transformDistance})`
                            : `translateY(${transformDistance})`,
                },
            },
        }
    },
)

export default Stack
