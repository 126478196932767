import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Wrench({ title = 'Wrench Icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon {...{ title, ...props }} viewBox="0 0 24 24">
            <path
                fillRule="evenodd"
                d="M17,2 C17.8667802,2 18.6820463,2.22055849 19.392737,2.60861434 L15,7 L17,9 L21.3919278,4.60825613 C21.7796469,5.31871319 22,6.13362348 22,7 C22,9.76142375 19.7614237,12 17,12 C16.4024571,12 15.8293962,11.8951801 15.2982017,11.7029244 L8,19 C8,20.6568542 6.65685425,22 5,22 C3.34314575,22 2,20.6568542 2,19 C2,17.3431458 3.34314575,16 5,16 L12.2974391,8.70280243 C12.1049521,8.17132594 12,7.5979194 12,7 C12,4.23857625 14.2385763,2 17,2 Z M5,18 C4.44771525,18 4,18.4477153 4,19 C4,19.5522847 4.44771525,20 5,20 C5.55228475,20 6,19.5522847 6,19 C6,18.4477153 5.55228475,18 5,18 Z"
            />
        </SvgIcon>
    )
}

export default Wrench
