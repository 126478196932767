import React, { ReactNode } from 'react'
import {
    StyledRadio,
    StyledRadioInput,
    StyledRadioCheck,
    StyledRadioLabel,
    StyledRadioChildren,
} from './Radio.styled'

export interface RadioProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    children?: ReactNode
}

export function Radio({ children, ...props }: RadioProps): JSX.Element {
    return (
        <StyledRadioLabel>
            <StyledRadioInput type="radio" {...props} />
            <StyledRadio>
                <StyledRadioCheck />
            </StyledRadio>
            <StyledRadioChildren>{children}</StyledRadioChildren>
        </StyledRadioLabel>
    )
}

export default Radio
