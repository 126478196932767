import React from 'react'
import { format } from 'date-fns-tz'
import { MatchTeam } from './MatchTeam'
import { BettingOdds } from './BettingOdds'
import {
    StyledCard,
    MatchStatusBadge,
    StyledStatusIconWrapper,
    StyledGrid,
    StyledBadge,
    MatchStatusWrapper,
    StyledLocation,
} from './HeadToHead.styled'
import { Stack } from '../Stack'
import { Check } from '../Icons/Check'
import { Lock } from '../Icons/Lock'
import { Info } from '../Icons/Info'
import {
    HeadToHeadMargin,
    HeadToHeadMatch,
    MatchStatus,
    SportName,
    TabtouchMatchedOdds,
    TeamToWin,
} from '@the-game/api-interfaces'
import { Colors, theme } from '../theme'
import { WinIndicator } from '../WinIndicator'
import { FormGuide, ShortFormGuide } from '../FormGuide'
import { WinState } from '../ScoreSummaryCard'
import { MarginInputCard } from '../MarginInputCard/MarginInputCard'
import { MarginCard } from '../MarginCard/MarginCard'

export interface MarginProps extends HeadToHeadMargin {
    value?: number
    onChange: (margin: number) => void
    disableMarginInput?: boolean
    label: string
    tooltipContent: React.ReactNode
    marginText: string
}

export interface HeadToHeadProps {
    sport: SportName
    match: HeadToHeadMatch
    value: TeamToWin
    saved?: boolean
    odds?: TabtouchMatchedOdds
    onChangeTeamToWin: (teamToWin: TeamToWin, id: string) => void
    margin?: MarginProps
    updateTipsState?: (matchId: string) => void
    tabTouchLinkPressed: (type: 'single' | 'logo', team?: string) => void
}

function StatusIcon({
    isLocked,
    matchStatus,
    winState,
}: {
    isLocked: boolean
    matchStatus: MatchStatus
    winState: WinState
}) {
    if (matchStatus === 'completed') {
        return (
            <StyledStatusIconWrapper isCompleted={true}>
                <WinIndicator variant={winState} />
            </StyledStatusIconWrapper>
        )
    }

    if (isLocked) {
        return (
            <StyledStatusIconWrapper>
                <Lock size="sm" themeColor="red500" />
            </StyledStatusIconWrapper>
        )
    }

    return null
}

export interface StatusBadgeProps {
    savedTip?: TeamToWin
    unsavedTip?: TeamToWin
    matchStatus?: MatchStatus
}

export function StatusBadge({
    savedTip,
    unsavedTip,
    matchStatus,
}: StatusBadgeProps) {
    switch (matchStatus) {
        case 'scheduled':
        case 'pre-match':
            return savedTip &&
                savedTip !== 'NONE' &&
                (savedTip === unsavedTip || !unsavedTip) ? (
                <StyledBadge color={theme.colors.palette.green500 as Colors}>
                    <Check themeColor="green500" border={2} />
                    <span>Tip submitted</span>
                </StyledBadge>
            ) : (
                <StyledBadge color={theme.colors.palette.red500 as Colors}>
                    <Info themeColor="red500" />
                    <span>Tip not submitted</span>
                </StyledBadge>
            )
        case 'in-progress':
        case 'completed':
            return savedTip && savedTip !== 'NONE' ? (
                <StyledBadge color={theme.colors.palette.green500 as Colors}>
                    <Check themeColor="green500" border={2} />
                    <span>Tip submitted</span>
                </StyledBadge>
            ) : (
                <StyledBadge color={theme.colors.palette.neutral90 as Colors}>
                    <Info themeColor="neutral90" />
                    <span>You didn't tip</span>
                </StyledBadge>
            )
        default:
            return <span></span>
    }
}

const Margin = React.forwardRef<HTMLInputElement, MarginProps>(
    (
        {
            isLocked,
            onChange,
            savedMargin,
            value,
            winningMargin,
            disableMarginInput,
            tooltipContent,
            label,
            marginText,
        },
        ref,
    ) => {
        if (isLocked) {
            const marginScore = winningMargin
                ? Math.abs(savedMargin ?? 0 - winningMargin)
                : undefined
            return (
                <MarginCard
                    isResultIn={winningMargin !== undefined}
                    tippedMargin={savedMargin}
                    winningMargin={winningMargin}
                    marginScore={marginScore}
                    marginText={marginText}
                />
            )
        } else {
            return (
                <MarginInputCard
                    max={200}
                    min={0}
                    matchStatus={'scheduled'}
                    value={value ?? savedMargin ?? 0}
                    onChange={(value) => {
                        onChange(value)
                    }}
                    ref={ref}
                    disableInput={disableMarginInput}
                    label={label}
                    tooltipContent={tooltipContent}
                />
            )
        }
    },
)

export function matchStartTimeDiff(localStartTime: string, clientTime: number) {
    const timeDifference = Date.parse(localStartTime) - clientTime
    return timeDifference
}

export const isTeamSelected = (
    teamToTest: TeamToWin,
    cancelled: boolean,
    isLocked: boolean,
    savedTip: TeamToWin | undefined,
    unsavedTip: TeamToWin,
): boolean => {
    return cancelled
        ? false
        : isLocked
        ? savedTip === teamToTest
        : (unsavedTip ?? savedTip) === teamToTest
}
const MAX_TIMEOUT = 2147483647

function createSetTimeout(cb: () => any, timeout: number) {
    if (timeout > MAX_TIMEOUT) {
        setTimeout(() => {
            createSetTimeout(cb, Math.abs(MAX_TIMEOUT - timeout))
        }, MAX_TIMEOUT)
    } else {
        setTimeout(cb, timeout)
    }
}

export const HeadToHead = React.forwardRef<HTMLInputElement, HeadToHeadProps>(
    (
        {
            sport,
            match,
            value,
            odds,
            onChangeTeamToWin,
            margin,
            updateTipsState,
            tabTouchLinkPressed,
        },
        ref,
    ) => {
        const {
            awayTeam,
            homeTeam,
            localStartTime,
            location,
            id,
            homeScore,
            awayScore,
            winState,
            isEighteenOrOver,
            savedTip,
        } = match
        let { isLocked, matchStatus } = match

        let started = matchStatus === 'in-progress'
        const completed = matchStatus === 'completed'
        const cancelled = matchStatus === 'cancelled'
        const suspended = matchStatus === 'suspended'

        const isCheckMatchStart =
            localStartTime && !suspended && !cancelled && !completed
        const startTime = localStartTime ? new Date(localStartTime) : undefined

        if (localStartTime) {
            const matchTimeDiff = matchStartTimeDiff(localStartTime, Date.now())
            if (matchTimeDiff <= 0 && isCheckMatchStart) {
                matchStatus = 'in-progress'
                started = true
                isLocked = true
            } else {
                createSetTimeout(() => {
                    if (isCheckMatchStart) {
                        if (updateTipsState) {
                            updateTipsState(id)
                        }
                    }
                }, matchTimeDiff)
            }
        }

        const homeSelected = isTeamSelected(
            'HOME',
            cancelled,
            isLocked,
            savedTip,
            value,
        )
        const awaySelected = isTeamSelected(
            'AWAY',
            cancelled,
            isLocked,
            savedTip,
            value,
        )

        const disableMarginInput = !(homeSelected || awaySelected)

        return (
            <StyledCard
                component="li"
                padding="unset"
                disabled={isLocked || cancelled}
            >
                <StyledGrid>
                    <StatusBadge
                        savedTip={savedTip}
                        unsavedTip={value}
                        matchStatus={matchStatus}
                    />
                    <MatchStatusWrapper>
                        <MatchStatusBadge>
                            {completed
                                ? 'Game finished'
                                : cancelled
                                ? 'Game cancelled'
                                : suspended
                                ? 'Game suspended'
                                : started
                                ? 'Game in play'
                                : 'Game scheduled'}
                        </MatchStatusBadge>
                    </MatchStatusWrapper>
                    <StyledLocation direction="column" alignItems="flex-end">
                        {startTime && (
                            <time>
                                {format(startTime, 'h:mm aaa E')}{' '}
                                {format(startTime, 'd LLL')}
                            </time>
                        )}
                        {location}
                    </StyledLocation>
                </StyledGrid>
                <StatusIcon
                    isLocked={isLocked}
                    matchStatus={matchStatus}
                    winState={winState}
                />
                <Stack dividers="strong">
                    <MatchTeam
                        ref={ref}
                        matchId={id}
                        selected={homeSelected}
                        name={homeTeam.team}
                        score={homeScore}
                        showScore={started || completed}
                        onChange={(val) => onChangeTeamToWin(val, id)}
                        disabled={isLocked || cancelled}
                        winState={winState}
                        orientation="home"
                        homeTeam={true}
                    />
                    <MatchTeam
                        ref={ref}
                        matchId={id}
                        selected={awaySelected}
                        name={awayTeam.team}
                        score={awayScore}
                        showScore={started || completed}
                        onChange={(val) => onChangeTeamToWin(val, id)}
                        disabled={isLocked || cancelled}
                        winState={winState}
                        orientation="away"
                        homeTeam={false}
                    />
                </Stack>
                <Stack direction="column">
                    {margin !== undefined && (
                        <Margin
                            ref={ref}
                            {...margin}
                            isLocked={isLocked}
                            disableMarginInput={disableMarginInput}
                            label={margin.label}
                            tooltipContent={margin.tooltipContent}
                        />
                    )}
                    <FormGuide
                        sport={sport}
                        homeTeam={homeTeam}
                        awayTeam={awayTeam}
                        venue={location}
                        odds={odds}
                    />
                </Stack>
                <BettingOdds
                    isLocked={isLocked}
                    isEighteenOrOver={isEighteenOrOver}
                    matchOdds={odds}
                    tabTouchLinkPressed={tabTouchLinkPressed}
                />
                {!odds && (
                    <ShortFormGuide
                        sport={sport}
                        homeTeam={homeTeam}
                        awayTeam={awayTeam}
                        venue={location}
                    />
                )}
            </StyledCard>
        )
    },
)

export default HeadToHead
