export function getDiagInfo(userSub?: string) {
    const intlInfo = Intl.DateTimeFormat().resolvedOptions()
    let diagInfo = `\n\n\n`
    diagInfo += `Diagnostic Information - Please do not remove\n`
    diagInfo += `***************************************************************\n\n`
    diagInfo += userSub ? `Sub: ${userSub}\n` : ''
    diagInfo += `User-agent header: ${navigator.userAgent}\n`
    diagInfo += `Platform: ${navigator.platform}\n`
    diagInfo += `Vendor: ${navigator.vendor}\n\n`
    diagInfo += `Connection Downlink: ${
        (navigator as any)?.connection?.downlink
    }\n`
    diagInfo += `Connection Effective Type: ${
        (navigator as any)?.connection?.effectiveType
    }\n\n`

    diagInfo += `Cookies Enabled: ${navigator.cookieEnabled}\n`
    diagInfo += `Do not track: ${navigator.doNotTrack}\n`
    diagInfo += `Session Storage Available: ${
        window.sessionStorage !== undefined
    }\n`
    diagInfo += `Local Storage Available: ${
        window.localStorage !== undefined
    }\n\n`

    diagInfo += `Language: ${navigator.language}\n`
    diagInfo += `Locale: ${intlInfo.locale}\n`
    diagInfo += `Timezone: ${intlInfo.timeZone}\n\n`

    diagInfo += `Screen Size (W): ${window.screen.width}\n`
    diagInfo += `Screen Size (H): ${window.screen.height}\n\n`
    diagInfo += `********************** End of Diag Info ***********************\n`

    return encodeURIComponent(diagInfo)
}
export default getDiagInfo
