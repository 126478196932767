import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'
import cuid from 'cuid'

interface FootballProps extends SvgIconProps {
    gradientFill?: boolean
}

export function AflSvgGradient({ identifier }: { identifier: string }) {
    return (
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id={identifier}>
            <stop stopColor="#3E65C0" offset="0%"></stop>
            <stop stopColor="#3C6BC0" offset="5.72157434%"></stop>
            <stop stopColor="#3A72C0" offset="12.0991254%"></stop>
            <stop stopColor="#3878C0" offset="19.0233236%"></stop>
            <stop stopColor="#357FC0" offset="26.3848397%"></stop>
            <stop stopColor="#3385C0" offset="34.074344%"></stop>
            <stop stopColor="#308CC0" offset="41.9825073%"></stop>
            <stop stopColor="#2C92C0" offset="50%"></stop>
            <stop stopColor="#2998C0" offset="58.0174927%"></stop>
            <stop stopColor="#259EC0" offset="65.925656%"></stop>
            <stop stopColor="#21A3C0" offset="73.6151603%"></stop>
            <stop stopColor="#1CA8C0" offset="80.9766764%"></stop>
            <stop stopColor="#17ACC0" offset="87.9008746%"></stop>
            <stop stopColor="#11B0C0" offset="94.2784257%"></stop>
            <stop stopColor="#09B4C0" offset="100%"></stop>
        </linearGradient>
    )
}
export function Football({
    title = 'Football Icon',
    gradientFill = false,
    ...props
}: FootballProps) {
    const identifier = cuid()

    return (
        <SvgIcon {...{ title, ...props }} viewBox="3 2 62 62">
            {gradientFill && (
                <defs>
                    <AflSvgGradient identifier={identifier} />
                </defs>
            )}
            <path
                fill={gradientFill ? `url(#${identifier})` : undefined}
                d="M33,2.66666667 C43.7814368,2.66666667 55,16.9840529 55,32 C55,47.0159471 43.7814368,61.3333333 33,61.3333333 C22.2185632,61.3333333 11,47.0159471 11,32 C11,16.9840529 22.2185632,2.66666667 33,2.66666667 Z M33,8 C25.6364438,8 16.5,19.660138 16.5,32 C16.5,44.339862 25.6364438,56 33,56 C40.3635562,56 49.5,44.339862 49.5,32 C49.5,19.660138 40.3635562,8 33,8 Z M38.5,37.3333333 C40.0187831,37.3333333 41.25,38.5272407 41.25,40 C41.25,41.4727593 40.0187831,42.6666667 38.5,42.6666667 L27.5,42.6666667 C25.9812169,42.6666667 24.75,41.4727593 24.75,40 C24.75,38.5272407 25.9812169,37.3333333 27.5,37.3333333 L38.5,37.3333333 Z M38.5,29.3333333 C40.0187831,29.3333333 41.25,30.5272407 41.25,32 C41.25,33.4727593 40.0187831,34.6666667 38.5,34.6666667 L27.5,34.6666667 C25.9812169,34.6666667 24.75,33.4727593 24.75,32 C24.75,30.5272407 25.9812169,29.3333333 27.5,29.3333333 L38.5,29.3333333 Z M38.5,21.3333333 C40.0187831,21.3333333 41.25,22.5272407 41.25,24 C41.25,25.4727593 40.0187831,26.6666667 38.5,26.6666667 L27.5,26.6666667 C25.9812169,26.6666667 24.75,25.4727593 24.75,24 C24.75,22.5272407 25.9812169,21.3333333 27.5,21.3333333 L38.5,21.3333333 Z"
            ></path>
        </SvgIcon>
    )
}
export default Football
