import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'
import cuid from 'cuid'

interface CricketBallProps extends SvgIconProps {
    gradientFill?: boolean
}

export function CricketSvgGradient({ identifier }: { identifier: string }) {
    return (
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id={identifier}>
            <stop stopColor="#FF8900" offset="0%" />
            <stop stopColor="#FF8C01" offset="5.7%" />
            <stop stopColor="#FF8F02" offset="12.1%" />
            <stop stopColor="#FF9203" offset="19%" />
            <stop stopColor="#FF9603" offset="26.4%" />
            <stop stopColor="#FF9904" offset="34.1%" />
            <stop stopColor="#FF9D04" offset="42%" />
            <stop stopColor="#FFA104" offset="50%" />
            <stop stopColor="#FFA405" offset="58%" />
            <stop stopColor="#FFA705" offset="65.9%" />
            <stop stopColor="#FFAA05" offset="73.6%" />
            <stop stopColor="#FFAD05" offset="81%" />
            <stop stopColor="#FFB006" offset="87.9%" />
            <stop stopColor="#FFB006" offset="94.3%" />
            <stop stopColor="#FFB506" offset="100%" />
        </linearGradient>
    )
}

export function CricketBall({
    title = 'Cricket Ball Icon',
    gradientFill = false,
    ...props
}: CricketBallProps) {
    // Generates a id when it mounts
    const [identifier] = React.useState(() => cuid())

    return (
        <SvgIcon {...{ title, ...props }} viewBox="0 0 24 24">
            {gradientFill && (
                <defs>
                    <CricketSvgGradient identifier={identifier} />
                </defs>
            )}

            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={gradientFill ? `url(#${identifier})` : undefined}
                d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12ZM11 4.06189C11.3276 4.02104 11.6614 4 12 4C12.339 4 12.6731 4.02108 13.001 4.06201V19.938C12.6731 19.9789 12.339 20 12 20C11.6614 20 11.3276 19.979 11 19.9381V4.06189ZM4 12C4 15.3574 6.06818 18.2317 9.00003 19.4185V4.58151C6.06818 5.76827 4 8.64261 4 12ZM15.001 19.4181V4.58191C17.9323 5.76892 20 8.64299 20 12C20 15.357 17.9323 18.2311 15.001 19.4181Z"
            />
        </SvgIcon>
    )
}
export default CricketBall
