import React from 'react'
import { Mail } from '../../Icons/Mail'
import { ButtonAnchor } from '../../ButtonAnchor'
import { contactMailToRef } from '@the-game/components/utils'

export function ContactUsButton() {
    return (
        <ButtonAnchor
            size="sm"
            href={contactMailToRef()}
            variant="ghost"
            kind="primary"
        >
            <Mail aria-hidden title="" />
            Contact&nbsp;us
        </ButtonAnchor>
    )
}
