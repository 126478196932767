import React from 'react'
import {
    CompetitionTipsMatch,
    ExpertTipsMember,
} from '@the-game/api-interfaces'
import {
    PaginationInformation,
    paginationReady,
} from '../pagination/pagination'
import { Paginator } from '../Paginator/Paginator'
import { Avatar } from '../Avatar/Avatar'
import {
    StyledGrid,
    StyledWrapper,
    StyledRowHeading,
    StyledHeader,
    StyledTeam,
    StyledLine,
    StyledUsername,
    EmptyRowContent,
    StyledFullName,
    StyledUserTitle,
} from '../ExpertTips/ExpertTips.styled'
import { TipResult } from '../WhoTippedWhat/WhoTippedWhat'

interface ExpertTipsProps {
    matches: CompetitionTipsMatch[]
    pagination?: PaginationInformation<ExpertTipsMember>
    goToPage: (page: number) => void
    userColumnLabel?: string
}

export function ExpertTips({
    matches,
    pagination,
    goToPage,
    userColumnLabel = 'Member',
}: ExpertTipsProps) {
    return (
        <StyledWrapper>
            <StyledGrid columns={matches.length}>
                <StyledLine variant="faint" />
                <StyledRowHeading>{userColumnLabel}</StyledRowHeading>
                {matches.map(({ home, away, winner, id }) => (
                    <StyledHeader key={id}>
                        <StyledTeam winner={winner === 'HOME'}>
                            {home}
                        </StyledTeam>{' '}
                        <span>vs</span>
                        <StyledTeam winner={winner === 'AWAY'}>
                            {away}
                        </StyledTeam>
                    </StyledHeader>
                ))}

                {paginationReady(pagination) && (
                    <Paginator
                        {...pagination}
                        pageSize={18}
                        totalItems={1}
                        goToPage={goToPage}
                        container={React.Fragment}
                        emptyItem={() => (
                            <>
                                <StyledLine variant="faint" />
                                <EmptyRowContent />
                            </>
                        )}
                        itemFromData={(
                            { avatar, tips, givenName, familyName, title },
                            i,
                        ) => {
                            return (
                                <React.Fragment key={`row-${i}`}>
                                    <StyledLine
                                        variant={i === 0 ? 'blue' : 'faint'}
                                    />

                                    <StyledUsername isExpert={true}>
                                        <Avatar
                                            src={avatar}
                                            name={`${givenName} ${familyName}`}
                                            size="experts"
                                        />

                                        <div>
                                            <StyledFullName>
                                                {givenName} {familyName}
                                            </StyledFullName>
                                            <StyledUserTitle>
                                                {title}
                                            </StyledUserTitle>
                                        </div>
                                    </StyledUsername>

                                    {matches.map((match, i) => (
                                        <TipResult
                                            tip={tips[i]}
                                            match={match}
                                            key={`result-${i}`}
                                        />
                                    ))}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
                <StyledLine variant="strong" />
            </StyledGrid>
        </StyledWrapper>
    )
}
