import React from 'react'
import { CheckRound } from '../Icons/CheckRound'
import { CrossRound } from '../Icons/CrossRound'

import styled from '@emotion/styled'
import { WinState } from '../ScoreSummaryCard'
import VisuallyHidden from '../VisuallyHidden'

export const StyledIndicator = styled('div')<{
    variant: WinState
}>(({ theme, variant }) => {
    const backgroundColor =
        variant === 'won'
            ? theme.colors.palette.green500
            : theme.colors.palette.red500

    return {
        backgroundColor,
        borderRadius: '50%',
        justifyContent: 'start',
        display: 'flex',
        svg: {
            fill: theme.colors.white,
        },
    }
})

export function WinIndicator({ variant }: { variant: WinState }) {
    return (
        <StyledIndicator variant={variant} aria-hidden="true">
            {variant === 'won' ? (
                <>
                    <CheckRound title="" />
                    <VisuallyHidden>Correct Tip</VisuallyHidden>
                </>
            ) : (
                <>
                    <CrossRound title="" />
                    <VisuallyHidden>Incorrect Tip</VisuallyHidden>
                </>
            )}
        </StyledIndicator>
    )
}
