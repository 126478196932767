import React from 'react'
import { EditorState } from 'draft-js'
import { StyledConrols, StyledControlButton } from './RichEditor.styled'

export type BlockType =
    | 'unstyled'
    | 'header-one'
    | 'header-two'
    | 'header-three'
    | 'header-four'
    | 'header-five'
    | 'header-six'
    | 'unordered-list-item'
    | 'ordered-list-item'
    | 'align-left'
    | 'align-center'
    | 'align-right'

export type InlineType = 'BOLD' | 'ITALIC' | 'UNDERLINE'

interface ISharedControlOptions {
    key: string
    active: boolean
    label: string
}

interface IBlockControlOptions extends ISharedControlOptions {
    onToggle: (key: BlockType) => void
    style: BlockType
}

interface IInlineControlOptions extends ISharedControlOptions {
    onToggle: (key: InlineType) => void
    style: InlineType
}

function isBlockControl(
    props: IBlockControlOptions | IInlineControlOptions,
): props is IBlockControlOptions {
    return props instanceof GetBlockControls
}

function ControlOption(props: IBlockControlOptions | IInlineControlOptions) {
    function onToggle(e: React.MouseEvent) {
        e.preventDefault()
        isBlockControl(props)
            ? props.onToggle(props.style as BlockType)
            : props.onToggle(props.style as InlineType)
    }

    return (
        <StyledControlButton onMouseDown={onToggle} isActive={props.active}>
            {props.label}
        </StyledControlButton>
    )
}

export function isAlignmentBlock(blockData: BlockType | undefined) {
    if (!blockData) {
        return false
    }
    return ['align-left', 'align-center', 'align-right'].includes(blockData)
}

export const headerControls: { label: string; style: BlockType }[] = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
]
export const listControls: { label: string; style: BlockType }[] = [
    { label: 'Bullet list', style: 'unordered-list-item' },
    { label: 'Numbered list', style: 'ordered-list-item' },
]
export const alignmentControls: { label: string; style: BlockType }[] = [
    // TO-DO: Below features to be ucommented once the icons support is implemented and inline styles are handled together with alignment
    // { label: 'Align L', style: 'align-left' },
    // { label: 'Align C', style: 'align-center' },
    // { label: 'Align R', style: 'align-right' },
]

export const blockControls: {
    label: string
    style: BlockType
}[] = headerControls.concat(listControls, alignmentControls)

export function GetBlockControls({
    editorState,
    onToggle,
    controlTypes,
}: {
    editorState: EditorState
    onToggle: (key: BlockType) => void
    controlTypes:
        | typeof headerControls
        | typeof listControls
        | typeof alignmentControls
}) {
    const selection = editorState.getSelection()
    const currentBlock = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
    const blockType = currentBlock.getType()
    const textAlignData = currentBlock.getData().get('text-align')

    return (
        <StyledConrols>
            {controlTypes.map((type) => (
                <ControlOption
                    key={type.label}
                    active={
                        type.style === blockType ||
                        (type.style === textAlignData &&
                            isAlignmentBlock(textAlignData))
                    }
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                />
            ))}
        </StyledConrols>
    )
}

export const inlineStyles: { label: string; style: InlineType }[] = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
]

export function InlineControls({
    editorState,
    onToggle,
}: {
    editorState: EditorState
    onToggle: (key: InlineType) => void
}) {
    const currentStyle = editorState.getCurrentInlineStyle()
    return (
        <StyledConrols>
            {inlineStyles.map((type) => (
                <ControlOption
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                />
            ))}
        </StyledConrols>
    )
}
