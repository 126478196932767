import React from 'react'
import { Heading } from '../Heading/Heading'
import { TheWestAustralianSvg } from '../NewsCard/TheWestAustralianSvg'
import styled from '@emotion/styled'
import { breakpoint } from '../utils/breakpoint'
import { calcRem } from '../utils/calcRem'
import VisuallyHidden from '../VisuallyHidden'

const StyledNewsWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateRows: `auto auto`,
    gridGap: theme.spacing.md,
}))

const StyledNewsCardGrid = styled('div')(({ theme }) => ({
    display: 'grid',
    gridGap: calcRem(theme.spacing.md),
    gridTempateRows: `repeat(${calcRem(170)}, 3)`,
    gridTemplateColumns: '1fr 1fr',
    '> :first-child': {
        gridColumn: '1 / -1',
        gridRow: '1 / 2',
    },
    [breakpoint('sm')]: {
        gridGap: calcRem(theme.spacing.xl),
        gridTemplateColumns: 'repeat(8, 1fr)',
        '> :first-child': {
            gridColumnEnd: 'span 5',
            gridRowEnd: 'span 2',
        },
        '> :nth-child(2), > :nth-child(3)': {
            gridColumnEnd: 'span 3',
        },

        '> :nth-child(4), > :nth-child(5)': {
            gridColumnEnd: 'span 4',
        },
    },

    [breakpoint('md')]: {
        gridGap: calcRem(theme.spacing.xl),
        gridTempateRows: `${calcRem(170)} ${calcRem(170)}`,
        gridTemplateColumns: '2fr 1fr 1fr',
        '> :nth-child(n+1)': {
            gridRowEnd: 'span 1',
            gridColumnEnd: 'span 1',
        },
        '> :first-child': {
            gridRowEnd: 'span 2',
        },
    },
}))

export function NewsCardGrid({ children }: { children: React.ReactNode }) {
    return (
        <StyledNewsWrapper>
            <div>
                <Heading level="6">
                    Top Stories from{' '}
                    <VisuallyHidden>The West Australian</VisuallyHidden>
                </Heading>
                <TheWestAustralianSvg aria-hidden />
            </div>
            <StyledNewsCardGrid>{children}</StyledNewsCardGrid>
        </StyledNewsWrapper>
    )
}
export default NewsCardGrid
