import React from 'react'
import {
    Badge,
    BadgeList,
    BadgeSize,
    StyledSpan,
} from './MarginHistoryBadges.styled'
import { VisuallyHidden } from '../VisuallyHidden'

export interface MarginHistoryBadgesProps {
    marginHistory: Array<number>
    showLatestText?: boolean
    size?: BadgeSize
}

export function MarginHistoryBadges({
    marginHistory,
    showLatestText = false,
    size = 'md',
}: MarginHistoryBadgesProps) {
    const last5 = marginHistory.slice(0, 5)
    return (
        <>
            <VisuallyHidden>
                {`Sixes History: ${last5.join(', ')}`}
            </VisuallyHidden>
            <BadgeList
                data-testid="marginHistory"
                size={size}
                aria-hidden="true"
            >
                {new Array(5 - last5.length).fill(0).map((_, i) => (
                    <Badge key={`placeholder-${i}`}></Badge>
                ))}
                {last5.map((margin, key) => (
                    <Badge key={key}>{margin}</Badge>
                ))}
                {showLatestText && <StyledSpan>Latest</StyledSpan>}
            </BadgeList>
        </>
    )
}
