import React from 'react'
import styled from '@emotion/styled'
import { ChevronDown } from '../../Icons/ChevronDown'
import { Link } from 'react-router-dom'
import { calcRem } from '@the-game/components/utils'
import { AnimationState, MenuSection } from '../types'
import { insetFocusStyle } from '@the-game/components/utils'
import { StyledPill } from '@the-game/components/Pill/Pill.styled'
import { StyledInactiveMenuItem } from '../GlobalHeader'
import { showOffseasonMenuLabel } from '@the-game/components/utils/showOffseasonMenuLabel'

export const menuHeaderHeight = calcRem(208)

const StyledMenuSection = styled('ul')<{ show: boolean; nItems: number }>(
    ({ theme, show, nItems }) => ({
        height: show ? `${(nItems + 1) * 4.5}rem` : `4.5rem`,
        borderBottom: `${theme.colors.palette.neutral30} 1px solid`,
        overflow: 'hidden',
        transition: 'height 0.1s ease-in-out',

        li: {
            fontWeight: theme.weights.bold,
            borderBottom: `${theme.colors.palette.neutral30} 1px solid`,
            'button, a': {
                padding: 0,
                color: theme.colors.black,
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                backgroundColor: theme.colors.white,
                transition: 'background-color 0.1s ease-in-out',
                listStyle: 'none',
                border: 'none',
                '&:hover': {
                    backgroundColor: theme.colors.palette.neutral30,
                },
                ...insetFocusStyle(theme),
            },
        },
        ul: {
            padding: 0,
            li: {
                fontWeight: theme.weights.regular,
                marginLeft: '4rem',
                display: 'grid',
                alignItems: 'center',
                height: '4.5rem',
                a: {
                    boxSizing: 'content-box',
                    marginLeft: '-4rem',
                    paddingLeft: '4rem',
                },
            },
        },
    }),
)

const StyledSectionContent = styled('div')(({ theme }) => ({
    padding: theme.spacing.md,
    gridGap: theme.spacing.md,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '4.5rem',
    textAlign: 'left',
    cursor: 'pointer',
}))

const StyledMenuLabel = styled('span')({
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
})

const MenuPill = styled(StyledPill)(({ theme }) => ({
    borderColor: theme.colors.palette.orange500,
    color: theme.colors.palette.orange500,
    background: 'transparent',
    display: 'inline',
}))

interface MobileMenuSectionProps extends MenuSection {
    setAnimation: (state: AnimationState) => void
}

function MobileMenuSection({
    title,
    icon,
    children,
    link,
    setAnimation,
}: MobileMenuSectionProps) {
    const [show, setShow] = React.useState(false)

    function SectionContent() {
        const offSeason = !link && !children?.length
        return (
            <StyledSectionContent>
                <StyledMenuLabel>
                    {icon({
                        size: 'sm',
                        themeColor: 'bayBlue',
                        'aria-hidden': true,
                        title: '',
                    })}
                    {title}
                </StyledMenuLabel>
                {offSeason && <MenuPill>Off Season</MenuPill>}
                {!link && !offSeason ? (
                    <ChevronDown
                        title={show ? 'Collapse' : 'Expand'}
                        rotate={show ? 180 : 0}
                        size={'xs'}
                    />
                ) : null}
            </StyledSectionContent>
        )
    }

    return (
        <StyledMenuSection show={show} nItems={children.length}>
            {link ? (
                <li>
                    <Link to={link} onClick={() => setAnimation('exiting')}>
                        <SectionContent />
                    </Link>
                </li>
            ) : (
                <li>
                    <button onClick={() => setShow(!show)}>
                        <SectionContent />
                    </button>
                </li>
            )}
            {children && children.length > 0 && (
                <li>
                    <ul>
                        {children.map(({ title, link, isInactive }, i) => (
                            <li key={i}>
                                {isInactive ? (
                                    <StyledInactiveMenuItem>
                                        {title}
                                    </StyledInactiveMenuItem>
                                ) : (
                                    <Link
                                        to={link}
                                        onFocus={() => setShow(true)}
                                        onClick={() => setAnimation('exiting')}
                                    >
                                        {title}
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </li>
            )}
        </StyledMenuSection>
    )
}

const StyledMobileNav = styled('nav')({
    display: 'block',
    maxHeight: `calc(100vh - ${menuHeaderHeight})`,
    overflow: 'auto',
})

export function MobileMenu({
    items,
    setAnimation,
}: {
    items: MenuSection[]
    setAnimation: (state: AnimationState) => void
}) {
    return (
        <StyledMobileNav>
            {items.map((props, i) => {
                return (
                    <MobileMenuSection
                        {...props}
                        key={i}
                        setAnimation={setAnimation}
                    />
                )
            })}
        </StyledMobileNav>
    )
}
