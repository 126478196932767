import * as z from 'zod'
import { MatchShape } from './match'
import { SportShape } from './sport'

const MatchUpdatedMatchShape = MatchShape.extend({
    round: z.number().optional(),
})

export const MatchScheduleUpdatedLifecycleEventShape = z.object({
    type: z.literal('match-schedule-updated-lifecycle-event'),
    sport: SportShape,
    season: z.string(),
    match: MatchUpdatedMatchShape,
})

export const FixtureCreateLifecycleEventShape = z.object({
    type: z.literal('fixture-create'),
    sport: SportShape,
    season: z.string(),
})

export type MatchDetailsUpdatedLifecycleEvent = z.TypeOf<
    typeof MatchScheduleUpdatedLifecycleEventShape
>

export type FixtureCreateLifecycleEvent = z.TypeOf<
    typeof FixtureCreateLifecycleEventShape
>
