import React, { ReactHTML } from 'react'
import { Box } from '../Box'
import { ElevationSizes } from '@the-game/components/utils'
import { Colors, Spacing } from '../theme'
import styled from '@emotion/styled'
import { CardHeader } from './CardHeader'

export interface CardProps {
    children: React.ReactNode
    padding?: Spacing
    spacing?: Spacing
    elevation?: ElevationSizes
    backgroundColor?: Colors
    className?: string
    hideOverflow?: boolean
    headerVariant?: 'afl'
    headerTitle?: string
    component?: keyof ReactHTML
}

export const StyledCard = styled(Box)<CardProps>(({ theme, elevation }) => ({
    border:
        elevation === 0
            ? `1px solid ${theme.colors.palette.neutral50}`
            : undefined,
    // Default for all cards
    borderRadius: 16,
    '@media (max-width: 400px)': {
        borderRadius: 8, // Make smaller when mobile
    },
}))

export function Card({
    children,
    elevation = 1,
    component,
    padding = 'md',
    headerVariant,
    headerTitle,
    backgroundColor = 'white',
    ...rest
}: CardProps) {
    return (
        <StyledCard
            component={component}
            padding={padding}
            elevation={elevation}
            backgroundColor={backgroundColor}
            {...rest}
        >
            {headerTitle ? (
                <CardHeader padding={padding}>{headerTitle}</CardHeader>
            ) : null}
            {children}
        </StyledCard>
    )
}

export default Card
