import React from 'react'
import { Label } from '../Label/Label'
import { Input, InputProps } from '../Input/Input'
import FormControl from '../FormControl/FormControl'
import FormHelp from '../FormHelp/FormHelp'

export interface TextFieldProps extends InputProps {
    required?: boolean
    label: string | JSX.Element
    helperText?: string | undefined
    error?: boolean | undefined
    fluid?: boolean
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            id,
            required,
            label,
            helperText,
            error = false,
            fluid = true,
            ...props
        },
        ref,
    ) => (
        <FormControl>
            <Label htmlFor={id} required={required}>
                {label}
            </Label>
            <Input
                ref={ref}
                {...{
                    id,
                    variant: error ? 'error' : props.variant,
                    required,
                    fluid,
                    ...props,
                }}
            />
            <FormHelp variant={error ? 'error' : props.variant}>
                {helperText}
            </FormHelp>
        </FormControl>
    ),
)
