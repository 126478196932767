import { elevations, ElevationSizes } from './elevations'

export function getElevationBySize(size: ElevationSizes) {
    switch (size) {
        case 0:
            return undefined
        case 1:
            return elevations.Elevation1
        case 2:
            return elevations.Elevation2
        case 3:
            return elevations.Elevation3
    }
}

export default getElevationBySize
