import { calcRem } from '@the-game/components/utils'
import { breakpoint } from '@the-game/components/utils'
import styled from '@emotion/styled'

export interface ContentProps {
    layout?: 'page' | 'full' | 'narrow' | 'column' | 'splash'
}

export const Content = styled('div')<ContentProps>(
    ({ theme }) => ({
        gridColumn: '1 / -1',
        maxWidth: '100vw',
        minHeight: 'calc(100vh - 4rem)', // fit one screen with the header
        padding: calcRem(0, theme.spacing.md, theme.spacing.md),
    }),
    ({ layout, theme }) =>
        layout === 'splash' && {
            width: '100%',
            display: 'grid',
            textAlign: 'center',
            gridGap: theme.spacing.md,
            marginTop: '20vh',
            gridTemplateRows: '30vh 1rem',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    ({ layout, theme }) =>
        layout === 'full' && {
            margin: 'auto',
            [theme.breakpoints.md]: {
                maxWidth: calcRem(970 + theme.spacing.md * 2),
            },
        },
    ({ layout }) =>
        layout === 'page' && {
            [breakpoint('md')]: {
                gridColumn: '3 / -3',
            },
        },
    ({ layout }) =>
        layout === 'narrow' && {
            [breakpoint('sm')]: {
                gridColumn: '2 / -2',
            },

            [breakpoint('md')]: {
                gridColumn: '5 / -5',
            },
        },
    ({ layout: variant }) =>
        variant === 'column' && {
            [breakpoint('sm')]: {
                gridColumn: '2 / -2',
            },

            [breakpoint('md')]: {
                gridColumn: '6 / -6',
            },
        },
)
