import React from 'react'
import { Skeleton } from '../Skeleton'
import { calcRem } from '@the-game/components/utils'
import { StyledBox } from '../HeadToHead/HeadToHead.styled'

export function SkeletonHeadToHead() {
    return (
        <StyledBox>
            <Skeleton height={calcRem(375)} width="100%" borderRadius={16} />
        </StyledBox>
    )
}
