/**
 * @description Modifies Luminance property of a hsl value
 * @property {string} initial HSL value
 * @property {number}  new luminance value
 * @returns {string} as hsl(initial Hue, initual Saturation, new Luminance value)
 */
export function modifyLuminance(hsl: string, luiminanceValue: number): string {
    const pattern = new RegExp(/hsl\((\d+),\s?(\d+%?),\s?(\.?\d+%?)\)/g)

    const parts = pattern.exec(hsl)

    if (!parts) {
        return hsl
    }

    return `hsl(${parts[1]}, ${parts[2]}, ${luiminanceValue}%)`
}
