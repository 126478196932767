import Resizer from 'react-image-file-resizer'

export async function getBlobfromURL(url: string): Promise<Blob | undefined> {
    const xhr = new XMLHttpRequest()

    return new Promise((resolve, reject) => {
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.onload = () => {
            resolve(xhr.response)
        }
        xhr.send()
        xhr.onerror = function () {
            reject()
        }
    })
}

export async function getBase64(
    file: File | Blob,
): Promise<string | undefined> {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
        reader.readAsDataURL(file)
        reader.onload = () => {
            resolve(reader.result as string)
        }

        reader.onerror = function () {
            reject()
        }
    })
}

interface ResizeImageOptions {
    maxWidth?: number
    maxHeight?: number
    quality?: number
    rotation?: number
}

export async function resizeImage(
    originalFile: File,
    {
        maxWidth = 300,
        maxHeight = 300,
        quality = 100,
        rotation = 0,
    }: ResizeImageOptions,
): Promise<File> {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            originalFile,
            maxWidth,
            maxHeight,
            'JPEG',
            quality,
            rotation,
            (value) => {
                resolve(
                    new File([value as Blob], 'file.png', {
                        type: 'image/png',
                    }),
                )
            },
            'blob',
        )
    })
}
