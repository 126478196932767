import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Button from '../Button/Button'
import { Spinner } from '../Spinner/Spinner'
import AvatarIcon from '../Icons/Avatar'
import {
    StyledAvatarSelector,
    StyledImagePreview,
    StyledIconSlot,
    StyledRemoveButtonWrapper,
    StyledCross,
    StyledAvatarSelectorContainer,
    StyledAvatarButtonContainer,
} from './AvatarSelector.styled'
import { QueryStatus } from 'react-query'
import { FormHelp } from '../FormHelp/FormHelp'
import { Label } from '../Label/Label'
import { Cross } from '../Icons/Cross'
import { FormControl } from '../FormControl/FormControl'
import { Avatar } from '../Avatar/Avatar'
import { getBase64, getBlobfromURL, resizeImage } from './helpers'
import { Team } from '@the-game/api-interfaces'
import { TeamFlagSelector } from './TeamFlagSelector'
import theme from '../theme'

export interface AvatarSelectorProps {
    imageUploaderId?: string
    imageUploaderName?: string
    imageUploaderErrorText?: string
    helperText?: string
    label?: string
    teamFlag?: Team
    onTeamFlagChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void
    initialImage?: string
    onRemove: () => void
    onImageChange: (file?: File) => void
}

export interface StyledImagePreviewProps
    extends React.HTMLAttributes<HTMLDivElement> {
    status?: QueryStatus
    image?: string
}

const messages = {
    default: 'Upload image',
    change: 'Change image',
}

export function AvatarSelector({
    imageUploaderId,
    imageUploaderName,
    label,
    helperText,
    imageUploaderErrorText,
    onImageChange,
    onRemove,
    teamFlag,
    onTeamFlagChange,
    initialImage,
}: AvatarSelectorProps): JSX.Element {
    const fileInput = useRef<HTMLInputElement>(null)
    const [image, setImage] = useState<string | undefined>()
    const [loadingBase64, setLoadingBase64] = useState(false)

    // Only on initial render
    useEffect(() => {
        const getImg = async () => {
            if (initialImage) {
                const img = await getBlobfromURL(initialImage)
                if (img) {
                    setLoadingBase64(true)
                    const b64Image = await getBase64(img)
                    setImage(b64Image)
                    setLoadingBase64(false)
                }
            }
        }
        getImg()
    }, [initialImage])

    const handleClick = () => {
        fileInput.current?.click()
    }

    const onImageSelect = async (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.currentTarget

        if (target && target.files && target.files.length > 0) {
            setLoadingBase64(true)
            const file = await resizeImage(target.files[0], {})
            const base64 = await getBase64(file)

            onImageChange(file)
            setImage(base64)
            setLoadingBase64(false)
        } else {
            // they clicked cancel, so don't change anything
        }
    }

    return (
        <FormControl>
            <Label htmlFor={imageUploaderId}>{label}</Label>
            <StyledAvatarSelectorContainer>
                <StyledAvatarSelector>
                    {(image || teamFlag) && (
                        <StyledRemoveButtonWrapper
                            type="button"
                            onClick={() => {
                                setImage(undefined)
                                onRemove && onRemove()
                                setLoadingBase64(false)

                                // also clear the value of the field. file inputs can't be controlled by React
                                // this allows re-selecting the same image if you accidentally cleared it
                                if (fileInput.current) {
                                    fileInput.current.value = ''
                                }
                            }}
                            aria-label="Remove Selected Image"
                        >
                            <StyledCross>
                                <Cross themeColor="artfulRed" />
                            </StyledCross>
                        </StyledRemoveButtonWrapper>
                    )}
                    <StyledIconSlot
                        onClick={handleClick}
                        type="button"
                        aria-label="Image preview"
                    >
                        {image ? (
                            loadingBase64 ? (
                                <div>
                                    <Spinner />
                                </div>
                            ) : (
                                <StyledImagePreview image={image} />
                            )
                        ) : teamFlag ? (
                            <Avatar size="xxl" teamFlag={teamFlag} />
                        ) : (
                            <AvatarIcon themeColor="neutral50" size="xl" />
                        )}
                    </StyledIconSlot>
                    <input
                        id={imageUploaderId}
                        name={imageUploaderName}
                        ref={fileInput}
                        onChange={onImageSelect}
                        type="file"
                        accept=".png,.jpg,.jpeg,.gif"
                        style={{ display: 'none' }}
                    />
                </StyledAvatarSelector>

                <StyledAvatarButtonContainer>
                    <Button
                        size="md"
                        fluid
                        variant="ghost"
                        onClick={handleClick}
                        style={{ marginBottom: theme.spacing.sm }}
                    >
                        {image ? messages.change : messages.default}
                    </Button>
                    <TeamFlagSelector
                        handleValueChange={(event) => {
                            setImage(undefined)
                            onImageChange(undefined)
                            onTeamFlagChange(event)
                        }}
                        teamFlag={teamFlag}
                    />
                </StyledAvatarButtonContainer>

                {imageUploaderErrorText && (
                    <FormHelp variant={'error'}>
                        {imageUploaderErrorText}
                    </FormHelp>
                )}
                {helperText && (
                    <FormHelp variant={'normal'}>{helperText}</FormHelp>
                )}
            </StyledAvatarSelectorContainer>
        </FormControl>
    )
}

export default AvatarSelector
