import { Logger } from 'typescript-log'
import { ZodErrorCode } from 'zod'

export interface BaseApiError extends Error {
    errors: string[]
    type: string
}

// The API should throw this when the user has made a mistake
export interface UserError extends BaseApiError {
    type: 'UserError'
}

// The API should throw this when the user should not be exposed to the details
// of what went wrong
export interface ServerError {
    type: 'ServerError'
}

// Throwing a user does not log the stack trace
export const ThrowUserError = (errors: string[]) => {
    // We want to throw a literal here to avoid exposing the stacktrace to the outside world
    /* eslint-disable-next-line no-throw-literal */
    throw {
        type: 'UserError',
        errors,
    }
}

// Throwing a ServerError logs the stack trace
export const ThrowServerError = (errors: string[], log: Logger) => {
    const errorObject = new Error(`Server error: ${errors.join(' ')}`)
    if (errorObject.stack) {
        log.error(errorObject.stack)
    }
    // We want to throw a literal here to avoid exposing the stacktrace to the outside world
    /* eslint-disable-next-line no-throw-literal */
    throw {
        type: 'ServerError',
        errors,
    }
}

export const maximumImageSizeBytes = 0.5 * 1024 * 1024 // 0.5 MB
export const fileSizeErrorOptions = [
    {
        message: `The file is too large, maximum allowed size is ${
            maximumImageSizeBytes / 1024 / 1024
        } MB.`,
        code: ZodErrorCode.custom_error,
        path: ['avatar'],
    },
]

export type ApiError = UserError | ServerError
