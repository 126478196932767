import { CSSObject } from '@emotion/react'

export const Elevation1: CSSObject = {
    boxShadow: `0 1px 0 0 rgba(0,0,0,0.08), 0 12px 30px -11px rgba(0,0,0,0.05), 0 10px 14px -10px rgba(0,0,0,0.10), 0 8px 12px -8px rgba(0,0,0,0.30)`,
}
export const Elevation2: CSSObject = {
    boxShadow: `0 1px 0 0 rgba(0,0,0,0.08), 0 15px 21px -20px rgba(0,0,0,0.23), 0 12px 25px -10px rgba(0,0,0,0.12), 0 16px 20px -4px rgba(0,0,0,0.13)`,
}
export const Elevation3: CSSObject = {
    boxShadow: `0 1px 0 0 rgba(0,0,0,0.08), 0 29px 50px -30px rgba(0,0,0,0.34), 0 20px 50px -12px rgba(0,0,0,0.17), 0 10px 24px 0 rgba(0,0,0,0.05)`,
}

export const elevations = {
    Elevation1,
    Elevation2,
    Elevation3,
}

export type ElevationSizes = 0 | 1 | 2 | 3

export default elevations
