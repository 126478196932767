import { StyledComponent } from '@emotion/styled'
import { cx } from '@emotion/css'
import React from 'react'

type WrappedComponentBase =
    | StyledComponent<any, any>
    | React.ComponentType<any>
    | React.Component<any>
    | React.ForwardRefExoticComponent<any>

type GetComponentProps<T> = T extends
    | StyledComponent<infer P, infer I>
    | React.ComponentType<infer P>
    | React.Component<infer P>
    ? P & I
    : never

/**
 * Use `withClass` to add a class to resulting styled component
 */

export function withClass(customClassName?: string) {
    return <C extends WrappedComponentBase>(Component: C) => {
        type Props = GetComponentProps<C>
        type WithClassNames = { className?: string }

        const ComponentWithCustomClass = React.forwardRef<
            any,
            Omit<Props, 'theme'>
        >((props, ref) => {
            const newProps = {
                ...props,
                className: cx(props.className, customClassName),
                ref,
            }
            const ToRender = Component as React.FC<
                Omit<Props, 'theme'> & WithClassNames
            >
            return <ToRender {...newProps} />
        })

        ComponentWithCustomClass.displayName = 'ComponentWithCustomClass'
        return ComponentWithCustomClass
    }
}
