import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Mail({ title = 'Mail Icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon {...{ title, ...props }} viewBox="0 0 24 24">
            <path
                fillRule="evenodd"
                d="M3 5.8c0-.5.5-1 1-1h16c.6 0 1 .5 1 1v11.4a2 2 0 01-2 2H5a2 2 0 01-2-2v-11-.4zm2 2.3v9h14v-9l-4.9 4.8a3 3 0 01-4.2 0L5 8.1zm1.6-1.3h10.8l-4.7 4.7a1 1 0 01-1.4 0L6.6 6.8z"
            />
        </SvgIcon>
    )
}

export default Mail
