import { getVariantColor, calcRem, fontScale } from "@the-game/components/utils";
import styled from "@emotion/styled";
export const StyledFormHelp = styled("p")(
  ({ theme, variant }) => ({
    marginTop: calcRem(theme.spacing.xs),
    color: getVariantColor(variant, theme),
    fontSize: calcRem(fontScale(1)),
    lineHeight: calcRem(fontScale(1.125)),
    minHeight: calcRem(fontScale(1.125))
  })
);
