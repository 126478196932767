import React from 'react'
import { calcRem } from '@the-game/components/utils'
import { Skeleton } from '../Skeleton'
import {
    StyledGrid,
    StyledWrapper,
    StyledRowHeading,
    StyledUsername,
    StyledHeader,
    StyledLine,
} from '../WhoTippedWhat/WhoTippedWhat.styled'

function predictablyRandom(i: number) {
    const a = Math.pow(i * 3, 2) % 90
    const b = ((i + 13) * 5) % 40
    return calcRem(a + b + 30)
}

export function WhoTippedWhatSkeletonRow({
    index,
    columns,
}: {
    index: number
    columns: number
}) {
    return (
        <>
            <StyledLine variant={index === 0 ? 'blue' : 'faint'} />
            <StyledUsername>
                <Skeleton
                    offset={index * 0.2}
                    variant="circle"
                    width="2rem"
                    height="2rem"
                />
                <Skeleton height="1rem" width={predictablyRandom(index)} />
            </StyledUsername>
            {new Array(columns).fill(0).map((_, i) => (
                <Skeleton
                    key={i}
                    offset={index * 0.2}
                    variant="circle"
                    width={calcRem(40)}
                    height={calcRem(40)}
                />
            ))}
        </>
    )
}

interface SkeletonProps {
    rows?: number
    columns?: number
}
export function WhoTippedWhatSkeleton({
    rows = 7,
    columns = 9,
}: SkeletonProps) {
    return (
        <StyledWrapper>
            <StyledGrid columns={columns}>
                <StyledLine variant="faint" />

                <StyledRowHeading>Member</StyledRowHeading>
                {new Array(columns).fill(0).map((_, i) => (
                    <StyledHeader key={i}>
                        <Skeleton
                            width="100%"
                            height={calcRem(10)}
                            offset={0}
                        />
                        <Skeleton
                            width="1rem"
                            height={calcRem(10)}
                            offset={0}
                        />
                        <Skeleton
                            width="100%"
                            height={calcRem(10)}
                            offset={0}
                        />
                    </StyledHeader>
                ))}

                {new Array(rows).fill(0).map((_, i) => (
                    <WhoTippedWhatSkeletonRow
                        index={i}
                        key={i}
                        columns={columns}
                    />
                ))}

                <StyledLine variant="strong" />
            </StyledGrid>
        </StyledWrapper>
    )
}
