import React from 'react'
import { Badge, BadgeKinds } from '../Badge/Badge'
import { BadgeList, BadgeSize, StyledSpan } from './MatchHistoryBadges.styled'
import { VisuallyHidden } from '../VisuallyHidden'
import { WinHistory } from '@the-game/api-interfaces'

export const getVariant = (letter: WinHistory): BadgeKinds => {
    switch (letter) {
        case 'W':
            return 'win'
        case 'L':
            return 'loss'
        case 'D':
            return 'default'
    }
}

export interface MatchHistoryBadgesProps {
    winHistory: Array<WinHistory>
    showLatestText?: boolean
    size?: BadgeSize
}

export function MatchHistoryBadges({
    winHistory,
    showLatestText = false,
    size = 'md',
}: MatchHistoryBadgesProps) {
    const last5 = winHistory.slice(0, 5)
    return (
        <>
            <VisuallyHidden>
                {`Win History: ${last5.map(getVariant).join(', ')}`}
            </VisuallyHidden>
            <BadgeList data-testid="winHistory" size={size} aria-hidden="true">
                {new Array(5 - last5.length).fill(0).map((_, i) => (
                    <Badge key={`placeholder-${i}`}></Badge>
                ))}
                {last5.map((variant, key) => (
                    <Badge kind={getVariant(variant)} key={key}>
                        {variant}
                    </Badge>
                ))}
                {showLatestText && <StyledSpan>Latest</StyledSpan>}
            </BadgeList>
        </>
    )
}
