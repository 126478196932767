import React from 'react'
import { FlagProps } from '.'

export function EastFremantleSharks({
    title = 'EastFremantleSharks',
    ...props
}: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g>
                <rect width="48" height="48" fill="#FFC526" />
                <rect y="19" width="48" height="10" fill="#00529B" />
                <rect y="10" width="48" height="9" fill="white" />
                <rect y="29" width="48" height="9" fill="white" />
                <rect y="38" width="48" height="10" fill="#00529B" />
                <rect width="48" height="10" fill="#00529B" />
            </g>
        </svg>
    )
}

export default EastFremantleSharks
