import React from 'react'
import { FlagProps } from '.'

export function Richmond({ title = 'Richmond', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h48v48H0z" fill="#000" />
                <path
                    d="M-12.909 36.072l65.818-38 8 13.856-65.818 38z"
                    fill="#FCCE01"
                />
            </g>
        </svg>
    )
}

export default Richmond
