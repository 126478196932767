import React from 'react'
import { Label } from '../Label/Label'
import FormControl from '../FormControl/FormControl'
import FormHelp from '../FormHelp/FormHelp'
import { Select, SelectProps } from '../Select/Select'

export interface SelectFieldProps extends SelectProps {
    required?: boolean
    label: string | JSX.Element
    helperText?: string | undefined
    error?: boolean | undefined
    fluid?: boolean
}

export const SelectField = React.forwardRef<
    HTMLSelectElement,
    React.PropsWithChildren<SelectFieldProps>
>(
    (
        {
            required,
            label,
            id,
            helperText,
            fluid = true,
            error = false,
            children,
            ...props
        },
        ref,
    ) => (
        <FormControl>
            <Label htmlFor={id} required={required}>
                {label}
            </Label>
            <Select
                {...{
                    id,
                    variant: error ? 'error' : props.variant,
                    required,
                    fluid,
                    ...props,
                }}
                ref={ref}
            >
                {children}
            </Select>
            <FormHelp variant={error ? 'error' : props.variant}>
                {helperText}
            </FormHelp>
        </FormControl>
    ),
)

export default SelectField
