import React from 'react'
import { Label } from '../Label/Label'
import { InputVariants } from '@the-game/components/utils'
import FormControl from '../FormControl/FormControl'
import FormHelp from '../FormHelp/FormHelp'
import { StyledTextArea } from './TextArea.styled'

export interface TextAreaProps
    extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    required?: boolean
    label: string | JSX.Element
    helperText?: string | undefined
    error?: boolean | undefined
    variant?: InputVariants
    fluid?: boolean
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            id,
            required,
            label,
            helperText,
            variant,
            error = false,
            fluid = true,
            ...props
        },
        ref,
    ) => (
        <FormControl>
            <Label htmlFor={id} required={required}>
                {label}
            </Label>
            <StyledTextArea
                ref={ref}
                label={label}
                variant={variant}
                required={required}
                fluid={fluid}
                aria-required={required ? true : undefined}
                {...props}
            />
            <FormHelp variant={error ? 'error' : variant}>
                {helperText}
            </FormHelp>
        </FormControl>
    ),
)
