import { GameTheme } from '../theme'
import styled from '@emotion/styled'
import { breakpoint } from '@the-game/components/utils'
import CSS from 'csstype'
import { MOBILE_STICKY_HEIGHT } from '../utils/constants'

export function stickyStyles(theme: GameTheme): CSS.Properties {
    return {
        width: '100vw',
        backgroundColor: theme.colors.white,
        zIndex: theme.zIndex.modal - 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
    }
}

export const MobileSticky = styled('div')(({ theme }) => ({
    ...stickyStyles(theme),
    height: MOBILE_STICKY_HEIGHT,
    [breakpoint('sm')]: {
        display: 'none',
    },
}))

export default MobileSticky
