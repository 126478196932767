import {
  breakpointMax,
  fontRemScale,
  calcRem
} from "@the-game/components/utils";
import styled from "@emotion/styled";
export const StyledRankSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [breakpointMax("sm")]: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  }
});
export const StyledScoreSpan = styled("span")(({ theme }) => ({
  fontSize: fontRemScale(2),
  fontWeight: theme.weights.bold,
  [breakpointMax("xs")]: {
    fontSize: calcRem(30)
  }
}));
export const StyledRank = styled("span", {
  shouldForwardProp: (prop) => prop !== "positive"
})(({ theme, positive }) => ({
  color: positive ? theme.colors.palette.green500 : theme.colors.loss
}));
export const StyledNoRankText = styled("span")({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
  alignItems: "center"
});
export const StyledRankInfo = styled("span", {
  shouldForwardProp: (prop) => prop !== "stackIndicator"
})(({ stackIndicator }) => ({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
  alignItems: "center",
  [breakpointMax("sm")]: {
    marginRight: calcRem(10)
  },
  [breakpointMax("xs")]: {
    flexDirection: stackIndicator ? "column" : "row"
  }
}));
export const StyledRankIndicator = styled("span")({
  display: "flex",
  alignItems: "center"
});
