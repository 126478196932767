import React from 'react'
import { FlagProps } from '.'

export function Fremantle({ title = 'Fremantle', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h48v48H0z" fill="#2E1946" />
                <g fill="#FFF">
                    <path d="M-7.82-17.5l4.243-4.243 31.82 31.82L24 14.32z" />
                    <path d="M19.757 10.077l31.82-31.82L55.82-17.5 24 14.32zM-7.82-4.5l4.243-4.243 31.82 31.82L24 27.32z" />
                    <path d="M19.757 23.077l31.82-31.82L55.82-4.5 24 27.32zM-7.82 8.5l4.243-4.243 31.82 31.82L24 40.32z" />
                    <path d="M19.757 36.077l31.82-31.82L55.82 8.5 24 40.32z" />
                </g>
            </g>
        </svg>
    )
}

export default Fremantle
