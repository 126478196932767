import { breakpoint, breakpointMax } from "@the-game/components/utils";
import styled from "@emotion/styled";
import { Typography } from "../../Typography";
import { Heading } from "../../Heading/Heading";
import { calcRem } from "@the-game/components/utils";
export const SyledScoreContainer = styled("div")(
  ({ theme }) => ({
    display: "grid",
    borderRadius: 8,
    gridTemplateRows: "auto 1fr 1fr",
    gridTemplateColumns: "1fr 1fr",
    backgroundColor: theme.colors.palette.neutral30,
    gap: calcRem(theme.spacing.sm),
    padding: calcRem(theme.spacing.sm),
    width: "100%",
    zIndex: 1,
    [breakpoint("sm")]: {
      gridRow: "1 / 2",
      gridColumnStart: "3",
      zIndex: 1,
      gridTemplateRows: "auto 1fr",
      gridTemplateColumns: "1fr 1fr 1fr 1fr"
    }
  })
);
export const StyledScoreValueContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  [breakpoint("sm")]: {
    flexDirection: "row",
    justifyContent: "space-around"
  }
});
export const StyledScoreValueContainerPick = styled("div")(({ theme }) => ({
  display: "grid",
  gridColumn: "1 / -1",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: calcRem(theme.spacing.md),
  [breakpoint("sm")]: {
    gridColumn: "auto",
    display: "flex",
    justifyContent: "space-around"
  }
}));
export const StyledScoreValue = styled("span")(({ theme }) => ({
  display: "block",
  fontWeight: theme.weights.bold,
  fontSize: calcRem(25),
  color: theme.colors.tipScoreCard.heading
}));
export const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: 0,
  color: theme.colors.tipScoreCard.heading,
  [breakpoint("sm")]: {
    fontSize: calcRem(13)
  },
  [breakpointMax("sm")]: {
    "&:first-child": {
      marginBottom: calcRem(theme.spacing.md)
    }
  }
}));
export const StyledHeading = styled(Heading)(({ theme }) => ({
  color: theme.colors.tipScoreCard.heading,
  [breakpoint("sm")]: {
    fontSize: calcRem(16)
  },
  marginBottom: 0,
  gridColumn: "1 / -1"
}));
export const HiddenMd = styled("span")({
  [breakpoint("md")]: {
    display: "none"
  }
});
export const HiddenSm = styled("span")({
  display: "none",
  [breakpoint("md")]: {
    display: "inline"
  }
});
export const StyledLadderContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.colors.palette.neutral30,
  maxHeight: calcRem(48),
  borderRadius: 8,
  "> a": {
    textDecoration: "none"
  },
  [breakpoint("sm")]: {
    gridRow: "2 / 3",
    gridColumnStart: "3",
    zIndex: 1
  }
}));
export const StyledLadderButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "100%",
  padding: theme.spacing.sm
}));
export const StyledLadderButton = styled("div")(() => ({
  display: "flex",
  alignItems: "center"
}));
export const StyledLadderButtonHeading = styled(Heading)(({ theme }) => ({
  color: theme.colors.tipScoreCard.heading,
  marginLeft: theme.spacing.xs,
  marginBottom: 0,
  [breakpoint("sm")]: {
    fontSize: calcRem(16)
  }
}));
