import React from 'react'
import { FlagProps } from '.'

export function HobartHurricanes({
    title = 'Hobart Hurricanes',
    ...props
}: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            {...props}
            viewBox="0 0 96 96"
        >
            <title>{title}</title>
            <rect
                x="-2"
                y="98"
                width="141.421"
                height="70.7107"
                transform="rotate(-45 -2 98)"
                fill="#2E2349"
            />
            <rect
                x="-52"
                y="48"
                width="141.421"
                height="70.7107"
                transform="rotate(-45 -52 48)"
                fill="#583073"
            />
            <rect
                x="-9"
                y="93"
                width="144"
                height="16"
                transform="rotate(-45 -9 93)"
                fill="#E2E2E2"
            />
        </svg>
    )
}

export default HobartHurricanes
