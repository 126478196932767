import React from 'react'
import { FlagProps } from '.'

export function Sydney({ title = 'Sydney', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h48v48H0z" fill="#FFF" />
                <path
                    d="M24-26.941L57.941 7l-22.97 22.97L24 19 13.03 29.97-9.942 7z"
                    fill="#CA1424"
                />
            </g>
        </svg>
    )
}
export default Sydney
