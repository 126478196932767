import { calcRem } from "@the-game/components/utils";
import {
  insetFocusStyle,
  visuallyHiddenStyle
} from "@the-game/components/utils";
import Check from "../Icons/Check";
import styled from "@emotion/styled";
export const StyledCheckboxLabel = styled("label")(({ alignItems, fluid }) => ({
  display: "inline-flex",
  alignItems,
  width: fluid ? "100%" : "auto",
  cursor: "pointer"
}));
export const StyledCheckboxChildren = styled("div")(({ theme }) => ({
  margin: 0,
  marginLeft: calcRem(theme.spacing.xs),
  fontFamily: theme.fontFamily
}));
export const StyledCheckbox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: calcRem(theme.spacing.lg),
  minWidth: calcRem(theme.spacing.lg),
  height: calcRem(theme.spacing.lg),
  padding: 2,
  backgroundColor: theme.colors.white,
  borderWidth: 2,
  borderColor: theme.colors.palette.neutral90,
  borderStyle: "solid"
}));
export const StyledCheck = styled(Check)(({ theme }) => ({
  flexGrow: 1,
  fill: theme.colors.primary,
  opacity: 0,
  transform: "scale(0.6)",
  transformOrigin: "center",
  transitionProperty: "opacity, transform",
  transition: "0.1s ease-out"
}));
export const StyledCheckboxInput = styled("input")(({ theme }) => ({
  ...visuallyHiddenStyle,
  ...insetFocusStyle(theme, "&:focus-visible + div"),
  [`&:focus + div`]: {
    borderColor: theme.colors.primary
  },
  // TODO: component selector
  // Cause: issues with Emotion
  // Need to update to 11
  [`&:checked + div`]: {
    [`& > svg`]: {
      opacity: 1,
      transform: "scale(1)"
    }
  },
  [`&:disabled + div`]: {
    backgroundColor: theme.colors.palette.neutral30,
    borderColor: theme.colors.palette.neutral50,
    [`& > svg`]: {
      fill: theme.colors.palette.neutral50
    }
  }
}));
