import React, { useEffect } from 'react'
import {
    Editor,
    EditorState,
    RichUtils,
    Modifier,
    ContentBlock,
} from 'draft-js'
import 'draft-js/dist/Draft.css'
import { convertToHTML } from 'draft-convert'
import Immutable from 'immutable'
import {
    CustomEditorClasses,
    StyledControlsWrapper,
    StyledEditor,
    StyledEditorWrapper,
} from './RichEditor.styled'
import {
    blockControls,
    BlockType,
    GetBlockControls,
    headerControls,
    InlineControls,
    InlineType,
    isAlignmentBlock,
    listControls,
} from './RichEditorControls'

export function RichEditor({ onChange }: { onChange: (e: any) => void }) {
    const [editorState, setEditorState] = React.useState(() =>
        EditorState.createEmpty(),
    )
    const selection = editorState.getSelection()
    const currentContent = editorState.getCurrentContent()
    const currentBlock = currentContent.getBlockForKey(selection.getStartKey())
    const stateBlockType = currentBlock.getType() as BlockType
    const textAlignData = currentBlock.getData().get('text-align')

    function toggleInlineStyle(inlineStyle: InlineType) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
    }

    function toggleBlockType(blockType: BlockType) {
        // Changing block data allows for appending custom styling (classes)
        const newContentState = Modifier.setBlockData(
            editorState.getCurrentContent(),
            selection,
            Immutable.Map(
                // Empty the block data if custom selection is triggered again
                blockType === textAlignData || !isAlignmentBlock(blockType)
                    ? []
                    : {
                          'text-align': blockType,
                      },
            ),
        )
        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'change-block-data',
        )
        if (isAlignmentBlock(blockType)) {
            setEditorState(newEditorState)
            // Do not allow for stacking lists with alignment (does not render well in terms of HTML)
            if (
                stateBlockType === 'unordered-list-item' ||
                stateBlockType === 'ordered-list-item'
            ) {
                setEditorState(
                    RichUtils.toggleBlockType(newEditorState, stateBlockType),
                )
            }

            return
        }
        setEditorState(RichUtils.toggleBlockType(newEditorState, blockType))
    }

    function blockStyleFn(contentBlock: ContentBlock) {
        const textAlignData = contentBlock.getData().get('text-align')
        if (textAlignData) {
            return textAlignData
        }
        return ''
    }

    // Update message and convert editor body to HTML
    const parsedHtml = convertToHTML({
        blockToHTML: (block) => {
            const alignedBlock = block?.data?.['text-align']
            const alignStyle =
                alignedBlock &&
                alignedBlock.substring(alignedBlock.indexOf('-') + 1)
            // Add custom styling to selected elements
            if (alignedBlock) {
                // TO-DO: When inline style is applied need to use inlineStyleRanges from block to map it and apply relevant containers together with align style, so that it is possible to align left/center/right and also underline / bold / italic - need some kind of helper function to handle this - alternatively disable inline styling when using custom align feature
                if (block.type === 'unstyled' && alignStyle !== 'unstyled') {
                    return <div style={{ textAlign: alignStyle }} />
                }
                const customBlock = blockControls.find(
                    (el) => el.style === block.type,
                )
                if (customBlock) {
                    if (
                        customBlock.style !== 'unordered-list-item' &&
                        customBlock.style !== 'ordered-list-item'
                    ) {
                        return `<${customBlock.label.toLowerCase()} style="text-align: ${alignStyle}" >${
                            block.text
                        }</${customBlock.label.toLowerCase()}>`
                    }
                }
            }
        },
    })(currentContent)

    const plainText = currentContent.getPlainText('\u0001')

    useEffect(() => {
        onChange({ parsedHtml, plainText })
    }, [parsedHtml, plainText, onChange])

    return (
        <StyledEditorWrapper>
            <StyledControlsWrapper>
                <InlineControls
                    editorState={editorState}
                    onToggle={toggleInlineStyle}
                />
                <GetBlockControls
                    editorState={editorState}
                    onToggle={toggleBlockType}
                    controlTypes={headerControls}
                />
                <GetBlockControls
                    editorState={editorState}
                    onToggle={toggleBlockType}
                    controlTypes={listControls}
                />
            </StyledControlsWrapper>
            <StyledEditor>
                <CustomEditorClasses>
                    <Editor
                        blockStyleFn={blockStyleFn}
                        editorState={editorState}
                        onChange={setEditorState}
                        spellCheck={true}
                        placeholder="Write a message..."
                    />
                </CustomEditorClasses>
            </StyledEditor>
        </StyledEditorWrapper>
    )
}
