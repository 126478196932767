import React from 'react'
import { ElevationSizes } from '@the-game/components/utils'
import { StyledBadge } from './Badge.styled'

export type BadgeKinds = 'win' | 'loss' | 'default'

export interface BadgeProps {
    children?: React.ReactNode
    kind?: BadgeKinds
    variant?: 'ghost'
    size?: 'sm' | 'lg'
    elevation?: ElevationSizes
    className?: string
}

export function Badge({
    children,
    kind,
    variant,
    size,
    elevation,
    className,
}: BadgeProps): JSX.Element {
    return (
        <StyledBadge
            component="span"
            elevation={elevation}
            padding={size === 'lg' ? 'xs' : 'xxs'}
            borderRadius={4}
            kind={kind}
            variant={variant}
            size={size}
            className={className}
        >
            {children}
        </StyledBadge>
    )
}

export default Badge
