import React from 'react'
import { FlagProps } from '.'

export function SydneyThunder({
    title = 'Sydney Thunder',
    ...props
}: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            {...props}
            viewBox="0 0 96 96"
        >
            <title>{title}</title>
            <rect y="64" width="96" height="32" fill="black" />
            <rect y="32" width="96" height="32" fill="white" />
            <rect width="96" height="32" fill="#8BCD00" />
        </svg>
    )
}

export default SydneyThunder
