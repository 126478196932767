import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function External({ title = 'External Icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon {...props} title={title} viewBox="0 0 24 24">
            <path d="M11,7 L11,9 L7,9 L7,17 L15,17 L15,13 L17,13 L17,19 L5,19 L5,7 L11,7 Z M19,5 L19,12 L17,12 L17,8.5 L12,13.5 L10.5,12 L15.5,7 L12,7 L12,5 L19,5 Z" />
        </SvgIcon>
    )
}

export default External
