import React from 'react'
import { FlagProps } from '.'

export function Adelaide({ title = 'Adelaide', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path d="M0 24h48v12H0z" fill="#FED201" />
                <path d="M0 12h48v12H0z" fill="#DE022C" />
                <path d="M0 36h48v12H0zM0 0h48v12H0z" fill="#002D59" />
            </g>
        </svg>
    )
}

export default Adelaide
