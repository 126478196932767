import React from 'react'
import { SvgIconProps, SvgIcon } from '../Icons/SvgIcon'

export function WestAustralian({
    title = 'The West Australian logo',
    ...props
}: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 160 139">
            <title>{title}</title>
            <path
                fillRule="evenodd"
                d="M77 114C66 99 67 78 89 59v64l19-17V58l5-4 8 8v58c-14 9-34 7-44-6zm51-77l15 16 6-6 1 1-7 7v58l10 10 6-6 1 1-21 21-16-17c-13 21-44 20-58 3-15 18-43 16-57 0l-2-3c-14-18-4-39 29-66-11 0-16-3-23-3-5 0-8 1-8 5 0 3 2 4 8 6-8 0-12-3-12-11s7-14 18-14c8 0 15 4 23 5 6 0 11-4 13-6v68l-19 17V58c-22 20-23 41-13 55 10 13 27 15 40 9-13-18-3-39 29-66-11 0-16-3-23-3-4 0-8 1-8 5 0 3 2 4 9 6-9 0-13-3-13-11s8-14 18-14c9 0 15 4 23 5 6 0 11-4 13-6v17l18-18zm15-37v12H0V0h143z"
            />
        </SvgIcon>
    )
}
export default WestAustralian
