import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Copy({ title = 'Copy Icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24" title={title}>
            <path d="M17 1v4h4v18H7v-4H3V1h14zm2 6h-2v12H9v2h10V7zm-4-4H5v14h10V3zm-2 10v2H7v-2h6zm0-4v2H7V9h6zm0-4v2H7V5h6z" />
        </SvgIcon>
    )
}

export default Copy
