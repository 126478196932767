import { calcRem } from "@the-game/components/utils";
import { Box } from "../Box";
import styled from "@emotion/styled";
import { modifyAlpha } from "../utils/modifyAlpha";
export const StyledBadge = styled(Box)(
  ({ theme, kind, variant }) => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: calcRem(theme.spacing.sm),
    fontWeight: 700,
    lineHeight: 1,
    ...getKindStyles(theme, kind, variant)
  })
);
function getKindStyles(theme, kind, variant) {
  switch (kind) {
    case "win":
      return variant === "ghost" ? getGhostStyle(theme.colors.palette.green500) : getSolidStyle(theme.colors.palette.green500);
    case "loss":
      return variant === "ghost" ? getGhostStyle(theme.colors.loss) : getSolidStyle(theme.colors.loss);
    default:
      return variant === "ghost" ? getGhostStyle(theme.colors.palette.neutral90) : getSolidStyle(theme.colors.palette.neutral90);
  }
}
function getSolidStyle(color) {
  return {
    backgroundColor: modifyAlpha(color, 0.06),
    color,
    transition: "all 0.1s linear"
  };
}
function getGhostStyle(primary) {
  return {
    borderColor: "currentcolor",
    borderStyle: "solid",
    borderWidth: 2,
    color: primary
  };
}
