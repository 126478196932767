import React from 'react'
import { FlagProps } from '.'

export function PeelThunder({ title = 'PeelThunder', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g>
                <rect width="48" height="48" fill="#009F93" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M-3.75736 0.0766953L-8 4.31934L19.5772 31.8965L23.8198 36.1391L28.0624 31.8965L55.6396 4.31934L51.397 0.0766953L23.8198 27.6539L-3.75736 0.0766953Z"
                    fill="black"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M-3.75736 -8.42282L-8 -4.18018L19.5772 23.397L23.8198 27.6396L28.0624 23.397L55.6396 -4.18018L51.397 -8.42282L23.8198 19.1543L-3.75736 -8.42282Z"
                    fill="white"
                />
                <path
                    d="M24 19.5L4.5 -5.27313e-06L43 -5.72205e-06L24 19.5Z"
                    fill="#012B5D"
                />
            </g>
        </svg>
    )
}

export default PeelThunder
