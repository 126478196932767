import React from 'react'
import {
    StyledMarginCard,
    StyledMarginResult,
    StyledSummaryMarginResult,
} from './MarginCard.styled'

export interface MarginCardProps {
    tippedMargin?: number
    winningMargin?: number
    marginScore?: number
    isSummary?: boolean
    isResultIn: boolean
    marginText: string
}

export function MarginCard({
    tippedMargin = 0,
    winningMargin = 0,
    marginScore, // if margin score is undefined then it is still being calculated.
    isSummary = false,
    isResultIn,
    marginText,
}: MarginCardProps) {
    if (!isResultIn) {
        return (
            <StyledMarginCard padding={'unset'} isSummary={isSummary}>
                <StyledMarginResult>
                    Predicted {marginText}: <b>{tippedMargin}</b>
                </StyledMarginResult>
            </StyledMarginCard>
        )
    }

    return (
        <StyledMarginCard padding={'unset'} isSummary={isSummary}>
            <span>
                Predicted {marginText}: <b>{tippedMargin}</b>
            </span>
            <span>
                Actual {marginText}: <b>{winningMargin}</b>
            </span>

            {isSummary && marginScore !== undefined && marginScore > 0 && (
                <StyledSummaryMarginResult>
                    You were off by <b>{marginScore}</b>
                </StyledSummaryMarginResult>
            )}

            {isSummary && marginScore === 0 && (
                <StyledSummaryMarginResult>
                    You guessed the {marginText} correctly!
                </StyledSummaryMarginResult>
            )}
        </StyledMarginCard>
    )
}
