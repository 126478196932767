import React from 'react'
import Adelaide from './Adelaide'
import Brisbane from './Brisbane'
import Carlton from './Carlton'
import Collingwood from './Collingwood'
import Essendon from './Essendon'
import Fremantle from './Fremantle'
import Geelong from './Geelong'
import GoldCoast from './GoldCoast'
import GWS from './GWS'
import Hawthorn from './Hawthorn'
import Melbourne from './Melbourne'
import NorthMelbourne from './NorthMelbourne'
import PortAdelaide from './PortAdelaide'
import Richmond from './Richmond'
import StKilda from './StKilda'
import Sydney from './Sydney'
import WestCoast from './WestCoast'
import WesternBulldogs from './WesternBulldogs'
import BrisbaneHeat from './BrisbaneHeat'
import HobartHurricanes from './HobartHurricanes'
import MelbourneRenegades from './MelbourneRenegades'
import MelbourneStars from './MelbourneStars'
import PerthScorchers from './PerthScorchers'
import AdelaideStrikers from './AdelaideStrikers'
import SydneySixers from './SydneySixers'
import SydneyThunder from './SydneyThunder'
import ToBeConfirmed from './ToBeConfirmed'
import WestPerthFalcons from './West Perth Falcons'
import EastFremantleSharks from './East Fremantle Sharks'
import ClaremontTigers from './Claremont Tigers'
import PeelThunder from './Peel Thunder'
import PerthDemons from './Perth Demons'
import SouthFremantleBulldogs from './South Fremantle Bulldogs'
import SubiacoLions from './Subiaco Lions'
import SwanDistrict from './Swan District'
import EastPerthRoyals from './East Perth Royals'
import { Team } from '@the-game/api-interfaces'

export interface FlagProps extends React.SVGProps<SVGSVGElement> {
    className?: string
    title?: string
}

export const flagMap: Record<Team, React.FC<FlagProps>> = {
    // AFL
    ADEL: Adelaide,
    BRIS: Brisbane,
    CARL: Carlton,
    COLL: Collingwood,
    ESS: Essendon,
    FRE: Fremantle,
    GEEL: Geelong,
    GC: GoldCoast,
    GWS: GWS,
    HAW: Hawthorn,
    MELB: Melbourne,
    NM: NorthMelbourne,
    PORT: PortAdelaide,
    RICH: Richmond,
    STK: StKilda,
    SYD: Sydney,
    WCE: WestCoast,
    WB: WesternBulldogs,

    // BBL
    HEA: BrisbaneHeat,
    HUR: HobartHurricanes,
    MELR: MelbourneRenegades,
    MELS: MelbourneStars,
    SCO: PerthScorchers,
    STR: AdelaideStrikers,
    SYDS: SydneySixers,
    SYDT: SydneyThunder,

    // WAFL
    'W.PER': WestPerthFalcons,
    'E.FRE': EastFremantleSharks,
    PEEL: PeelThunder,
    CLA: ClaremontTigers,
    SWA: SwanDistrict,
    PER: PerthDemons,
    'S.FRE': SouthFremantleBulldogs,
    'E.PER': EastPerthRoyals,
    SUBI: SubiacoLions,

    // NRL
    BRI: ToBeConfirmed,
    CAN: ToBeConfirmed,
    CBY: ToBeConfirmed,
    CRO: ToBeConfirmed,
    GCT: ToBeConfirmed,
    DOL: ToBeConfirmed,
    MAN: ToBeConfirmed,
    NEW: ToBeConfirmed,
    PEN: ToBeConfirmed,
    SOU: ToBeConfirmed,
    MEL: ToBeConfirmed,
    NQC: ToBeConfirmed,
    NZ: ToBeConfirmed,
    PAR: ToBeConfirmed,
    STG: ToBeConfirmed,
    SYDR: ToBeConfirmed,
    WST: ToBeConfirmed,
    TBC: ToBeConfirmed,
}
