/**
 * DEPRECATED:
 * This component has been worked into the avatar selector.
 *
 * It will be maintained for now, until the edit profile page is updated
 * to use the AvatarSelector. Then it will be deleted.
 */

import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Button from '../Button/Button'
import { Spinner } from '../Spinner/Spinner'
import AvatarIcon from '../Icons/Avatar'
import {
    StyledImageUploader,
    StyledImagePreview,
    StyledIconSlot,
    StyledRemoveButtonWrapper,
    StyledCross,
    StyledImageUploaderContainer,
} from './ImageUploader.styled'
import { QueryStatus } from 'react-query'
import { FormHelp } from '../FormHelp/FormHelp'
import { Label } from '../Label/Label'
import { Cross } from '../Icons/Cross'
import { FormControl } from '../FormControl/FormControl'
import { Avatar } from '../Avatar/Avatar'
import { getBase64, getBlobfromURL, resizeImage } from './helpers'
import { Team } from '@the-game/api-interfaces'

export interface ImageUploaderProps {
    id?: string
    name?: string
    errorText?: string
    helperText?: string
    label?: string
    teamFlag?: Team
    initialImage?: string
    onRemove?: () => void
    onImageChange: (file?: File) => void
}

export interface StyledImagePreviewProps
    extends React.HTMLAttributes<HTMLDivElement> {
    status?: QueryStatus
    image?: string
}

const messages = {
    default: 'Select image',
    change: 'Change image',
}

export function ImageUploader({
    id,
    name,
    label,
    helperText,
    errorText,
    onImageChange,
    onRemove,
    teamFlag,
    initialImage,
}: ImageUploaderProps): JSX.Element {
    const fileInput = useRef<HTMLInputElement>(null)
    const [image, setImage] = useState<string | undefined>()
    const [loadingBase64, setLoadingBase64] = useState(false)

    // Only on initial render
    useEffect(() => {
        const getImg = async () => {
            if (initialImage) {
                const img = await getBlobfromURL(initialImage)
                if (img) {
                    setLoadingBase64(true)
                    const b64Image = await getBase64(img)
                    setImage(b64Image)
                    setLoadingBase64(false)
                }
            }
        }
        getImg()
    }, [initialImage])

    const handleClick = () => {
        fileInput.current?.click()
    }

    const onImageSelect = async (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.currentTarget

        if (target && target.files && target.files.length > 0) {
            setLoadingBase64(true)
            const file = await resizeImage(target.files[0], {})
            const base64 = await getBase64(file)

            onImageChange(file)
            setImage(base64)
            setLoadingBase64(false)
        } else {
            // they clicked cancel, so don't change anything
        }
    }

    return (
        <FormControl>
            <Label htmlFor={id}>{label}</Label>
            <StyledImageUploaderContainer>
                <StyledImageUploader>
                    {image && (
                        <StyledRemoveButtonWrapper
                            type="button"
                            onClick={() => {
                                setImage(undefined)
                                onRemove && onRemove()
                                setLoadingBase64(false)

                                // also clear the value of the field. file inputs can't be controlled by React
                                // this allows re-selecting the same image if you accidentally cleared it
                                if (fileInput.current) {
                                    fileInput.current.value = ''
                                }
                            }}
                            aria-label="Remove Selected Image"
                        >
                            <StyledCross>
                                <Cross themeColor="artfulRed" />
                            </StyledCross>
                        </StyledRemoveButtonWrapper>
                    )}
                    <StyledIconSlot
                        onClick={handleClick}
                        type="button"
                        aria-label="Image preview"
                    >
                        {image ? (
                            loadingBase64 ? (
                                <div>
                                    <Spinner />
                                </div>
                            ) : (
                                <StyledImagePreview image={image} />
                            )
                        ) : teamFlag ? (
                            <Avatar size="xxl" teamFlag={teamFlag} />
                        ) : (
                            <AvatarIcon themeColor="neutral50" size="xl" />
                        )}
                    </StyledIconSlot>
                    <input
                        id={id}
                        name={name}
                        ref={fileInput}
                        onChange={onImageSelect}
                        type="file"
                        accept=".png,.jpg,.jpeg,.gif"
                        style={{ display: 'none' }}
                    />
                    <Button
                        size="sm"
                        fluid
                        variant="ghost"
                        onClick={handleClick}
                    >
                        {image ? messages.change : messages.default}
                    </Button>
                </StyledImageUploader>

                {errorText && (
                    <FormHelp variant={'error'}>{errorText}</FormHelp>
                )}
                {helperText && (
                    <FormHelp variant={'normal'}>{helperText}</FormHelp>
                )}
            </StyledImageUploaderContainer>
        </FormControl>
    )
}

export default ImageUploader
