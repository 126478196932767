import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Home({ title = 'Home icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon {...{ title, ...props }} viewBox="0 0 24 24">
            <g fillRule="evenodd">
                <path d="M21,9.85786238 L14.0208,2.87868238 C12.8492,1.70710638 10.9497,1.70710538 9.77817,2.87868238 L3,9.65685238 L3,22 L10,22 L10,16.1714924 C10,15.0668924 10.8954,14.1714924 12,14.1714924 C13.1046,14.1714924 14,15.0668924 14,16.1714924 L14,22 L21,22 L21,9.85786238 Z M11.1924,4.29289237 L5,10.4852824 L5,20.0000024 L8,20.0000024 L8,16.1714924 C8,13.9623924 9.79086,12.1714924 12,12.1714924 C14.2091,12.1714924 16,13.9623924 16,16.1714924 L16,20.0000024 L19,20.0000024 L19,10.6862924 L12.6066,4.29289237 C12.2161,3.90237237 11.5829,3.90237237 11.1924,4.29289237 Z"></path>
            </g>
        </SvgIcon>
    )
}

export default Home
