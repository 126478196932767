import React from 'react'
import { FlagProps } from '.'

export function StKilda({ title = 'St Kilda', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h16v48H0z" fill="#E82636" />
                <path d="M16 0h16v48H16z" fill="#FFF" />
                <path d="M32 0h16v48H32z" fill="#000" />
            </g>
        </svg>
    )
}

export default StKilda
