import {
  getElevationBySize,
  focusStyle,
  calcRem,
  fontScale,
  breakpoint
} from "@the-game/components/utils";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { modifyLuminance } from "../utils/modifyLuminance";
const buttonRadius = calcRem(20);
const borderSize = 2;
function getPadding(padding, size, theme) {
  return padding === "auto" ? size === "lg" ? calcRem(20 - borderSize, theme.spacing.lg - borderSize) : size === "md" ? calcRem(
    theme.spacing.xs - borderSize,
    theme.spacing.md - borderSize
  ) : calcRem(
    theme.spacing.xxs - borderSize,
    theme.spacing.sm - borderSize
  ) : padding;
}
export const StyledButton = styled("button", {
  shouldForwardProp: isPropValid
})(
  {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: `${calcRem(borderSize)} solid transparent`,
    fontWeight: 600,
    "&[disabled]": {
      cursor: "not-allowed",
      boxShadow: "unset"
    }
  },
  ({ shape }) => ({
    borderRadius: shape === "square" ? 0 : shape === "rounded" ? "3rem" : 4
  }),
  ({ fluid }) => ({
    width: fluid ? "100%" : "auto"
  }),
  ({ theme }) => ({ ...focusStyle(theme) }),
  ({ theme, size, padding }) => ({
    padding: getPadding(padding, size, theme),
    fontFamily: theme.fontFamily,
    fontSize: calcRem(
      fontScale(size === "lg" ? 1.5 : size === "md" ? 1.125 : 0.875)
    ),
    lineHeight: calcRem(24),
    svg: {
      height: calcRem(theme.metrics.icons.sm),
      width: calcRem(theme.metrics.icons.sm),
      margin: theme.spacing.xxs
    }
  }),
  ({ theme, kind, variant }) => ({
    ...getKindVariantStyles(kind, variant, theme)
  })
);
function getKindVariantStyles(kind, variant, theme) {
  const loss600 = modifyLuminance(theme.colors.loss, 31);
  const loss800 = modifyLuminance(theme.colors.loss, 26);
  switch (kind) {
    case "action":
      return variant === "ghost" ? getGhostStyles(
        theme.colors.white,
        theme.colors.palette.green500,
        theme.colors.palette.neutral50,
        theme.colors.palette.green600,
        theme.colors.palette.green500,
        theme.colors.palette.green700,
        theme.colors.white,
        theme.colors.palette.green500,
        theme.colors.palette.neutral70
      ) : getButtonStyles(
        theme.colors.palette.green500,
        theme.colors.palette.green600,
        theme.colors.palette.green700,
        theme.colors.palette.green700,
        theme.colors.palette.neutral50,
        theme.colors.white,
        theme.colors.palette.neutral90
      );
    case "neutral":
      return variant === "ghost" ? getGhostStyles(
        theme.colors.white,
        theme.colors.palette.neutral70,
        theme.colors.palette.neutral50,
        theme.colors.palette.neutral30,
        theme.colors.palette.neutral30,
        theme.colors.palette.neutral30,
        theme.colors.palette.neutral90,
        theme.colors.palette.neutral70,
        theme.colors.palette.neutral30,
        theme.colors.palette.black
      ) : getButtonStyles(
        theme.colors.palette.neutral30,
        theme.colors.palette.neutral50,
        theme.colors.palette.neutral50,
        theme.colors.palette.neutral50,
        theme.colors.palette.neutral30,
        theme.colors.palette.black,
        theme.colors.palette.neutral70
      );
    case "gradient":
      return {
        background: theme.colors.gradientButton.background,
        color: theme.colors.gradientButton.text,
        border: "none",
        transition: "all ease-in-out 0.1s",
        "&:hover": {
          ...getElevationBySize(2),
          background: `linear-gradient(${theme.colors.primary}, ${theme.colors.primary})`
        },
        "&:focus": {
          ...getElevationBySize(2),
          background: `linear-gradient(${modifyLuminance(
            theme.colors.primary,
            85
          )}, ${modifyLuminance(theme.colors.primary, 85)})`
        },
        "&:active": {
          ...getElevationBySize(2),
          background: `linear-gradient(${modifyLuminance(
            theme.colors.primary,
            45
          )}, ${modifyLuminance(theme.colors.primary, 45)})`
        }
      };
    case "danger":
      return variant === "ghost" ? getGhostStyles(
        theme.colors.white,
        theme.colors.loss,
        theme.colors.palette.neutral50,
        loss600,
        theme.colors.loss,
        loss600,
        theme.colors.white,
        theme.colors.loss,
        theme.colors.palette.neutral70
      ) : getButtonStyles(
        theme.colors.loss,
        loss800,
        loss600,
        loss800,
        theme.colors.palette.neutral30,
        theme.colors.white,
        theme.colors.palette.neutral90
      );
    case "inactive":
      return {
        cursor: "default",
        background: theme.colors.palette.neutral70,
        color: theme.colors.white,
        padding: `${calcRem(theme.spacing.sm)} ${calcRem(
          theme.spacing.md
        )}`
      };
    default:
      return variant === "ghost" ? getGhostStyles(
        theme.colors.white,
        theme.colors.primary,
        theme.colors.palette.neutral50,
        modifyLuminance(theme.colors.primary, 40),
        theme.colors.primary,
        modifyLuminance(theme.colors.primary, 30),
        theme.colors.white,
        theme.colors.primary,
        theme.colors.palette.neutral70,
        theme.colors.white
      ) : getButtonStyles(
        theme.colors.primary,
        modifyLuminance(theme.colors.primary, 50),
        modifyLuminance(theme.colors.primary, 50),
        modifyLuminance(theme.colors.primary, 30),
        theme.colors.palette.neutral50,
        theme.colors.white,
        theme.colors.palette.neutral90
      );
  }
}
function getButtonStyles(background, focusBg, hoverBg, activeBg, disabledBg, color, disabledColor) {
  return {
    position: "relative",
    backgroundColor: background,
    color,
    transition: "background 0.1s ease-in-out",
    WebkitTapHighlightColor: activeBg,
    path: {
      fill: color
    },
    "&::after": {
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      content: "''",
      position: "absolute",
      zIndex: -1,
      opacity: 0,
      borderRadius: buttonRadius,
      transition: "opacity 0.1s ease-in-out",
      ...getElevationBySize(2)
    },
    "&:focus": {
      backgroundColor: focusBg
    },
    "@media (any-hover: hover)": {
      "&:hover": {
        backgroundColor: hoverBg,
        "&::after": {
          opacity: 1
        }
      }
    },
    "&:active": {
      backgroundColor: activeBg,
      "&::after": {
        ...getElevationBySize(2)
      }
    },
    "&[disabled]": {
      backgroundColor: disabledBg,
      color: disabledColor,
      boxShadow: "unset",
      "&::after": {
        content: "none"
      }
    }
  };
}
function getGhostStyles(bgColor, borderColor, disabledBorderColor, focusBg, hoverBg, activeBg, hoverColor, color, disabledColor, focusColor = "#fff") {
  return {
    backgroundColor: bgColor,
    borderColor,
    color,
    transition: "0.1s ease-in-out",
    transitionProperty: "background-color, color",
    WebkitTapHighlightColor: activeBg,
    path: {
      fill: color,
      transition: "0.1s ease-in-out fill"
    },
    "&:focus": {
      backgroundColor: focusBg,
      color: focusColor,
      path: {
        fill: focusColor
      }
    },
    "@media (any-hover: hover)": {
      "&:hover": {
        backgroundColor: hoverBg,
        color: hoverColor,
        path: {
          fill: hoverColor
        }
      }
    },
    "&:active": {
      backgroundColor: activeBg,
      color: focusColor,
      path: {
        fill: focusColor
      }
    },
    "&[disabled]": {
      backgroundColor: bgColor,
      borderColor: disabledBorderColor,
      boxShadow: "unset",
      color: disabledColor
    }
  };
}
export const StyledPDFTabs = styled("div")(() => ({
  "> :nth-child(1)": {
    borderRadius: "4px 0px 0px 4px",
    borderLeftWidth: 2
  },
  "> :nth-last-child(1)": {
    borderRadius: "0px 4px 4px 0px"
  },
  display: "flex"
}));
export const StyledPDFButtons = styled("button")(({ theme }) => ({
  cursor: "pointer",
  border: `2px solid ${theme.colors.palette.bayBlue}`,
  hover: `${theme.colors.palette.bayBlue}`,
  borderLeftWidth: 0,
  backgroundColor: "transparent",
  color: theme.colors.palette.bayBlue,
  padding: calcRem(6),
  fontSize: calcRem(14),
  width: calcRem(105),
  [breakpoint("xs")]: {
    width: calcRem(115)
  },
  [breakpoint("sm")]: {
    width: calcRem(172)
  },
  "&:active": {
    color: theme.colors.white,
    backgroundColor: theme.colors.palette.bayBlue,
    textDecorationColor: "currentColor"
  },
  "&:disabled": {
    opacity: 0.6,
    cursor: "not-allowed"
  }
}));
