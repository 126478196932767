import { PaginatedResponse } from '@the-game/api-interfaces'
import { QueryResult } from 'react-query'
// To use usePagination the request must extend PaginationRequest
// and the response must extend PaginatedResponse

export interface PaginationInformation<T> {
    /** Number of items at the remote resource */
    totalItems: number | undefined
    pageNumber: number
    pageSize: number
    pageData: T[] | undefined
}

export interface PaginationReady<T> extends PaginationInformation<T> {
    totalItems: number
}

interface PagedData<T> {
    pagination: PaginationInformation<T>
}

interface PaginateResultArgs<T> {
    pageNumber: number
    pageSize?: number
    queryResult: QueryResult<T, unknown>
}

export function usePaginatedResult<X, T extends PaginatedResponse<X>>({
    pageNumber,
    pageSize = 10,
    queryResult,
}: PaginateResultArgs<T>): QueryResult<T, unknown> & PagedData<X> {
    if (queryResult.data === undefined) {
        return {
            ...queryResult,
            pagination: {
                pageNumber,
                pageSize,
                totalItems: undefined,
                pageData: undefined,
            },
        }
    }

    return {
        ...queryResult,
        pagination: {
            totalItems: queryResult.data.totalItems,
            pageNumber,
            pageData: queryResult.data.page,
            pageSize,
        },
    }
}

export function getOffset(page: number, pageSize: number) {
    return (page - 1) * pageSize
}

export function paginationReady<T>(
    pagination?: PaginationInformation<T>,
): pagination is PaginationReady<T> {
    return pagination?.totalItems !== undefined
}
