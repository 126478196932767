import React from 'react'
import { calcRem, ElevationSizes } from '@the-game/components/utils'
import { Box } from '../Box'
import { IconSizes, Spacing } from '../theme'
import styled from '@emotion/styled'

import AvatarIcon from '../Icons/Avatar'
import { flagMap, FlagProps } from '@the-game/icons'
import { Team } from '@the-game/api-interfaces'

export interface AvatarProps {
    name?: string
    spacing?: Spacing
    children?: React.ReactNode
    size?: IconSizes | 'auto'
    className?: string
    elevation?: ElevationSizes
    src?: string
    teamFlag?: Team
}

export function Avatar({
    className,
    name,
    spacing,
    children,
    size = 'sm',
    elevation,
    src,
    teamFlag,
}: AvatarProps) {
    let TeamFlag: React.FC<FlagProps> | undefined

    if (teamFlag) {
        TeamFlag = flagMap[teamFlag]
    }

    return (
        <StyledAvatar
            className={className}
            spacing={spacing}
            aria-label={name}
            size={size}
            elevation={elevation}
        >
            {src ? (
                <img src={src} alt={name} loading="lazy" />
            ) : TeamFlag ? (
                <TeamFlag viewBox="0 0 48 48" />
            ) : children ? (
                children
            ) : (
                <AvatarIcon themeColor="black" />
            )}
        </StyledAvatar>
    )
}

const StyledAvatar = styled(Box)<{ size: IconSizes | 'auto' }>(
    ({ theme, size }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: size === 'auto' ? '100%' : calcRem(theme.metrics.icons[size]),
        height: size === 'auto' ? '100%' : calcRem(theme.metrics.icons[size]),
        minWidth: size === 'auto' ? '100%' : calcRem(theme.metrics.icons[size]),
        minHeight:
            size === 'auto' ? '100%' : calcRem(theme.metrics.icons[size]),
        overflow: 'hidden',
        backgroundColor: theme.colors.palette.neutral30,
        borderRadius: '50%',
        border: '1px solid',
        borderColor: theme.colors.palette.neutral50,

        svg: {
            width: '100%',
            height: '100%',
        },

        img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    }),
)
