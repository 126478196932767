import React from 'react'
import { keyframes } from '@emotion/react'
import { Link } from 'react-router-dom'
import { ProfileDTO } from '@the-game/api-interfaces'
import styled from '@emotion/styled'
import { breakpoint, elevations, calcRem } from '@the-game/components/utils'
import { Cross } from '../../Icons/Cross'
import { Hamburger } from '../../Icons/Hamburger'
import { Avatar } from '../../Avatar/Avatar'
import { Stack } from '../../Stack'
import { LogoutSpan } from './LogoutSpan'
import { ContactUsButton } from './ContactUsButton'
import { MenuSection, AnimationState } from '../types'
import { IconButton } from './IconButton'
import { MobileMenu, menuHeaderHeight } from './MobileMenu'
import FocusTrap from 'focus-trap-react'

const { Elevation2 } = elevations

const menuWidth = 281

const fade = keyframes({
    '0%': {
        opacity: 0,
    },
    '100%': {
        left: 1,
    },
})

const slide = keyframes({
    '0%': {
        left: -menuWidth,
    },
    '100%': {
        left: 0,
    },
})

const StyledOverlay = styled('div')<{ animation: AnimationState }>(
    ({ animation, theme }) => ({
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        opacity: animation === 'exiting' ? 0 : 1,
        transition: 'ease-out 0.3s opacity',
        backgroundColor: `hsla(0, 0%, 0%, 0.1)`,
        animation: `${fade} 0.3s ease-in`,
        zIndex: theme.zIndex.modal,
    }),
)

const StyledMobileMenu = styled('div')<{ animation: AnimationState }>(
    ({ theme, animation }) => ({
        position: 'fixed',
        left: animation === 'exiting' ? -menuWidth : 0,
        transition: 'ease-in 0.3s left',
        top: 0,
        width: menuWidth,
        height: '100%',
        backgroundColor: theme.colors.white,
        ...Elevation2,
        zIndex: theme.zIndex.modal,
        animation: `${slide} 0.3s ease-out`,
        display: 'grid',
        gridTemplateRows: `${menuHeaderHeight} auto`,
    }),
)
const StyledMenuHeader = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateRows: '3rem 1fr 1fr',
    gridGap: theme.spacing.sm,
    padding: theme.spacing.sm,
    borderBottom: `${theme.colors.palette.neutral50} 1px solid`,
    a: {
        padding: 0,
        textDecoration: 'none',
    },
}))

const StyledMobileNavigation = styled('div')({
    [breakpoint('lg')]: {
        display: 'none',
    },
})

const StyledAvatarLink = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateRows: `${calcRem(24)} ${calcRem(24)}`,
    gridTemplateColumns: `${calcRem(48)} 1fr`,
    gridColumnGap: theme.spacing.sm,
    '> div:first-child': {
        gridRow: '1 / -1',
    },
}))

const StyledUsername = styled('span')({
    maxWidth: calcRem(200),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
})

function UserAvatar({ user }: { user?: ProfileDTO }) {
    return (
        <StyledAvatarLink>
            <Avatar size="lg" src={user?.avatar} teamFlag={user?.teamFlag} />
            <StyledUsername>{user?.username}</StyledUsername>
            <Link to="/profile">View profile</Link>
        </StyledAvatarLink>
    )
}

export function MobileNavigation({
    user,
    logoutButton,
    items,
    loggedIn,
    setScroll = (_) => undefined,
}: {
    user?: ProfileDTO
    logoutButton?: React.FC
    items: MenuSection[]
    loggedIn: boolean
    setScroll: (val: boolean) => void
}) {
    const [animation, setAnimation] = React.useState<AnimationState>('hidden')

    React.useEffect(() => {
        if (animation === 'visible') {
            setScroll(false)
        }
        const timer = setTimeout(() => {
            if (animation === 'exiting') {
                setAnimation('hidden')
                setScroll(true)
            }
        }, 350)
        return () => {
            setScroll(true)
            clearTimeout(timer)
        }
    }, [animation, setScroll])

    React.useEffect(() => {
        function keydownHandler({ key }: KeyboardEvent) {
            if (key === 'Escape') {
                setAnimation('exiting')
            }
        }

        if (animation !== 'hidden') {
            document.addEventListener('keydown', keydownHandler)
        }

        return () => {
            document.removeEventListener('keydown', keydownHandler)
        }
    }, [animation])

    return (
        <StyledMobileNavigation>
            <IconButton
                onClick={() => setAnimation('visible')}
                aria-label="Open menu"
            >
                <Hamburger aria-hidden title="" size="md" />
            </IconButton>

            {animation !== 'hidden' && (
                <StyledOverlay
                    animation={animation}
                    onClick={() => setAnimation('exiting')}
                />
            )}
            {animation !== 'hidden' && (
                <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                    <div>
                        <StyledMobileMenu animation={animation}>
                            <StyledMenuHeader>
                                <Stack justifyContent="space-between">
                                    <IconButton
                                        aria-label="Close menu"
                                        width="sm"
                                        onClick={() => setAnimation('exiting')}
                                    >
                                        <Cross aria-hidden title="" />
                                    </IconButton>
                                    {loggedIn && logoutButton ? (
                                        logoutButton({
                                            children: <LogoutSpan />,
                                        })
                                    ) : (
                                        <span></span>
                                    )}
                                </Stack>
                                {loggedIn && <UserAvatar user={user} />}
                                <ContactUsButton />
                            </StyledMenuHeader>
                            <MobileMenu
                                items={items}
                                setAnimation={setAnimation}
                            />
                        </StyledMobileMenu>
                    </div>
                </FocusTrap>
            )}
        </StyledMobileNavigation>
    )
}
