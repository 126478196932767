import React from 'react'
import { StyledLabel, StyledLabelRequired } from './Label.styled'

export interface LabelProps
    extends React.LabelHTMLAttributes<HTMLLabelElement> {
    required?: boolean
    children?: React.ReactNode
}

export function Label({
    children,
    required = false,
    ...props
}: LabelProps): JSX.Element {
    return (
        <StyledLabel {...props}>
            {required && (
                <StyledLabelRequired aria-hidden={true}>*</StyledLabelRequired>
            )}
            {children}
        </StyledLabel>
    )
}

export default Label
