import React from 'react'
import {
    StyledRankSection,
    StyledScoreSpan,
    StyledRank,
    StyledRankInfo,
    StyledRankIndicator,
    StyledNoRankText,
} from './CompetitionRank.styled'
import Up from './icons/Up'
import Down from './icons/Down'
import { Skeleton } from '../Skeleton'
import { SportName } from '@the-game/api-interfaces'

interface Props {
    rank?: number
    memberTotal?: number
    previousRank?: number
    showBadge: boolean
    isLoading: boolean
    sport: SportName
}

function displayRankInfo(params: {
    previousRank?: number
    rank?: number
    memberTotal?: number
    showBadge: boolean
    isLoading?: boolean
}) {
    const { previousRank, rank, memberTotal, showBadge } = params
    let rankIndicator
    let stackIndicator = false

    if (rank) {
        const positiveChange = previousRank ? previousRank - rank >= 0 : false
        const difference = previousRank ? Math.abs(rank - previousRank) : 0

        // Only render indicator on difference > 0
        if (difference !== 0) {
            const rankValue = (
                <StyledRank positive={positiveChange}>{difference}</StyledRank>
            )

            if (difference > 0) {
                const indicator = positiveChange ? (
                    <Up aria-hidden title="" size={'xxs'} />
                ) : (
                    <Down aria-hidden title="" size={'xxs'} />
                )
                stackIndicator =
                    difference > 999 ||
                    (Number(rank || 0) > 999 &&
                        Number(memberTotal || 0) > 999) ||
                    showBadge

                rankIndicator = (
                    <StyledRankIndicator
                        aria-label={`${
                            positiveChange ? 'improved' : 'dropped'
                        } by ${difference} to `}
                    >
                        {indicator}
                        {rankValue}&nbsp;
                    </StyledRankIndicator>
                )
            }
        }
    }

    return (
        <StyledRankInfo stackIndicator={stackIndicator}>
            <span>Your rank&nbsp;</span>
            {rankIndicator}
        </StyledRankInfo>
    )
}

export function CompetitionRank(props: Props) {
    const {
        rank,
        memberTotal,
        previousRank,
        showBadge,
        isLoading,
        sport,
    } = props

    const noRank = !previousRank && !rank

    return (
        <StyledRankSection>
            {noRank && !isLoading ? (
                <StyledNoRankText>
                    Your rank will be available at the end of the{' '}
                    {sport === 'Cricket' ? 'round' : 'round'}
                </StyledNoRankText>
            ) : isLoading ? (
                <Skeleton width="100%" height={20} />
            ) : (
                <>
                    {displayRankInfo({
                        previousRank,
                        rank,
                        memberTotal,
                        showBadge,
                        isLoading,
                    })}
                    <div>
                        <StyledScoreSpan>{rank ?? '-'}</StyledScoreSpan> of{' '}
                        {memberTotal ?? '-'}
                    </div>
                </>
            )}
        </StyledRankSection>
    )
}
