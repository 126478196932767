import React from 'react'
import { Heading } from '../Heading/Heading'
import { QuickLink } from '../QuickLink/QuickLink'
import { StyledCompetitionHeader } from './Hero.styled'

interface BackLinkProps {
    to: string
    label: string
}

export interface HeroProps {
    backLink?: BackLinkProps
    heading: string
    children?: React.ReactNode
}

export function Hero({ backLink, heading, children }: HeroProps) {
    return (
        <StyledCompetitionHeader>
            {backLink && (
                <QuickLink to={backLink.to}>{backLink.label}</QuickLink>
            )}
            <Heading size="3">{heading}</Heading>
            {children}
        </StyledCompetitionHeader>
    )
}
