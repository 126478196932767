import React from 'react'
import { FlagProps } from '.'

export function GWS({ title = 'GWS', ...props }: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h48v24H0z" fill="#F49811" />
                <path d="M32 24h16v24H16z" fill="#5F5E5E" />
                <path d="M0 24h32L16 48H0z" fill="#FFF" />
            </g>
        </svg>
    )
}

export default GWS
