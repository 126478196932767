import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Share({ title = 'Share Icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon {...{ title, ...props }} viewBox="0 0 24 24">
            <path d="M18,9 C19.6569,9 21,7.65685 21,6 C21,4.34315 19.6569,3 18,3 C16.3431,3 15,4.34315 15,6 C15,6.12549 15.0077,6.24919 15.0227,6.37063 L8.08261,9.84066 C7.54305,9.32015 6.80891,9 6,9 C4.34315,9 3,10.3431 3,12 C3,13.6569 4.34315,15 6,15 C6.80891,15 7.54305,14.6798 8.08261,14.1593 L15.0227,17.6294 C15.0077,17.7508 15,17.8745 15,18 C15,19.6569 16.3431,21 18,21 C19.6569,21 21,19.6569 21,18 C21,16.3431 19.6569,15 18,15 C17.1911,15 16.457,15.3202 15.9174,15.8407 L8.97733,12.3706 C8.99229,12.2492 9,12.1255 9,12 C9,11.8745 8.99229,11.7508 8.97733,11.6294 L15.9174,8.15934 C16.457,8.67985 17.1911,9 18,9 Z" />
        </SvgIcon>
    )
}

export default Share
