import 'core-js/stable'
import 'cross-fetch/polyfill'
import 'regenerator-runtime/runtime'

import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { GameThemeProvider, GameGlobalStyles } from '@the-game/components'
import { initialiseRumSdk, initialiseBrowserLogs } from './app/utils/dataDog'

const cache = createCache({ key: 'game-cache' })
cache.compat = true

const App = lazy(() => import(/* webpackChunkName: 'app' */ './app/app'))
initialiseBrowserLogs()
initialiseRumSdk()

ReactDOM.render(
    <React.StrictMode>
        <CacheProvider value={cache}>
            <GameThemeProvider>
                <GameGlobalStyles />

                <Suspense fallback={null}>
                    <App />
                </Suspense>
            </GameThemeProvider>
        </CacheProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)
