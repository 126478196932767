import * as z from 'zod'
import { WinHistoryShape } from './match'
import { atLeastOneId } from './custom'
import { SeasonShape } from './season'
import { SportShape } from './sport'

const FormguideShape = z.object({
    winsAtThisVenue: z.number().optional(),
    lossesAtThisVenue: z.number().optional(),
    wins: z.number().optional(),
    losses: z.number().optional(),
    played: z.number().optional(),

    // last 5 sixes and winHistory are both "oldest first", so in [L,W,W,W,W], they have won their 4 most recent games
    lastFiveSixes: z.array(z.number()).optional(),
    winHistory: z.array(WinHistoryShape).optional(),
})

export const FormguideUpdatedLifecycleEventShape = z
    .object({
        type: z.literal('formguide-updated-lifecycle-event'),
        id: z.string().nonempty().optional(),
        externalId: z.string().nonempty().optional(),
        sport: SportShape,
        season: SeasonShape,
        round: z.number().optional(),
        homeTeam: FormguideShape,
        awayTeam: FormguideShape,
    })
    .refine(atLeastOneId.matcher, atLeastOneId.options)

export type FormguideUpdatedLifecycleEvent = z.TypeOf<
    typeof FormguideUpdatedLifecycleEventShape
>
