import React from 'react'
import styled from '@emotion/styled'
import { breakpoint, calcRem } from '@the-game/components/utils'
import cuid from 'cuid'

const StyledSvg = styled('svg')(({ theme }) => ({
    position: 'absolute',
    right: '-15rem',
    top: `calc(50% - ${theme.spacing.sm / 2}px)`,
    height: calcRem(140),
    zIndex: 0,
    [breakpoint('sm')]: {
        right: 0,
        bottom: 0,
        top: 'auto',
        height: calcRem(80),
    },
}))

export function AflLadderShape() {
    const gradientIdentifier = cuid()

    return (
        <StyledSvg
            viewBox="0 0 337 72"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-hidden
        >
            <title>Ladder Shape</title>
            <defs>
                <linearGradient
                    x1="0%"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                    id={gradientIdentifier}
                >
                    <stop stopColor="#3E65C0" offset="0%"></stop>
                    <stop stopColor="#3C6BC0" offset="5.72157434%"></stop>
                    <stop stopColor="#3A72C0" offset="12.0991254%"></stop>
                    <stop stopColor="#3878C0" offset="19.0233236%"></stop>
                    <stop stopColor="#357FC0" offset="26.3848397%"></stop>
                    <stop stopColor="#3385C0" offset="34.074344%"></stop>
                    <stop stopColor="#308CC0" offset="41.9825073%"></stop>
                    <stop stopColor="#2C92C0" offset="50%"></stop>
                    <stop stopColor="#2998C0" offset="58.0174927%"></stop>
                    <stop stopColor="#259EC0" offset="65.925656%"></stop>
                    <stop stopColor="#21A3C0" offset="73.6151603%"></stop>
                    <stop stopColor="#1CA8C0" offset="80.9766764%"></stop>
                    <stop stopColor="#17ACC0" offset="87.9008746%"></stop>
                    <stop stopColor="#11B0C0" offset="94.2784257%"></stop>
                    <stop stopColor="#09B4C0" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g stroke="none" fill="none" fillRule="evenodd">
                <path
                    d="M107.295393,0 L336.73757,0 L336.73757,0 L337,72 L0,72 L30.9489664,35.4149925 C49.9494077,12.9544271 77.8761173,5.40423322e-15 107.295393,0 Z"
                    fill={`url(#${gradientIdentifier})`}
                ></path>
            </g>
        </StyledSvg>
    )
}
