import { SportShape } from '@the-game/api-interfaces'
import * as z from 'zod'
import { Team } from '../api-interfaces'

export const LadderUpdatedLifecycleEventShape = z.object({
    type: z.literal('ladder-updated-lifecycle-event'),
    sport: SportShape,
    season: z.string(),
    position: z.number(),
    team: z.nativeEnum(Team),
    played: z.number(),
    win: z.number(),
    loss: z.number(),
    drawn: z.number().optional(),
    tied: z.number().optional(),
    noResult: z.number().optional(),
    bye: z.number().optional(),
    homeWins: z.number().optional(),
    homeLosses: z.number().optional(),
    homeDraws: z.number().optional(),
    awayWins: z.number().optional(),
    awayLosses: z.number().optional(),
    awayDraws: z.number().optional(),
    ptsFor: z.number().optional(),
    ptsAgainst: z.number().optional(),
    percentage: z.number().optional(),
    netRunRate: z.number().optional(),
    points: z.number(),
    last5: z.array(z.enum(['W', 'L', 'D'])),
})

export type LadderUpdatedLifecycleEvent = z.TypeOf<
    typeof LadderUpdatedLifecycleEventShape
>
