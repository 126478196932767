import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function ChevronDown({
    title = 'Chevron down icon',
    ...props
}: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 15 9" title={title}>
            <path d="M1.414 0L0 1.414l7.071 7.071 7.071-7.07L12.728 0 7.07 5.657z" />
        </SvgIcon>
    )
}

export default ChevronDown
