/**
 * @description Modifies alpha property of a hex,rgb[a],hsl[a] value
 * @property {string} initial color value
 * @property {number}  new alpha value between 0 and 1
 * @returns {string} as hsl(initial Hue, initual Saturation, new Luminance value)
 */
export function modifyAlpha(color: string, alpha: number): string {
    if (alpha < 0 || alpha > 1) {
        throw new Error(
            'invalid value for `alpha`. Must be in the range 0 to 1',
        )
    }
    if (color.startsWith('#')) {
        const hexAlpha = Math.floor(alpha * 255)
            .toString(16)
            .padStart(2, '0')
        const [, a, b, c, d, e, f] = color
        if (!d) {
            // three leter hex code
            return `#${a}${a}${b}${b}${c}${c}${hexAlpha}`
        }
        return `#${a}${b}${c}${d}${e}${f}` + hexAlpha
    }

    const pattern = new RegExp(
        /(hsl|rgb)a?\((\d+),\s?(\d+%?),\s?(\.?\d+%?)(,\d?\.?\d*)?\)/g,
    )

    const parts = pattern.exec(color)

    if (!parts) {
        return color
    }

    return `${parts[1]}a(${parts[2]}, ${parts[3]}, ${parts[4]}, ${alpha})`
}
