import { getVariantColor } from "@the-game/components/utils";
import { calcRem } from "@the-game/components/utils";
import styled from "@emotion/styled";
import { insetFocusStyle } from "@the-game/components/utils";
export const StyledTextAreaContainer = styled("div")({
  position: "relative",
  display: "flex"
});
export const StyledTextArea = styled("textarea")(
  ({ theme, variant, fluid }) => ({
    display: "flex",
    flexGrow: fluid ? 1 : void 0,
    width: fluid ? "100%" : void 0,
    padding: calcRem(theme.spacing.sm),
    background: theme.colors.white,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: getVariantColor(variant, theme),
    borderRadius: 0,
    outline: "none",
    "&:focus": {
      borderColor: theme.colors.primary
    },
    ...insetFocusStyle(theme),
    "&[disabled]": {
      backgroundColor: theme.colors.palette.neutral30,
      borderColor: theme.colors.palette.neutral70,
      color: theme.colors.palette.neutral70
    }
  })
);
