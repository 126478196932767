import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function ChevronLeft({
    title = 'Chevron left icon',
    ...props
}: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 10 16" title={title} {...props}>
            <path d="M9.243 2.343L7.828.93.758 8l7.07 7.071 1.415-1.414L3.586 8z" />
        </SvgIcon>
    )
}

export default ChevronLeft
