import React from 'react'
import { FlagProps } from '.'

export function BrisbaneHeat({ title = 'Brisbane Heat', ...props }: FlagProps) {
    return (
        <svg
            width="96"
            height="96"
            {...props}
            viewBox="0 0 96 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>{title}</title>

            <rect y="32" width="96" height="32" fill="#77CBC9" />
            <rect y="64" width="96" height="32" fill="white" />
            <rect width="96" height="32" fill="#25A6B0" />
        </svg>
    )
}

export default BrisbaneHeat
