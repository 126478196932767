import { Theme } from '@emotion/react'

export function getPrimarySportColor(param: string, theme: Theme) {
    switch (param) {
        case 'AFLW':
            return theme.colors.palette['aflw100']
        case 'WAFL':
            return theme.colors.palette['waflTeal']
        case 'Cricket':
            return theme.colors.palette['cricketOrange']
        case 'AFL':
            return theme.colors.palette['bayBlue']
        default:
            return theme.colors.primary
    }
}

export default getPrimarySportColor
