import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Lock({ title = 'Lock Icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon
            {...{ title, ...props }}
            data-testid="lock-icon"
            viewBox="0 0 24 24"
        >
            <path
                fillRule="evenodd"
                d="M18,10.5 C19.6569,10.5 21,11.8431 21,13.5 L21,19.5 C21,21.1569 19.6569,22.5 18,22.5 L6,22.5 C4.34315,22.5 3,21.1569 3,19.5 L3,13.5 C3,11.8431 4.34315,10.5 6,10.5 L6,7.5 C6,4.18629 8.68629,1.5 12,1.5 C15.3137,1.5 18,4.18629 18,7.5 L18,10.5 Z M12,3.5 C14.2091,3.5 16,5.29086 16,7.5 L16,10.5 L8,10.5 L8,7.5 C8,5.29086 9.79086,3.5 12,3.5 Z M18,12.5 L6,12.5 C5.44772,12.5 5,12.9477 5,13.5 L5,19.5 C5,20.0523 5.44772,20.5 6,20.5 L18,20.5 C18.5523,20.5 19,20.0523 19,19.5 L19,13.5 C19,12.9477 18.5523,12.5 18,12.5 Z"
            />
        </SvgIcon>
    )
}
export default Lock
