import React from 'react'
import { FlagProps } from '.'

export function NorthMelbourne({
    title = 'North Melbourne',
    ...props
}: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            role="img"
            {...props}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h10v48H0zm38 0h10v48H38z" fill="#1A3B84" />
                <path d="M29 0h9v48h-9z" fill="#FFF" />
                <path d="M19 0h10v48H19z" fill="#1A3B84" />
                <path d="M10 0h9v48h-9z" fill="#FFF" />
            </g>
        </svg>
    )
}

export default NorthMelbourne
