import React, { useEffect, useRef } from 'react'
import { CrossRound } from '../Icons/CrossRound'
import { Info } from '../Icons/Info'
import { Cross } from '../Icons/Cross'
import { Check } from '../Icons/Check'
import {
    StyledCrossButton,
    StyledNoticeCard,
    StyledStickyDivTop,
} from './NoticeCard.styled'
import { NoticeCardVariant } from './types'

export interface NoticeCardProps {
    variant?: NoticeCardVariant
    message?: string
    dismissAfter?: number
    dismiss: () => void
}

function ClosingButton({
    onClick,
}: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
    return (
        <StyledCrossButton type="button" onClick={onClick} aria-label="Close">
            <Cross
                stroke={'white'}
                strokeWidth="1"
                themeColor={'black'}
                size="xs"
            />
        </StyledCrossButton>
    )
}
export function NoticeCard({
    dismiss,
    variant = 'error',
    message = 'There seems to be a problem. Please try again.',
    dismissAfter,
}: NoticeCardProps) {
    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined)

    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault()
        dismiss()
    }

    useEffect(() => {
        if (dismissAfter) {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            timerRef.current = setTimeout(() => {
                dismiss()
            }, dismissAfter)
        } else {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
        }
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
        }
    }, [dismiss, dismissAfter])
    return (
        <StyledStickyDivTop>
            <StyledNoticeCard variant={variant}>
                <div>
                    {
                        {
                            error: (
                                <CrossRound
                                    rotate={180}
                                    themeColor={'white'}
                                    size="sm"
                                />
                            ),
                            warning: (
                                <Info
                                    rotate={180}
                                    themeColor={'white'}
                                    size="sm"
                                />
                            ),
                            success: <Check themeColor={'white'} size="sm" />,
                        }[variant]
                    }
                </div>
                <p>{message}</p>
                <ClosingButton onClick={handleClose} />
            </StyledNoticeCard>
        </StyledStickyDivTop>
    )
}

export default NoticeCard
