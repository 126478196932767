import styled from "@emotion/styled";
import {
  calcRem,
  breakpointMax,
  visuallyHiddenStyle,
  insetFocusStyle,
  breakpoint
} from "@the-game/components/utils";
export const StyledTeamList = styled("dl")(
  ({ theme, orientation }) => ({
    display: "grid",
    gridTemplateColumns: "1fr",
    width: "100%",
    padding: orientation === "home" ? calcRem(
      theme.spacing.md,
      36,
      theme.spacing.md,
      theme.spacing.md
    ) : calcRem(
      theme.spacing.md,
      theme.spacing.md,
      theme.spacing.md,
      36
    ),
    transition: "background-color 0.1s linear",
    alignItems: "center",
    dt: {
      ...visuallyHiddenStyle
    },
    [breakpoint("sm")]: {
      gridTemplateColumns: orientation === "home" ? "5fr 1fr" : "1fr 5fr"
    },
    [breakpointMax("sm")]: {
      padding: calcRem(theme.spacing.sm)
    }
  })
);
export const StyledScore = styled("dd")(
  ({ theme, orientation }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    fontSize: calcRem(33),
    fontWeight: theme.weights.bold,
    [breakpointMax("xs")]: {
      fontSize: calcRem(22)
    },
    [breakpointMax("sm")]: {
      alignSelf: "flex-start",
      alignItems: orientation === "home" ? "flex-start" : "flex-end"
    }
  })
);
export const StyledMainScore = styled("div")(
  () => ({
    display: "inline-block"
  })
);
export const StyledSubScore = styled("div")(({ theme, selected, orientation }) => {
  return {
    display: "inline-block",
    fontSize: calcRem(16),
    fontWeight: theme.weights.bold,
    color: selected ? theme.colors.white : theme.colors.palette.neutral70,
    paddingBottom: theme.spacing.sm,
    [breakpoint("sm")]: {
      alignSelf: orientation === "home" ? "flex-end" : void 0
    }
  };
});
export const StyledTeamContainer = styled("dd")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignItems: "flex-end"
});
export const StyledTeamName = styled("dd")(
  ({ theme, orientation }) => ({
    fontSize: calcRem(19),
    fontWeight: theme.weights.bold,
    textAlign: orientation === "home" ? "left" : "right",
    marginLeft: orientation === "home" ? calcRem(16) : void 0,
    marginRight: orientation !== "home" ? calcRem(16) : void 0,
    [breakpointMax("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: calcRem(8)
    },
    [breakpointMax("xs")]: {
      fontSize: calcRem(16),
      paddingLeft: orientation === "away" ? calcRem(10) : void 0,
      paddingRight: orientation === "home" ? calcRem(10) : void 0
    }
  })
);
const backgroundColor = (theme, winState) => {
  switch (winState) {
    case "won":
      return theme.colors.palette.green500;
    case "lost":
      return theme.colors.palette.red500;
    default:
      return theme.colors.primary;
  }
};
export const StyledRadio = styled("input")(({ theme, disabled, winState }) => {
  const defaults = {
    ...visuallyHiddenStyle,
    "&:checked + dl": {
      backgroundColor: backgroundColor(theme, winState)
    }
  };
  if (disabled) {
    return defaults;
  } else {
    return {
      "&:hover + dl": {
        backgroundColor: theme.colors.palette.bayBlue100
      },
      ...insetFocusStyle(theme, "&:focus-visible + dl"),
      ...defaults
    };
  }
});
export const StyledLabel = styled("label")(({ disabled, orientation, selected, theme }) => ({
  display: "flex",
  flex: 1,
  maxWidth: "50%",
  cursor: disabled ? "not-allowed" : "pointer",
  borderRadius: orientation === "home" ? "4px 0 0 0" : "0 4px 0 0",
  color: selected ? theme.colors.white : theme.colors.palette.neutral90,
  minHeight: calcRem(120),
  WebkitTapHighlightColor: "transparent"
  // stops flash when clicking the item on safari mobile
}));
export const StyledAvatarTeamContainer = styled("div")(({ orientation, theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: orientation === "home" ? "flex-start" : "flex-end",
  div: {
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.colors.white,
    margin: 0
  },
  [breakpointMax("sm")]: {
    flexDirection: orientation === "home" ? "column" : "column-reverse",
    alignItems: orientation === "home" ? "flex-start" : "flex-end",
    gridRow: 1,
    div: {
      borderWidth: 2,
      width: calcRem(48),
      height: calcRem(48),
      minWidth: calcRem(48),
      minHeight: calcRem(48)
    }
  }
}));
export const StyledSuperOver = styled("div")(({ orientation }) => ({
  fontSize: calcRem(16),
  textAlign: orientation === "home" ? "left" : "right",
  [breakpoint("sm")]: {
    textAlign: orientation === "home" ? "right" : "left"
  }
}));
export const StyledSuperOverHeading = styled("h6")(({ theme, selected }) => ({
  fontSize: calcRem(14),
  margin: 0,
  fontWeight: theme.weights.regular,
  color: selected ? theme.colors.white : theme.colors.palette.neutral70
}));
