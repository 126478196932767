import React from 'react'
import { FlagProps } from '.'

export function AdelaideStrikers({
    title = 'Adelaide Strikers',
    ...props
}: FlagProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            {...props}
            viewBox="0 0 96 96"
        >
            <title>{title}</title>
            <g clipPath="url(#clip0_786:47585)">
                <rect
                    y="96"
                    width="96"
                    height="32"
                    transform="rotate(-90 0 96)"
                    fill="#0185D8"
                />
                <rect
                    x="32"
                    y="96"
                    width="96"
                    height="32"
                    transform="rotate(-90 32 96)"
                    fill="#E7E7E7"
                />
                <rect
                    x="64"
                    y="96"
                    width="96"
                    height="32"
                    transform="rotate(-90 64 96)"
                    fill="#004E92"
                />
            </g>
            <defs>
                <clipPath id="clip0_786:47585">
                    <rect width="96" height="96" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default AdelaideStrikers
