import { GameTheme } from '../theme'

export function insetFocusStyle(
    theme: GameTheme,
    selector = '&:focus-visible',
    game = undefined,
) {
    return {
        ':focus': {
            outline: 'none',
        },
        [selector]: {
            boxShadow: game
                ? 'none'
                : `inset 0 0 0 2px ${theme.colors.primary}`,
            outline: 'none',
        },
    }
}

export default insetFocusStyle
