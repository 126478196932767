import React from 'react'
import { StyledButton } from './Button.styled'

export interface GenericButtonProps {
    kind?: 'primary' | 'action' | 'neutral' | 'gradient' | 'danger' | 'inactive'
    shape?: 'default' | 'square' | 'rounded'
    variant?: 'ghost'
    fluid?: boolean
    size?: 'lg' | 'md' | 'sm'
    padding?: 'auto' | number
}

export type ButtonProps = GenericButtonProps &
    React.ButtonHTMLAttributes<HTMLButtonElement>

export function Button({
    children,
    type = 'button',
    kind = 'primary',
    fluid = false,
    shape = 'default',
    padding = 'auto',
    variant,
    size = 'md',
    ...buttonProps
}: ButtonProps): JSX.Element {
    return (
        <StyledButton
            size={size}
            type={type}
            kind={kind}
            fluid={fluid}
            variant={variant}
            padding={padding}
            shape={shape}
            {...buttonProps}
        >
            {children}
        </StyledButton>
    )
}

export default Button
