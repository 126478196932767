import React from 'react'
import { StyledButtonGroupItem } from './ButtonGroupItem.styled'

interface ButtonGroupItemProps {
    id: string
    children: React.ReactNode
    disabled?: boolean
    value: string
    isChecked?: boolean
    onClick?: () => void
    ref?: React.RefObject<HTMLButtonElement>
}

export const ButtonGroupItem = React.forwardRef<
    HTMLButtonElement,
    ButtonGroupItemProps
>(({ onClick, isChecked = false, disabled = false, value, children }, ref) => {
    const internalRef = React.useRef<HTMLButtonElement>(null)

    function onClickHandler() {
        if (typeof onClick !== 'undefined') {
            onClick()
        }
    }

    type HTMLButtonFocs = Pick<HTMLButtonElement, 'focus'>
    React.useImperativeHandle<HTMLButtonFocs, HTMLButtonFocs>(ref, () => ({
        focus: () => internalRef.current?.focus(),
    }))

    return (
        <StyledButtonGroupItem
            ref={internalRef}
            type="button"
            role="radio"
            aria-checked={isChecked}
            disabled={disabled ? true : undefined}
            onClick={onClickHandler}
            tabIndex={isChecked ? 0 : -1}
            value={value}
        >
            {children}
        </StyledButtonGroupItem>
    )
})
