import React from 'react'
import { SvgIcon, SvgIconProps } from './SvgIcon'

export function Prize({ title = 'Prize icon', ...props }: SvgIconProps) {
    return (
        <SvgIcon title={title} viewBox="0 0 24 24" {...props}>
            <g fillRule="evenodd">
                <path d="M17.5354,2.87868 C16.3638,1.70711 14.4644,1.70711 13.2928,2.87868 L11.8786,4.29289 C11.8183,4.35317 11.7611,4.41538 11.707,4.47931 C11.653,4.41539 11.5958,4.3532 11.5355,4.29293 L10.1213,2.87871 C8.94975,1.70714 7.05025,1.70714 5.87868,2.87871 C4.70711,4.05029 4.70711,5.94978 5.87868,7.12136 L6.75732,8.0000025 L1,8.0000025 L1,14.0000025 L3,14.0000025 L3,22.0000025 L21,22.0000025 L21,14.0000025 L23,14.0000025 L23,8.0000025 L16.6567,8.0000025 L17.5354,7.12132 C18.707,5.94975 18.707,4.05025 17.5354,2.87868 Z M14.707,7.12132 L16.1212,5.70711 C16.5117,5.31658 16.5117,4.68342 16.1212,4.29289 C15.7307,3.90237 15.0975,3.90237 14.707,4.29289 L13.2928,5.70711 C12.9023,6.09763 12.9023,6.7308 13.2928,7.12132 C13.6833,7.51184 14.3165,7.51184 14.707,7.12132 Z M10.1213,5.70714 L8.70711,4.29293 C8.31658,3.9024 7.68342,3.9024 7.29289,4.29293 C6.90237,4.68345 6.90237,5.31662 7.29289,5.70714 L8.70711,7.12136 C9.09763,7.51188 9.7308,7.51188 10.1213,7.12136 C10.5118,6.73083 10.5118,6.09767 10.1213,5.70714 Z M21,10 L21,12 L3,12 L3,10 L21,10 Z M12.9167,14 L19,14 L19,20 L12.9167,20 L12.9167,14 Z M11.0834,14 L11.0834,20 L5,20 L5,14 L11.0834,14 Z" />
            </g>
        </SvgIcon>
    )
}

export default Prize
