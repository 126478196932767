import { jsx, useTheme } from '@emotion/react'
import { ReactHTML, ReactText } from 'react'
import { calcRem } from '@the-game/components/utils'
import { ElevationSizes, getElevationBySize } from '@the-game/components/utils'
import { Spacing } from '../theme'
import CSS from 'csstype'

export interface BoxProps {
    id?: string
    className?: string
    padding?: Spacing // All sides
    paddingX?: Spacing // Horizontal (left & right)
    paddingY?: Spacing // Vertical (top & bottom)
    paddingTop?: Spacing
    paddingRight?: Spacing
    paddingBottom?: Spacing
    paddingLeft?: Spacing
    spacing?: Spacing
    children?: React.ReactNode
    component?: keyof ReactHTML
    borderRadius?: string | number | ReactText[] | string[]
    elevation?: ElevationSizes
    backgroundColor?: string
    textAlign?: CSS.Property.TextAlign
    hideOverflow?: boolean
    position?: string
}

export function Box({
    id,
    className,
    padding,
    paddingX = padding,
    paddingY = padding,
    paddingTop = paddingY,
    paddingRight = paddingX,
    paddingBottom = paddingY,
    paddingLeft = paddingX,
    spacing,
    children,
    component = 'div',
    backgroundColor,
    borderRadius,
    elevation,
    textAlign,
    hideOverflow = false,
    position,
}: BoxProps) {
    const theme = useTheme()

    return jsx(component, {
        id,
        className,
        css: {
            color: theme.colors.palette.neutral90,
            overflow: hideOverflow ? 'hidden' : undefined,
            paddingTop: paddingTop
                ? calcRem(theme.spacing[paddingTop])
                : undefined,
            paddingRight: paddingRight
                ? calcRem(theme.spacing[paddingRight])
                : undefined,
            paddingBottom: paddingBottom
                ? calcRem(theme.spacing[paddingBottom])
                : undefined,
            paddingLeft: paddingLeft
                ? calcRem(theme.spacing[paddingLeft])
                : undefined,
            marginBottom: spacing ? calcRem(theme.spacing[spacing]) : undefined,
            borderRadius: borderRadius,
            backgroundColor: backgroundColor ? backgroundColor : undefined,
            fontFamily: theme.fontFamily,
            textAlign,
            ...(elevation ? getElevationBySize(elevation) : {}),
            position,
        },
        children,
    })
}
