import { focusStyle } from "@the-game/components/utils";
import styled from "@emotion/styled";
export const StyledButtonGroupItem = styled("button")(({ theme }) => ({
  position: "relative",
  flexGrow: 1,
  margin: 0,
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.primary,
  borderWidth: `2px 1px`,
  borderStyle: "solid",
  color: theme.colors.primary,
  cursor: "pointer",
  textDecoration: "none",
  transition: "background-color 0.1s linear",
  "&:first-child": {
    borderLeftWidth: 2,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  "&:last-child": {
    borderRightWidth: 2,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4
  },
  ...focusStyle(theme),
  "@media (any-hover: hover)": {
    "&:hover": {
      backgroundColor: theme.colors.buttonGroup.hover
    }
  },
  "&[aria-checked=true]": {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    textDecoration: "underline"
  },
  "&[disabled]": {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.palette.neutral50,
    color: theme.colors.palette.neutral50,
    cursor: "not-allowed",
    "&[aria-checked=true]": {
      backgroundColor: theme.colors.palette.neutral50,
      color: theme.colors.palette.neutral90
    }
  }
}));
