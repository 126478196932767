import React from 'react'
import {
    StyledCheckboxInput,
    StyledCheckboxChildren,
    StyledCheckboxLabel,
    StyledCheckbox,
    StyledCheck,
} from './Checkbox.styled'

export interface CheckboxProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    alignItems?: 'center' | 'start'
    fluid?: boolean
    children: React.ReactNode
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({ children, alignItems = 'start', fluid = false, ...props }, ref) => (
        <StyledCheckboxLabel alignItems={alignItems} fluid={fluid}>
            <StyledCheckboxInput type="checkbox" ref={ref} {...props} />
            <StyledCheckbox>
                <StyledCheck />
            </StyledCheckbox>
            <StyledCheckboxChildren>{children}</StyledCheckboxChildren>
        </StyledCheckboxLabel>
    ),
)

export default Checkbox
