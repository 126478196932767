import React from 'react'
import { Stack } from '../Stack'
import styled from '@emotion/styled'
import { Skeleton } from '../Skeleton'
import {
    StyledRightAlignedTd,
    LeaderboardBase,
} from '../Leaderboard/leaderboard'
import { LeaderboardSkeletonProps } from './LeaderboardSkeletonProps'

const StyledSkeleton = styled(Skeleton)({
    height: 24,
    display: 'inline-block', // so that they respect text-align
})

function between(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min
}

function LeaderboardRowSkeleton({ columns }: { columns: number }) {
    return (
        <tr>
            <td>
                <StyledSkeleton width={24} />
            </td>
            <td>
                <Stack direction="row" gap="sm">
                    <StyledSkeleton width={24} variant="circle" />
                    <StyledSkeleton width={between(50, 120)} />
                </Stack>
            </td>
            {columns > 2 && (
                <StyledRightAlignedTd>
                    <StyledSkeleton width={between(24, 48)} />
                </StyledRightAlignedTd>
            )}
            {columns > 3 && (
                <StyledRightAlignedTd>
                    <StyledSkeleton width={between(30, 48)} />
                </StyledRightAlignedTd>
            )}
            <StyledRightAlignedTd>
                <StyledSkeleton width={between(36, 60)} />
            </StyledRightAlignedTd>
            <StyledRightAlignedTd>
                <StyledSkeleton width={between(40, 60)} />
            </StyledRightAlignedTd>
        </tr>
    )
}
export function LeaderboardSkeleton({
    round,
    rowCount,
    highlightedRow,
    sport,
    lastCalculatedRound,
}: LeaderboardSkeletonProps) {
    return (
        <LeaderboardBase
            rows={undefined}
            round={round}
            sport={sport}
            highlightedRow={highlightedRow}
            lastCalculatedRound={lastCalculatedRound}
        >
            {new Array(rowCount).fill(0).map((_, index) => (
                <LeaderboardRowSkeleton
                    columns={!!round && round >= 0 ? 4 : 2}
                    key={index}
                />
            ))}
        </LeaderboardBase>
    )
}
