import {
    abbreviationToNameMap,
    isAflScore,
    isCricketScore,
    MatchScore,
    Team,
    TeamToWin,
} from '@the-game/api-interfaces'
import { Avatar } from '../Avatar'
import React from 'react'
import {
    Orientation,
    StyledAvatarTeamContainer,
    StyledLabel,
    StyledRadio,
    StyledScore,
    StyledTeamList,
    StyledTeamName,
    StyledSubScore,
    StyledMainScore,
    StyledSuperOverHeading,
    StyledSuperOver,
} from './MatchTeam.styled'
import { WinState } from '../ScoreSummaryCard'
import { VisuallyHidden } from '../VisuallyHidden'

interface MatchTeamProps {
    name: Team
    matchId: string
    selected: boolean
    score?: MatchScore
    showScore: boolean
    disabled?: boolean
    orientation: Orientation
    winState: WinState
    homeTeam: boolean
    onChange: (teamToWin: TeamToWin) => void
}

export const MatchTeam = React.forwardRef<HTMLInputElement, MatchTeamProps>(
    (props, ref) => {
        const {
            name,
            matchId,
            onChange,
            selected,
            score,
            showScore,
            disabled = false,
            winState,
            orientation = 'home',
            homeTeam,
        } = props
        const inputId = `input-${matchId}-${homeTeam ? 'HOME' : 'AWAY'}`

        const teamItem = (
            <>
                <dt>Team</dt>
                <StyledTeamName orientation={orientation}>
                    {abbreviationToNameMap[name]}
                </StyledTeamName>
            </>
        )

        const scoreItem = (
            <>
                <dt>Score</dt>
                {showScore ? (
                    <StyledScore
                        data-testid={'score-' + name}
                        orientation={orientation}
                    >
                        <StyledMainScore orientation={orientation}>
                            {isAflScore(score)
                                ? score.total
                                : isCricketScore(score)
                                ? `${score.wickets}/${score.runs}`
                                : '0'}
                        </StyledMainScore>
                        <StyledSubScore
                            data-testid={'score-' + name}
                            orientation={orientation}
                            selected={selected}
                        >
                            {isAflScore(score)
                                ? `(${score.goals}.${score.behinds})`
                                : isCricketScore(score)
                                ? `(${score.overs})`
                                : ''}
                        </StyledSubScore>

                        {isCricketScore(score) && score.superOver && (
                            <StyledSuperOver orientation={orientation}>
                                <StyledSuperOverHeading selected={selected}>
                                    Super Over
                                </StyledSuperOverHeading>
                                {score.superOver.wickets}/{score.superOver.runs}
                            </StyledSuperOver>
                        )}
                    </StyledScore>
                ) : (
                    <dd />
                )}
            </>
        )

        const avatarAndTeam = (
            <StyledAvatarTeamContainer orientation={orientation}>
                {!homeTeam && teamItem}
                <VisuallyHidden component="dt">Team flag</VisuallyHidden>
                <dd>
                    <Avatar
                        elevation={1}
                        size="xl"
                        spacing="md"
                        teamFlag={name}
                    />
                </dd>
                {homeTeam && teamItem}
            </StyledAvatarTeamContainer>
        )

        return (
            <StyledLabel
                htmlFor={inputId}
                disabled={disabled}
                orientation={orientation}
                selected={selected}
            >
                <VisuallyHidden>{abbreviationToNameMap[name]}</VisuallyHidden>
                <StyledRadio
                    ref={ref}
                    value={homeTeam ? 'HOME' : 'AWAY'}
                    id={inputId}
                    name={matchId}
                    checked={selected}
                    winState={winState}
                    type="radio"
                    onChange={() => onChange(homeTeam ? 'HOME' : 'AWAY')}
                    disabled={disabled}
                />
                <StyledTeamList orientation={orientation}>
                    {!homeTeam && (
                        <>
                            {scoreItem}
                            {avatarAndTeam}
                        </>
                    )}

                    {homeTeam && (
                        <>
                            {avatarAndTeam}
                            {scoreItem}
                        </>
                    )}
                </StyledTeamList>
            </StyledLabel>
        )
    },
)
