import { SportName } from '@the-game/api-interfaces'
import { ButtonLink, Heading } from '@the-game/components'
import React from 'react'
import { Ladder } from '../Icons'
import { StyledLadderCard } from './LadderCard.styled'
import { AflLadderShape } from './components/AflLadderShape'
import { CricketLadderShape } from './components/CricketLadderShape'
import GameThemeProvider from '../GameThemeProvider'

export function LadderCard({ sport = 'AFL' }: { sport?: SportName }) {
    return (
        <GameThemeProvider sport={sport}>
            <StyledLadderCard>
                <Ladder aria-hidden title="" size="lg" sport={sport} />
                <Heading level="3" size="5">
                    {`${sport} ladder`}
                </Heading>
                <ButtonLink
                    to={`/${sport}/ladder`}
                    shape="rounded"
                    size="md"
                    variant="ghost"
                >
                    View Ladder
                </ButtonLink>
                {sport === 'Cricket' ? (
                    <CricketLadderShape />
                ) : (
                    <AflLadderShape />
                )}
            </StyledLadderCard>
        </GameThemeProvider>
    )
}
