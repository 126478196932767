import { DisplayLeaderboardEntry } from '@the-game/api-interfaces'
import { SelectedColumn } from './leaderboard'

export const sortRank = (ascending: boolean) => {
    return function (
        first: DisplayLeaderboardEntry,
        second: DisplayLeaderboardEntry,
    ) {
        return ascending ? second.rank - first.rank : first.rank - second.rank
    }
}

export const sortMember = (ascending: boolean) => {
    return function (
        first: DisplayLeaderboardEntry,
        second: DisplayLeaderboardEntry,
    ) {
        if (!first.username) {
            return ascending ? 1 : -1
        } else if (!second.username) {
            return ascending ? -1 : 1
        }
        // ! tells the TS compiler that the variable cannot be null/undefined
        return ascending
            ? first.username.localeCompare(second.username)
            : second.username.localeCompare(first.username)
    }
}

export type scoringAttributes =
    | 'roundMargin'
    | 'roundScore'
    | 'totalMargin'
    | 'totalScore'

export const sortMarginAndScores = (
    objectToSort: scoringAttributes,
    ascending: boolean,
) => {
    return function (
        first: DisplayLeaderboardEntry,
        second: DisplayLeaderboardEntry,
    ) {
        const firstObject = first[objectToSort]
        const secondObject = second[objectToSort]

        if (!firstObject) {
            return ascending ? -1 : 1
        } else if (!secondObject) {
            return ascending ? 1 : -1
        }

        return ascending
            ? firstObject - secondObject
            : secondObject - firstObject
    }
}

export function sortLeaderboard(
    selectedColumn: SelectedColumn,
    dataToSort: DisplayLeaderboardEntry[],
    updateSortedData: React.Dispatch<
        React.SetStateAction<DisplayLeaderboardEntry[] | undefined>
    >,
) {
    const sortData = [...dataToSort]
    if (selectedColumn) {
        switch (selectedColumn.name) {
            case 'rank': {
                updateSortedData([
                    ...sortData.sort(
                        sortRank(selectedColumn.state === 'ascending'),
                    ),
                ])
                break
            }
            case 'member': {
                updateSortedData([
                    ...sortData.sort(
                        sortMember(selectedColumn.state === 'ascending'),
                    ),
                ])
                break
            }

            case 'roundMargin': {
                updateSortedData([
                    ...sortData.sort(
                        sortMarginAndScores(
                            'roundMargin',
                            selectedColumn.state === 'ascending',
                        ),
                    ),
                ])
                break
            }

            case 'roundScore': {
                updateSortedData([
                    ...sortData.sort(
                        sortMarginAndScores(
                            'roundScore',
                            selectedColumn.state === 'ascending',
                        ),
                    ),
                ])
                break
            }

            case 'totalMargin': {
                updateSortedData([
                    ...sortData.sort(
                        sortMarginAndScores(
                            'totalMargin',
                            selectedColumn.state === 'ascending',
                        ),
                    ),
                ])
                break
            }

            case 'totalScore': {
                updateSortedData([
                    ...sortData.sort(
                        sortMarginAndScores(
                            'totalScore',
                            selectedColumn.state === 'ascending',
                        ),
                    ),
                ])
                break
            }
        }
    }
}
