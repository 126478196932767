import differenceInYears from 'date-fns/differenceInYears'
import parseISO from 'date-fns/parseISO'

import { ProfileDTO } from '../api-interfaces'

export type ProfileSetupForm = Omit<
    ProfileDTO,
    'givenName' | 'familyName' | 'isDeleted'
>

export const getIsEighteenOrOver = (form: {
    dateOfBirth: string | undefined
}): boolean =>
    !!form.dateOfBirth &&
    form.dateOfBirth !== '' &&
    differenceInYears(new Date(), parseISO(form.dateOfBirth)) >= 18

export const getIsPrizeEligible = (form: {
    dateOfBirth: string | undefined
    state: string | undefined
}) => getIsEighteenOrOver(form) && form.state === 'WA'
