import { GameDTO } from '@the-game/api-interfaces'
import React, { useContext } from 'react'

// reason for disable: handled below
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const GameContext = React.createContext<GameDTO>(undefined!)
GameContext.displayName = 'GameContext'

export function useGameContext(): GameDTO {
    const context = useContext(GameContext)
    if (context === undefined) {
        throw new Error(
            'useGameContext must be used within a GameContext.Provider',
        )
    }

    return context
}
