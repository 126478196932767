import { useTheme } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import VisuallyHidden from '../VisuallyHidden'
import {
    StyledAlertBanner,
    StyledAlertActionButton,
    StyledAlertBannerBox,
    StyledAlertActionButtonCross,
    StyledInfoIcon,
    StyledAlertBannerHeader,
    StyledAlertBannerText,
} from './AlertBanner.styled'
import { set as setCookie, get as getCookie } from 'js-cookie'
import Box from '../Box'
import { SportName } from '@the-game/api-interfaces'

export const COOKIE_DISMISS_SEASON_ALERT_BANNER = 'alertBannerDismissed'

export function AlertBanner({
    id,
    title,
    body,
    sport,
    tippingAvailable,
    registered,
}: {
    id: string
    title: string
    body: string
    sport: SportName | ''
    tippingAvailable: boolean
    registered: boolean
}) {
    const theme = useTheme()

    let color: string = theme.colors.palette.bayBlue600
    switch (sport) {
        case 'AFLW':
            color = theme.colors.palette.aflw100
            break
        case 'Cricket':
            color = theme.colors.palette.cricketOrange
            break
        case 'WAFL':
            color = theme.colors.palette.waflTeal
            break
    }

    const [isVisible, setIsVisible] = useState(
        !sport || (tippingAvailable && !registered),
    )

    function dismissAlert() {
        setCookie(COOKIE_DISMISS_SEASON_ALERT_BANNER, id)
        setIsVisible(false)
    }

    const shouldHideAlertBanner =
        getCookie(COOKIE_DISMISS_SEASON_ALERT_BANNER) === id

    useEffect(() => {
        if (!sport) {
            setIsVisible(true)
        } else if (shouldHideAlertBanner || registered) {
            setIsVisible(false)
        } else if (tippingAvailable) {
            setIsVisible(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tippingAvailable, registered])

    if (!isVisible || shouldHideAlertBanner) {
        return null
    }

    return (
        <Box backgroundColor={color}>
            <StyledAlertBanner>
                <StyledAlertBannerBox padding={'md'}>
                    <StyledAlertBannerHeader>
                        <StyledInfoIcon />
                        <h3>{title}</h3>
                    </StyledAlertBannerHeader>
                    <StyledAlertBannerText>{body}</StyledAlertBannerText>
                </StyledAlertBannerBox>
                <StyledAlertActionButton onClick={dismissAlert}>
                    <VisuallyHidden>Close</VisuallyHidden>
                    <StyledAlertActionButtonCross fill={theme.colors.white} />
                </StyledAlertActionButton>
            </StyledAlertBanner>
        </Box>
    )
}
