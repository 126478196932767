import React from 'react'
import { fontRemScale } from '@the-game/components/utils'
import styled from '@emotion/styled'
import CSS from 'csstype'
import { Link, LinkProps } from 'react-router-dom'

const shouldForwardProp = (prop: string) => !['textAlign'].includes(prop)

export interface StyledTypographyProps {
    textAlign?: CSS.Property.TextAlign
}

export interface TypographyProps
    extends React.PropsWithChildren<StyledTypographyProps> {
    variant?: 'p' | 'small'
    as?: 'p' | undefined
}

export const P = styled('p', { shouldForwardProp })<StyledTypographyProps>(
    ({ textAlign, theme }) => ({
        fontSize: fontRemScale(1),
        fontWeight: theme.weights.regular,
        lineHeight: 1.5,
        textAlign,
        marginBottom: theme.spacing.sm,
    }),
)

export const Small = styled('small', {
    shouldForwardProp,
})<StyledTypographyProps>(({ textAlign, theme }) => ({
    display: 'block',
    fontSize: fontRemScale(0.875),
    fontWeight: theme.weights.regular,
    lineHeight: 1.5,
    textAlign,
    marginBottom: theme.spacing.sm,
}))

export function Typography({ variant, children, ...props }: TypographyProps) {
    switch (variant) {
        case 'small':
            return <Small {...props}>{children}</Small>
        case 'p':
        default:
            return <P {...props}>{children}</P>
    }
}

export type TypographyLinkProps = StyledTypographyProps & LinkProps<unknown>
export const TypographyLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'textAlign',
})<TypographyLinkProps>(({ textAlign }) => ({
    textAlign,
}))
