import React from 'react'
import { CricketSvgGradient } from './CricketBall'
import { SvgIcon, SvgIconProps } from './SvgIcon'
import cuid from 'cuid'
import { AflSvgGradient } from './Football'
import { palette } from '../theme'

export function Ladder({
    title = 'Ladder icon',
    sport = 'AFL',
    themeColor,
    ...props
}: SvgIconProps) {
    const identifier = cuid()
    return (
        <SvgIcon title={title} viewBox="0 0 24 24" {...props}>
            {sport === 'Cricket' && !themeColor && (
                <defs>
                    <CricketSvgGradient identifier={identifier} />
                </defs>
            )}
            {sport === 'AFL' && !themeColor && (
                <defs>
                    <AflSvgGradient identifier={identifier} />
                </defs>
            )}
            <path
                fill={themeColor ? palette[themeColor] : `url(#${identifier})`}
                d="M20,21 C20,21.5522847 19.5522847,22 19,22 C18.4477153,22 18,21.5522847 18,21 L18,19 L6,19 L6,21 C6,21.5522847 5.55228475,22 5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 C5.55228475,2 6,2.44771525 6,3 L6,5 L18,5 L18,3 C18,2.44771525 18.4477153,2 19,2 C19.5522847,2 20,2.44771525 20,3 L20,21 Z M18,15 L6,15 L6,17 L18,17 L18,15 Z M18,11 L6,11 L6,13 L18,13 L18,11 Z M18,7 L6,7 L6,9 L18,9 L18,7 Z"
            ></path>
        </SvgIcon>
    )
}

export default Ladder
